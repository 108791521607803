import {
  Box,
  Card,
  Chip,
  Grid,
  LinearProgress,
  TableCell,
  Typography,
} from "@mui/material";
import styled from "styled-components";

export const OuterBox = styled(Box)({
  width: "100%",
  height: "100%",
  padding: "100px",
  boxSizing: "border-box",
});

export const NoRowBox = styled(Box)({
  width: "100%",
  height: "100%",
  padding: "50px",
  boxSizing: "border-box",
  border: "3px dashed #8f8f8f",
  borderRadius: "15px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

export const AssetFileBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  height: "100%", // 전체 뷰포트 높이
});

export const TobEmptyBox = styled(Box)({
  height: "20px",
});

export const CustomLinearProgress = styled(LinearProgress)({
  height: "16px",
  borderRadius: "8px",
  margin: "0 50px",
  "& .MuiLinearProgress-bar": {
    backgroundColor: process.env.REACT_APP_MAIN_COLOR, // 진행 바의 색상을 변경
  },
});

export const ProgressTypo = styled(Typography)({
  height: "30px",
  fontSize: "14px",
  color: "#8f8f8f",
  textAlign: "center",
});

export const FileViewOuterGrid = styled(Grid)({
  maxHeight: "calc(100% - 66px)",
  height: "calc(100% - 66px)",
});

export const FileDocListGrid = styled(Grid)({
  overflow: "auto",
  height: "100%",
  maxHeight: "100%",
  borderRight: "1px solid #ececec",
});

export const FileDocsCard = styled(Card)({
  margin: "10px",
  padding: "10px",
  backgroundColor: "white",
  position: "relative", // 상대 위치 설정
});

export const FileDocsCardBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const FileDocsChip = styled(Chip)({
  background: process.env.REACT_APP_MAIN_COLOR,
  color: "white",
});

export const FileDocsSummaryTitleTypo = styled(Typography)({
  marginTop: 2,
  fontWeight: "bold",
  color: "#8f8f8f",
});

export const FileDocsDetailGrid = styled(Grid)({
  height: "100%", // 높이를 100%로 설정
  display: "flex",
  flexDirection: "column",
  padding: "20px",
  overflowY: "auto", // 여기에서도 스크롤바가 생기도록 설정
});

export const FileDocsDetailOneLineCell = styled(TableCell)({
  background: process.env.REACT_APP_MAIN_COLOR,
  color: "white",
  whiteSpace: "nowrap",
  padding: "8px",
});

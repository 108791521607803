import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { Box } from "@mui/material";

interface DataPoint {
  x: number;
  y: number;
}

interface BarChartProps {
  chartTitle: string;
  xLabel: string;
  yLabel: string;
  data: DataPoint[];
}

const BarChart: React.FC<BarChartProps> = ({
  chartTitle,
  xLabel,
  yLabel,
  data,
}) => {
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    // Chart dimensions
    const margin = { top: 60, right: 40, bottom: 100, left: 60 }; // Bottom margin 증가
    const width = 700 - margin.left - margin.right;
    const height = 400 - margin.top - margin.bottom;

    // Clear previous chart
    d3.select(svgRef.current).selectAll("*").remove();

    // Create SVG container
    const svg = d3
      .select(svgRef.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom);

    // Create chart group
    const chart = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Create scales
    const xScale = d3
      .scaleBand()
      .domain(data.map((d) => d.x.toString()))
      .range([0, width])
      .padding(0.2);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.y)! + 5])
      .range([height, 0]);

    // Add X-axis
    chart
      .append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(xScale))
      .selectAll("text")
      .attr("font-size", "12px")
      .attr("text-anchor", "end") // 텍스트 앵커를 끝으로 설정
      .attr("transform", "rotate(-45)") // 45도 회전
      .attr("dx", "-0.5em") // X 위치 조정
      .attr("dy", "0.5em"); // Y 위치 조정

    // Add X-axis label
    chart
      .append("text")
      .attr("x", width / 2)
      .attr("y", height + margin.bottom - 40) // 위치 조정
      .attr("text-anchor", "middle")
      .style("font-size", "14px")
      .style("font-weight", "bold")
      .text(xLabel);

    // Add Y-axis
    chart
      .append("g")
      .call(d3.axisLeft(yScale))
      .selectAll("text")
      .attr("font-size", "12px");

    // Add Y-axis label
    chart
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -height / 2)
      .attr("y", -margin.left + 15)
      .attr("text-anchor", "middle")
      .style("font-size", "14px")
      .style("font-weight", "bold")
      .text(yLabel);

    // Add chart title
    svg
      .append("text")
      .attr("x", (width + margin.left + margin.right) / 2)
      .attr("y", margin.top / 2)
      .attr("text-anchor", "middle")
      .style("font-size", "18px")
      .style("font-weight", "bold")
      .text(chartTitle);

    // Add bars
    chart
      .selectAll(".bar")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => xScale(d.x.toString())!)
      .attr("y", (d) => yScale(d.y))
      .attr("width", xScale.bandwidth())
      .attr("height", (d) => height - yScale(d.y))
      .style("fill", "#007bff");
  }, [chartTitle, xLabel, yLabel, data]);

  return (
    <Box sx={{ padding: "15px", borderRadius: "5px", background: "white" }}>
      <svg ref={svgRef}></svg>
    </Box>
  );
};

export default BarChart;

import { Menu, styled as muiStyled } from "@mui/material";

export const CustomMenu = muiStyled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 12, // 모서리를 둥글게 설정
    marginTop: theme.spacing(1),
    color: "white",
    backgroundColor: process.env.REACT_APP_MAIN_COLOR,
  },
  "& .MuiMenuItem-root": {
    padding: "10px 16px",
    "&:hover": {
      backgroundColor: "#3A1A7A",
    },
  },
}));

import { Box, Button, InputBase, Stack } from "@mui/material";
import styled from "styled-components";

export const ResponsiveRow = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 0px;
  text-align: start;

  /* 모바일 환경 */
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
  }

  .label {
    flex: 1.5;
    white-space: nowrap;
    margin-top: 14px;

    /* 모바일 환경 */
    @media (max-width: 768px) {
      flex: none;
      font-size: 14px;
      margin-top: 5px;
    }
  }
`;

export const InputContainer = styled(Box)`
  display: flex;
  align-items: center;
  background-color: #e5e8eb;
  border-radius: 30px;
  padding: 10px 15px;
  flex: 7;
  min-height: 40px;
  box-sizing: border-box;

  /* 모바일 환경 */
  @media (max-width: 768px) {
    flex: none;
    width: 100%;
    padding: 8px 12px;
    min-height: 36px;
  }
`;

export const CustomInputBase = styled(InputBase)`
  flex: 1;
  margin-left: 15px;
  font-size: 14px;

  &::placeholder {
    font-size: 12px;
  }

  /* 모바일 환경 */
  @media (max-width: 768px) {
    margin-left: 8px;
    font-size: 12px;

    &::placeholder {
      font-size: 10px;
    }
  }
`;

export const CustomButton = styled(Button)({
  alignSelf: "flex-start", // 버튼 정렬
  fontSize: "12px",
  color: process.env.REACT_APP_MAIN_COLOR,
  "&:hover": {
    color: process.env.REACT_APP_MAIN_COLOR,
  },
  "@media (max-width: 768px)": {
    width: "100%", // 모바일에서 버튼 전체 너비
  },
});

export const CustomStack = styled(Stack)({
  flex: 7,
  gap: "10px",
});

export const CompleteButton = styled;

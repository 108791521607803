import { Box, InputBase, Typography } from "@mui/material";
import styled from "styled-components";

export const CenterSideBar = styled(Box)({
  width: "100%",
  height: "100%",
  padding: "10px",
  boxSizing: "border-box",
  borderRight: "1px solid #ececec",
  position: "relative",
});

export const FileUploadTypo = styled(Typography)({
  color: "white",
  opacity: 1,
});

export const FileUploadBox = styled(Box)({
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: "100%",
  height: "100%",
  boxSizing: "border-box",
  background: "rgba(0, 0, 0, 0.7)", // 배경에만 투명도 적용
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 10,
});

export const ResponsiveRow = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 0px;
  text-align: start;

  /* 모바일 환경 */
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
  }

  .label {
    flex: 1.5;
    white-space: nowrap;
    margin-top: 14px;

    /* 모바일 환경 */
    @media (max-width: 768px) {
      flex: none;
      font-size: 14px;
      margin-top: 5px;
    }
  }
`;

export const InputContainer = styled(Box)`
  display: flex;
  align-items: center;
  background-color: #e5e8eb;
  border-radius: 30px;
  padding: 10px 15px;
  flex: 7;
  min-height: 40px;
  box-sizing: border-box;

  /* 모바일 환경 */
  @media (max-width: 768px) {
    flex: none;
    width: 100%;
    padding: 8px 12px;
    min-height: 36px;
  }
`;

export const CustomInputBase = styled(InputBase)`
  flex: 1;
  margin-left: 15px;
  font-size: 14px;

  &::placeholder {
    font-size: 12px;
  }

  /* 모바일 환경 */
  @media (max-width: 768px) {
    margin-left: 8px;
    font-size: 12px;

    &::placeholder {
      font-size: 10px;
    }
  }
`;

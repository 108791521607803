import { Box, Step, styled } from "@mui/material";

export const CustomBox = styled(Box)(({ theme }) => ({
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[24],
  padding: theme.spacing(4),
  outline: "none",
  textAlign: "center",

  "@media (max-width: 768px)": {
    width: "90%", // 모바일에서는 너비를 90%로 설정
    padding: theme.spacing(2), // 패딩을 줄임
    boxShadow: theme.shadows[12], // 모바일에서 그림자 크기를 줄임
  },
}));

export const CustomBox2 = styled(Box)(({ theme }) => ({
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  width: "60%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[24],
  padding: theme.spacing(4),
  outline: "none",
  textAlign: "center",

  "@media (max-width: 768px)": {
    width: "90%", // 모바일에서는 너비를 90%로 설정
    padding: theme.spacing(2), // 패딩을 줄임
    boxShadow: theme.shadows[12], // 모바일에서 그림자 크기를 줄임
  },
}));

export const InnerCustomBox = styled(Box)({
  height: "60vh",
  maxHeight: "60vh",
  overflowY: "auto",
  marginTop: "20px",
  display: "flex",
  justifyContent: "start",
  flexDirection: "column",
  boxSizing: "border-box",
});

interface CustomStepProps {
  isactive: string;
}

export const CustomStep = styled(Step)<CustomStepProps>(({ isactive }) => ({
  "& .MuiStepIcon-root.Mui-active": {
    color: isactive === "true" ? process.env.REACT_APP_MAIN_COLOR : undefined,
  },
  "& .MuiStepIcon-root.Mui-completed": {
    color: process.env.REACT_APP_MAIN_COLOR,
  },
}));

export const openNewWindowWithPostData = (
  url: string,
  data: Record<string, any>
) => {
  const newwindow = window.open(url, "_blank");

  if (!newwindow) {
    return;
  }

  setTimeout(() => {
    newwindow.postMessage(data, url);
  }, 1000);
};

import React, { useEffect } from "react";
import {
  Box,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { useAtom } from "jotai";
import {
  isAccordionOpenAtom,
  profileSidebarAtom,
  selectSidebarAtom,
} from "../Atoms/ProfileAtom";
import * as S from "../Components/Profile/styled";
import { whenPageComeAtom } from "../ViewModels/ProfileViewModel";
import { AccountManage } from "../Components/Profile/AccountManage";
import { UsageManage } from "../Components/Profile/UsageManage";

export function Profile() {
  const [open, setOpen] = useAtom(isAccordionOpenAtom);
  const [profileSidebar] = useAtom(profileSidebarAtom);
  const [selectSidebar, setSelectSidebar] = useAtom(selectSidebarAtom);

  const [, whenPageCome] = useAtom(whenPageComeAtom);

  const isTabletOrLarger = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    whenPageCome();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: !isTabletOrLarger ? "row" : "column",
        height: "100%",
        boxSizing: "border-box",
      }}
    >
      {!isTabletOrLarger ? (
        <S.SidebarContainer>
          {profileSidebar.map((side, index) => (
            <S.CustomListItem
              onClick={() => setSelectSidebar(side.label)}
              key={`${index}-profile-sidebar`}
              isselect={side.label === selectSidebar ? "true" : "false"}
            >
              <S.CustomListItemIcon
                isselect={side.label === selectSidebar ? "true" : "false"}
              >
                <side.icon />
              </S.CustomListItemIcon>
              <S.CustomTypo>{side.label}</S.CustomTypo>
            </S.CustomListItem>
          ))}
        </S.SidebarContainer>
      ) : (
        <Accordion expanded={open} onChange={() => setOpen(!open)}>
          <AccordionSummary expandIcon={<MenuIcon />}></AccordionSummary>
          <AccordionDetails>
            {profileSidebar.map((side, index) => (
              <S.CustomListItem
                onClick={() => setSelectSidebar(side.label)}
                key={`${index}-profile-sidebar-mobile`}
                isselect={side.label === selectSidebar ? "true" : "false"}
              >
                <S.CustomListItemIcon
                  isselect={side.label === selectSidebar ? "true" : "false"}
                >
                  <side.icon />
                </S.CustomListItemIcon>
                <S.CustomTypo>{side.label}</S.CustomTypo>
              </S.CustomListItem>
            ))}
          </AccordionDetails>
        </Accordion>
      )}
      <Box style={{ flex: 1, padding: "20px" }}>
        {selectSidebar === "계정 관리" ? (
          <AccountManage />
        ) : selectSidebar === "사용량 관리" ? (
          <UsageManage />
        ) : null}
      </Box>
    </Box>
  );
}

import {
  AccordionSummary,
  Box,
  Chip,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import styled from "styled-components";

export const CustomTextField = styled(TextField)({
  width: "100%",
  padding: "0 10px",
  marginBottom: "10px",
  boxSizing: "border-box",
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: process.env.REACT_APP_MAIN_COLOR,
    },
  },

  "& .MuiInputLabel-root": {
    "&.Mui-focused": {
      color: process.env.REACT_APP_MAIN_COLOR,
    },
  },
});

export const FirstTitleTypography = styled(Typography)({
  padding: "15px 10px 0 10px",
  fontSize: "13px",
  color: "#8f8f8f",
});

export const TitleTypography = styled(Typography)({
  padding: "0 10px",
  fontSize: "13px",
  color: "#8f8f8f",
});

export const ChiBox = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  gap: 1,
  marginBottom: "15px",
  maxHeight: "72px",
  overflow: "auto",
});

export const KeywordChip = styled(Chip)({
  margin: "0 5px",
  background: process.env.REACT_APP_MAIN_COLOR,
  color: "white",
  maxWidth: "100px", // 너비를 제한하여 텍스트가 넘치지 않도록 설정
  textOverflow: "ellipsis", // 넘치는 텍스트는 '...'으로 표시
  whiteSpace: "nowrap", // 텍스트를 한 줄로 유지
  overflow: "hidden", // 넘치는 텍스트 숨김
});

export const ScrollableBox = styled(Box)({
  flexGrow: 1,
  overflowY: "auto",
});

interface AccordionProps {
  tabvalue: string;
}

export const CustomAccordionSummary = styled(AccordionSummary)<AccordionProps>(
  (props) => ({
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor:
      props.tabvalue === "1" ? process.env.REACT_APP_MAIN_COLOR : "transparent",
    color: props.tabvalue === "1" ? "white" : "black",
    boxShadow: "none",
    padding: "10px 16px",
  })
);

export const StyledFormControlLabel = styled(FormControlLabel)({
  "& .MuiFormControlLabel-label": {
    color: "#6b6b6b",
    fontSize: "14px",
    fontWeight: "bold",
  },
});

export const StyledSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: process.env.REACT_APP_MAIN_COLOR,
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: process.env.REACT_APP_MAIN_COLOR,
  },
}));

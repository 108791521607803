import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { useGetMyAssetQuery } from "../Queries/DokgabiAssetQueries";
import { useAtom } from "jotai";
import { DokgabiAssetProps } from "../Models/DokgabiFlow";
import { insertSelectAssetAtom } from "../ViewModels/ChatAssetViewModel";
import PublicIcon from "@mui/icons-material/Public";
import HttpsIcon from "@mui/icons-material/Https";
import { useNavigate } from "react-router-dom";
import { currentMakerAtom } from "../Atoms/ChatDesignPublicAtom";
import AddIcon from "@mui/icons-material/Add";
import * as S from "../Components/ChatAsset/styled";

export function ChatAsset() {
  const [currentMaker] = useAtom(currentMakerAtom);

  const [, insertSelectAsset] = useAtom(insertSelectAssetAtom);
  const { data: knwoledgeBaseData } = useGetMyAssetQuery(currentMaker ?? "");

  const navigate = useNavigate();

  return (
    <Box sx={{ flexGrow: 1 }} padding={5}>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12, lg: 20 }}
        sx={{
          display: "flex", // Ensure all grid items are treated as flex items
          alignItems: "stretch", // Make all items stretch to the same height
        }}
      >
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          lg={4}
          xl={4}
          key={`make-asset-children`}
          sx={{
            cursor: "pointer",
            display: "flex",
            flexDirection: "column", // Ensure child components inside stretch
          }}
        >
          <S.CustomCard
            onClick={() => insertSelectAsset(null, { navigate: navigate })}
            sx={{
              height: "100%", // Ensure the card takes the full height of the grid item
            }}
          >
            <S.CustomCardContent>
              <IconButton aria-label="add">
                <AddIcon sx={{ color: "white", fontSize: "50px" }} />
              </IconButton>
              <Typography gutterBottom variant="body1" component="div">
                나만의 Asset 만들기
              </Typography>
            </S.CustomCardContent>
          </S.CustomCard>
        </Grid>
        {knwoledgeBaseData &&
          knwoledgeBaseData.map((asset: DokgabiAssetProps, index: number) => (
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              key={`${index}-asset-list-in-chatdesign`}
              sx={{
                cursor: "pointer",
                display: "flex",
                flexDirection: "column", // Ensure child components inside stretch
              }}
              onClick={() => insertSelectAsset(asset, { navigate: navigate })}
            >
              <Card
                sx={{
                  height: "100%", // Ensure the card takes the full height of the grid item
                  display: "flex",
                  flexDirection: "column", // Make the card content stretch
                }}
              >
                <CardHeader
                  title={
                    <Stack
                      direction={"row"}
                      sx={{
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Avatar
                        src={
                          asset.thumbnail
                            ? `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${asset.thumbnail.file_path}`
                            : `/no-thumbnail.png`
                        }
                      />
                      <Typography variant="subtitle1">{`${asset.name}`}</Typography>
                    </Stack>
                  }
                  action={
                    <Tooltip
                      title={asset.is_public ? "public Asset" : "private Asset"}
                    >
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        {asset.is_public ? <PublicIcon /> : <HttpsIcon />}
                      </IconButton>
                    </Tooltip>
                  }
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    marginBottom={"10px"}
                  >
                    <Typography flex={1} fontSize={"12px"} color="#8f8f8f">
                      설명
                    </Typography>
                    <Typography flex={4}>{asset.desc ?? "-"}</Typography>
                  </Stack>
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography flex={1} fontSize={"12px"} color="#8f8f8f">
                      키워드
                    </Typography>
                    <Box flex={4}>
                      {asset.keywords ? (
                        asset.keywords.map((keyword, index) => (
                          <Chip
                            label={keyword}
                            key={`${index}-chip-asset-keyword`}
                            sx={{ marginRight: "10px" }}
                          />
                        ))
                      ) : (
                        <Chip label={"없음"} />
                      )}
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}

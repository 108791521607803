import React from "react";

import { Stack, Typography } from "@mui/material";
import { Handle, Position } from "@xyflow/react";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import Lottie from "react-lottie";
import LottieData from "../Animation/maincolor.json";
import * as S from "../styled";
import { useAtom } from "jotai";
import { handleNodeClickAtom } from "../../../../ViewModels/ChatFlowViewModel";
import { TargetNodeProps } from "../../../../Props/CustomNodeProps";

export function TargetNode({ data }: TargetNodeProps) {
  const [currentEditingNode, setCurrentEditingNode] =
    useAtom(handleNodeClickAtom);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          cursor: "pointer",
        }}
        onClick={() => setCurrentEditingNode(data.id)}
      >
        <S.CircleBox
          id={`${data.id}`}
          isclick={currentEditingNode?.data.id === data.id ? "true" : "false"}
        >
          <Handle
            type="target"
            position={Position.Left}
            id={`${data.id}-left`}
            className="handle-left"
          />
          <SwitchAccountIcon
            sx={{ color: "white", width: "100%", height: "60%" }}
          />
          <S.NodeNameTypography>Target</S.NodeNameTypography>
        </S.CircleBox>
        {currentEditingNode?.data.id === data.id ?? false ? (
          <Lottie
            options={defaultOptions}
            height={40}
            isClickToPauseDisabled={true}
          />
        ) : (
          <Typography sx={{ color: "#8f8f8f" }}>
            클릭하여 세부설정을 해보세요.
          </Typography>
        )}

        {data.target_list.map((target, index) => (
          <S.ChildrenBox
            key={`${data.id}-${index}`}
            id={`${data.id}-${index}`}
            isclick={currentEditingNode?.data.id === data.id ? "true" : "false"}
          >
            <Typography sx={{ color: "white" }}>{target.name}</Typography>
            {target.name !== "기타" &&
              target.target_feature_list.map((feature, index) => (
                <Typography
                  key={`target-feature-list-map-${index}`}
                  sx={{ color: "white", fontSize: "11px" }}
                >
                  {feature.feature_value.view_value}
                </Typography>
              ))}

            <Handle
              type="source"
              position={Position.Right}
              id={`${data.id}-${index}-right`}
              className="handle-right"
            />
          </S.ChildrenBox>
        ))}
      </Stack>
    </>
  );
}

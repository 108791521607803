import { useQuery } from "@tanstack/react-query";
import { ResponseStatus } from "../Enum/NetworkEnum";
import { getCookie } from "../Utils/CookieUtil";
import { handleReactQueryApiResponse } from "../Utils/APIUtil";

export const calculateUsage = async (access: string, userId: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/calculateUsage`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${access}`,
      },
      body: JSON.stringify({ user_id: userId }),
    }
  );
  if (response.status === 200) {
    const result = await response.json();
    return result;
  } else if (response.status === 401) {
    return ResponseStatus.UNAUTHORIZED;
  } else if (response.status === 403) {
    return ResponseStatus.FORBIDDEN;
  } else {
    return ResponseStatus.ERROR;
  }

  // return response;
};

export function usecalculateUsage(userId: string) {
  const accessCookie = getCookie(
    process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
  );
  return useQuery({
    queryKey: [`${userId}-dokgabi-usage-query`],
    queryFn: () =>
      handleReactQueryApiResponse(
        (accessCookie) => calculateUsage(accessCookie ?? "", userId),
        accessCookie ?? ""
      ),
  });
}

export const getUsage = async (access: string, userId: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_USER_API_ADDRESS}/mysql/getDokgabiUsage`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${access}`,
      },
      body: JSON.stringify({ user_id: userId }),
    }
  );
  if (response.status === 200) {
    const result = await response.json();
    return result.results;
  } else if (response.status === 401) {
    return ResponseStatus.UNAUTHORIZED;
  } else if (response.status === 403) {
    return ResponseStatus.FORBIDDEN;
  } else {
    return ResponseStatus.ERROR;
  }

  // return response;
};

export function useGetUsage(userId: string) {
  const accessCookie = getCookie(
    process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
  );
  return useQuery({
    queryKey: [`${userId}-dokgabi-get-usage-query`],
    queryFn: () =>
      handleReactQueryApiResponse(
        (accessCookie) => getUsage(accessCookie ?? "", userId),
        accessCookie ?? ""
      ),
  });
}

import { atom } from "jotai";
import { DokgabiAssetProps } from "../Models/DokgabiFlow";
import { selectAssetAtom } from "../Atoms/ChatAssetAtom";
import { initialDokgabiAsset } from "../Initialize/ChatFlowInitialize";
import { userAtom } from "../Atoms/PublicAtom";

export const insertSelectAssetAtom = atom(
  null,
  async (get, set, asset: DokgabiAssetProps | null, { navigate }) => {
    if (asset === null) {
      const user = get(userAtom);
      set(selectAssetAtom, {
        ...initialDokgabiAsset,
        maker: user?.membership === "admin" ? "admin" : user?.id ?? "",
      });
    } else {
      set(selectAssetAtom, asset!);
    }

    navigate("/chatbotDesign/assetMake");
  }
);

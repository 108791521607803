import React from "react";
import { useAtom } from "jotai";
import * as S from "./styled";
import { WelcomeDataProps } from "../../../Props/CustomNodeProps";
import {
  sortedModelOptions,
  welcomeOptions,
} from "../../../Initialize/CustomNodeOptions";
import {
  checkFinishInThirdStepAtom,
  handleNodeSettingUpdateAtom,
} from "../../../ViewModels/GuideModalViewModel";
import { Button } from "@mui/material";

export function ThirdStep() {
  const [currentEditingNode, handleNodeUpdate] = useAtom(
    handleNodeSettingUpdateAtom
  );
  const [, checkFinish] = useAtom(checkFinishInThirdStepAtom);

  return (
    <>
      <S.CustomAutoComplete
        value={
          (currentEditingNode.data as unknown as WelcomeDataProps).type
            .view_value
        }
        options={welcomeOptions.map((value) => value.view_value)}
        onChange={(event, value) => {
          if (value)
            handleNodeUpdate(
              welcomeOptions.find((val) => val.view_value === value),
              "type"
            );

          checkFinish();
        }}
        renderInput={(params) => (
          <S.CustomTextField
            {...params}
            label="인사말을 전달할 방식을 선택해주세요."
          />
        )}
      />
      {(currentEditingNode.data as unknown as WelcomeDataProps).type.value ===
      "llm" ? (
        <>
          <S.CustomAutoComplete
            value={
              (currentEditingNode.data as unknown as WelcomeDataProps)
                .model_name.view_value
            }
            options={sortedModelOptions.map((value) => value.view_value)}
            onChange={(event, value) => {
              if (value)
                handleNodeUpdate(
                  sortedModelOptions.find((val) => val.view_value === value),
                  "model_name"
                );
              checkFinish();
            }}
            renderInput={(params) => (
              <S.CustomTextField
                {...params}
                label="사용할 AI 모델을 선택해주세요."
              />
            )}
          />
          <S.CustomTextField
            placeholder="Open AI API-KEY 입력"
            value={
              (currentEditingNode.data as unknown as WelcomeDataProps).api_key
            }
            onChange={(event) =>
              handleNodeUpdate(event.target.value, "api_key")
            }
            onBlur={checkFinish}
          />

          <S.CustomTextField
            value={
              (currentEditingNode.data as unknown as WelcomeDataProps).prompt
            }
            placeholder={`프롬프트 입력
예시) 너는 000 서비스를 하는 챗봇이야. 이것에 대해서 설명을 해주고, 친절한 말투로 사용자에게 인사를 건네줘.`}
            onChange={(event) => handleNodeUpdate(event.target.value, "prompt")}
            onBlur={checkFinish}
            minRows={7}
            maxRows={15}
            multiline
          />
        </>
      ) : (currentEditingNode.data as unknown as WelcomeDataProps).type
          .value === "user_setting_text" ? (
        <S.CustomTextField
          value={
            (currentEditingNode.data as unknown as WelcomeDataProps)
              .welcome_message
          }
          placeholder={`원하는 문구를 정확하게 입력해주세요.
예시) 안녕하세요? 
저는 당신의 멘토가 되어줄 챗봇입니다.
영어나 수학 관련한 질문은 언제든지 환영이에요.
어떤 부분이 궁금하세요?`}
          onChange={(event) =>
            handleNodeUpdate(event.target.value, "welcome_message")
          }
          onBlur={checkFinish}
          minRows={7}
          maxRows={25}
          multiline
        />
      ) : null}
      <Button
        variant="contained"
        sx={{
          borderRadius: "20px",
          background: process.env.REACT_APP_MAIN_COLOR,
          "&:hover": {
            background: "#3A1A7A",
          },
          marginTop: "25px",
        }}
        onClick={checkFinish}
      >
        3단계 완료
      </Button>
    </>
  );
}

import { useAtom } from "jotai";
import * as S from "./styled";
import {
  completeStepAtom,
  currentStepAtom,
  hanldeGuideModalStateAtom,
  stepAtom,
} from "../../../Atoms/GuideModalAtom";
import * as React from "react";
import {
  Button,
  Modal,
  Stack,
  StepButton,
  Stepper,
  Typography,
} from "@mui/material";
import { FirstStep } from "../../GuideModal/FirstStep";
import { FourthStep } from "../../GuideModal/FourthStep";
import { KeyboardArrowRight } from "@mui/icons-material";
import {
  clickSkipButtonAtom,
  initializeGuideModalAtom,
  whenStartGuideModalAtom,
} from "../../../ViewModels/GuideModalViewModel";
import { ChatSkin } from "../../../Pages/ChatSkin";
import { ThirdStep } from "../../GuideModal/ThirdStep";
import { Finish } from "../../GuideModal/Finish";
import { openaiAPIKeyAtom } from "../../../Atoms/PublicAtom";

const GuideModal: React.FC = () => {
  const [successState] = useAtom(hanldeGuideModalStateAtom);
  const [steps] = useAtom(stepAtom);
  const [currentStep, setCurrentStep] = useAtom(currentStepAtom);
  const [completeStep] = useAtom(completeStepAtom);
  const [openaiAPIKey] = useAtom(openaiAPIKeyAtom);

  const [, whenStartGuideModal] = useAtom(whenStartGuideModalAtom);
  const [, clickSkipButton] = useAtom(clickSkipButtonAtom);
  const [, initialize] = useAtom(initializeGuideModalAtom);

  const handleStep = (step: number) => () => {
    setCurrentStep(step);
  };

  React.useEffect(() => {
    whenStartGuideModal();

    return () => initialize();
  }, []);

  const allStepsComplete = steps.every((_, index) => completeStep[index]);

  return (
    <Modal
      open={successState}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <S.CustomBox2>
        {openaiAPIKey ? (
          <Stack
            direction={"row"}
            justifyContent={"flex-end"}
            marginBottom={"20px"}
          >
            <Button
              sx={{
                width: "auto",
                color: process.env.REACT_APP_MAIN_COLOR,
                visibility: allStepsComplete ? "hidden" : "visible",
                "&:hover": {
                  color: process.env.REACT_APP_MAIN_COLOR,
                },
              }}
              onClick={clickSkipButton}
            >
              건너뛰기
              <KeyboardArrowRight />
            </Button>
          </Stack>
        ) : (
          <Typography>
            STEP을 따라하시면 만들고 싶으신 챗봇을 쉽게 만드실 수 있어요!!
          </Typography>
        )}

        <Stepper nonLinear activeStep={currentStep}>
          {steps.map((label, index) => (
            <S.CustomStep
              isactive={currentStep === index ? "true" : "false"}
              key={label}
              completed={completeStep[index]}
            >
              <StepButton
                color="inherit"
                onClick={allStepsComplete ? undefined : handleStep(index)}
              >
                {/* {label} */}
              </StepButton>
            </S.CustomStep>
          ))}
        </Stepper>

        <Typography
          sx={{ fontSize: "12px", color: "#8f8f8f", marginBottom: "20px" }}
        >
          {steps[currentStep]}
        </Typography>
        <S.InnerCustomBox>
          {allStepsComplete ? (
            <Finish />
          ) : currentStep === 0 ? (
            <FirstStep />
          ) : currentStep === 1 ? (
            <ChatSkin />
          ) : currentStep === 2 ? (
            <ThirdStep />
          ) : currentStep === 3 ? (
            <FourthStep />
          ) : null}
        </S.InnerCustomBox>
      </S.CustomBox2>
    </Modal>
  );
};

export default GuideModal;

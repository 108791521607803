import React from "react";
import { ArrowForward } from "@mui/icons-material";
import * as S from "./styled";
import { useAtom } from "jotai";
import { chattingValueAtom } from "../../../Atoms/ChatTestAtom";
import { isMakingAtom, promptAtom } from "../../../Atoms/ChatSkinAtom";
import {
  handlePromptChangeAtom,
  handlePromptKeyDownAtom,
  handleSendPromptAtom,
} from "../../../ViewModels/ChatSkinViewModel";
import { Typography } from "@mui/material";

export function ImageMakeInput() {
  const [inputValue] = useAtom(promptAtom);
  const [, onInputChange] = useAtom(handlePromptChangeAtom);
  const [, onSendMessage] = useAtom(handleSendPromptAtom);
  const [, onKeyDown] = useAtom(handlePromptKeyDownAtom);
  const [isMaking] = useAtom(isMakingAtom);

  return (
    <S.InputContainer>
      {isMaking ? (
        <Typography>이미지 생성중입니다.</Typography>
      ) : (
        <>
          <S.CustomInputBase
            placeholder={"원하는 이미지를 입력해보세요."}
            multiline
            maxRows={5}
            value={inputValue}
            onChange={onInputChange}
            onKeyDown={onKeyDown}
          />
          <S.CustomIconButton onClick={onSendMessage}>
            <ArrowForward />
          </S.CustomIconButton>
        </>
      )}
    </S.InputContainer>
  );
}

import { atom } from "jotai";
import { userAtom } from "../Atoms/PublicAtom";
import { openNewWindowWithPostData } from "../Utils/WindowOpenNavigateUtil";
import {
  checkUserChatbotNum,
  checkUserEndDate,
} from "../Queries/PlanValidQueries";
import { errorModalAtom } from "../Atoms/RootAtom";
import { getCookie } from "../Utils/CookieUtil";
import { handleApiResponse } from "../Utils/APIUtil";

export const takeToMyChatbotAtom = atom(
  null,
  async (get, set, flowId: number) => {
    const user = get(userAtom);

    if (user) {
      const refreshCookie = getCookie(
        process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
      );

      const accessCookie = getCookie(
        process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
      );

      const errorFunction = () => {
        set(errorModalAtom, {
          state: true,
          event: () =>
            (window.location.href = process.env.REACT_APP_DOKGABI_PAY_ADDRESS),
          eventText: "플랜 갱신",
          redirectUrl: "",
          title: "플랜 기간 만료",
          text: "현재 플랜의 기간이 만료되었습니다.\n플랜 갱신 후 다시 시도해 주세요.",
        });
      };

      const successFunction = async (results: any) => {
        const refreshCookie = getCookie(
          process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
        );

        const accessCookie = getCookie(
          process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
        );

        const errorFunction = () => {
          set(errorModalAtom, {
            state: true,
            event: () =>
              (window.location.href =
                process.env.REACT_APP_DOKGABI_PAY_ADDRESS),
            eventText: "플랜 갱신",
            redirectUrl: "",
            title: "챗봇 생성 제한",
            text: "현재 플랜에서 최대 챗봇 생성에 도달 했습니다.\n플랜 업그레이드 후에 다시 시도해 주세요.",
          });
        };

        const successFunction = (results: any) => {
          if (results === true) {
            const data = {
              message: "chatbotDesign",
              type: "create",
              id: flowId,
            };

            openNewWindowWithPostData(
              `${process.env.REACT_APP_DOKGABI_DASHBOARD_ADDRESS}/chatbotDesign/flow`,
              data
            );
          } else {
            set(errorModalAtom, {
              state: true,
              event: () =>
                (window.location.href =
                  process.env.REACT_APP_DOKGABI_PAY_ADDRESS),
              eventText: "플랜 갱신",
              redirectUrl: "",
              title: "챗봇 생성 제한",
              text: "현재 플랜에서 최대 챗봇 생성에 도달 했습니다.\n플랜 업그레이드 후에 다시 시도해 주세요.",
            });
          }
        };

        const newAccessToken = await handleApiResponse(
          refreshCookie,
          set,
          () => checkUserChatbotNum(accessCookie ?? "", user?.id),
          () => errorFunction(),
          (results) => successFunction(results)
        );

        if (newAccessToken) {
          await handleApiResponse(
            null,
            set,
            () => checkUserChatbotNum(newAccessToken ?? "", user?.id),
            () => errorFunction(),
            (results) => successFunction(results)
          );
        }
      };

      const newAccessToken = await handleApiResponse(
        refreshCookie,
        set,
        () => checkUserEndDate(accessCookie ?? "", user?.id),
        () => errorFunction(),
        (results) => successFunction(results)
      );

      if (newAccessToken) {
        await handleApiResponse(
          null,
          set,
          () => checkUserEndDate(newAccessToken ?? "", user?.id),
          () => errorFunction(),
          (results) => successFunction(results)
        );
      }
    } else {
      set(errorModalAtom, {
        state: true,
        event: null,
        eventText: "",
        redirectUrl: "",
        title: "네트워크 오류",
        text: "네트워크 오류가 났습니다.\n새로고침 후에 시도해주세요.",
      });
    }
  }
);

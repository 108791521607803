import React from "react";
import { useAtom } from "jotai";
import {
  handleAddFlowAssetAtom,
  handleNodeSettingUpdateAtom,
} from "../../../../../ViewModels/ChatFlowViewModel";
import * as S from "../styled";
import {
  sortedModelOptions,
  sortedoutputTypeOptions,
} from "../../../../../Initialize/CustomNodeOptions";
import { RagBaseLLMDataProps } from "../../../../../Props/CustomNodeProps";
import { useGetMyAssetQuery } from "../../../../../Queries/DokgabiAssetQueries";
import { DokgabiAssetProps } from "../../../../../Models/DokgabiFlow";
import { currentMakerAtom } from "../../../../../Atoms/ChatDesignPublicAtom";

export function RagLLMNodeSetting() {
  const [user] = useAtom(currentMakerAtom);
  const [currentEditingNode, handleNodeUpdate] = useAtom(
    handleNodeSettingUpdateAtom
  );

  const [, addFlowAsset] = useAtom(handleAddFlowAssetAtom);

  const { data: knwoledgeBaseData } = useGetMyAssetQuery(user ?? "");

  if (currentEditingNode === null) return null;

  if (knwoledgeBaseData === null || knwoledgeBaseData === undefined)
    return null;

  return (
    <>
      <S.CustomAutoComplete
        value={
          (currentEditingNode.data as unknown as RagBaseLLMDataProps)
            .output_type.view_value
        }
        options={sortedoutputTypeOptions.map((value) => value.view_value)}
        onChange={(event, value) => {
          if (value)
            handleNodeUpdate(
              sortedoutputTypeOptions.find((val) => val.view_value === value),
              "output_type"
            );
        }}
        renderInput={(params) => (
          <S.CustomTextField
            {...params}
            label="Asset을 선택해주시면 해당 Asset의 타입을 알려드립니다."
          />
        )}
        disabled={true}
      />
      <S.CustomAutoComplete
        value={
          (currentEditingNode.data as unknown as RagBaseLLMDataProps)
            .grounding_data === ""
            ? ""
            : (
                (currentEditingNode.data as unknown as RagBaseLLMDataProps)
                  .grounding_data as unknown as DokgabiAssetProps
              ).name
        }
        options={(knwoledgeBaseData as DokgabiAssetProps[]).map(
          (value) => value.name
        )}
        onChange={(event, value) => {
          if (value) {
            const findAsset = (knwoledgeBaseData as DokgabiAssetProps[]).find(
              (val) => val.name === value
            );

            if (findAsset) {
              handleNodeUpdate(findAsset, "grounding_data");

              addFlowAsset(findAsset);

              handleNodeUpdate(
                sortedoutputTypeOptions.find(
                  (val) => val.value === findAsset?.output_type
                ),
                "output_type"
              );
            }
          }
        }}
        renderInput={(params) => (
          <S.CustomTextField {...params} label="사용할 Asset을 선택해주세요." />
        )}
      />
      <S.CustomAutoComplete
        value={
          (currentEditingNode.data as unknown as RagBaseLLMDataProps).model_name
            .view_value
        }
        options={sortedModelOptions.map((value) => value.view_value)}
        onChange={(event, value) => {
          if (value)
            handleNodeUpdate(
              sortedModelOptions.find((val) => val.view_value === value),
              "model_name"
            );
        }}
        renderInput={(params) => (
          <S.CustomTextField
            {...params}
            label="사용할 AI 모델을 선택해주세요."
          />
        )}
      />
      <S.CustomTextField
        value={
          (currentEditingNode.data as unknown as RagBaseLLMDataProps).api_key
        }
        placeholder="Open AI API-KEY 입력"
        onChange={(event) => handleNodeUpdate(event.target.value, "api_key")}
      />

      <S.CustomTextField
        value={
          (currentEditingNode.data as unknown as RagBaseLLMDataProps).prompt
        }
        placeholder={`프롬프트 입력
예시) 사용자 입력으로 인해 추출된 Asset을 표형태로 보여줘.`}
        onChange={(event) => handleNodeUpdate(event.target.value, "prompt")}
        minRows={5}
        maxRows={15}
        multiline
      />
    </>
  );
}

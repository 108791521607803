import React from "react";
import { useAtom } from "jotai";
import * as S from "./styled";
import {
  handleChatbotDesignHeaderMakeMenuOpenAtom,
  navigateAtom,
  onSaveAtom,
  onTextFieldChangeAtom,
} from "../../ViewModels/ChatbotDesignHeaderViewModel";
import { useLocation, useNavigate } from "react-router-dom";
import { flowAtom, pageListAtom } from "../../Atoms/ChatDesignPublicAtom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ChatbotDesignHeaderFunctionMenu } from "./ChatbotDesignHeaderFunctionMenu";
import { MobileComponent } from "./MobileComponent";
import { Avatar, IconButton, Stack } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  handleProfileMenuOpenAtom,
  setStateDrawerAtom,
} from "../../ViewModels/HeaderViewModel";
import { userAtom } from "../../Atoms/PublicAtom";
import { ProfileMenu } from "../Root/Header/ProfileMenu";

export function ChatbotDesignHeader() {
  const [user] = useAtom(userAtom);
  const [flow] = useAtom(flowAtom);
  const [pageList] = useAtom(pageListAtom);
  const [profileOpen, profileHandleClick] = useAtom(handleProfileMenuOpenAtom);
  const [, onTextFieldChange] = useAtom(onTextFieldChangeAtom);
  const [, chatboDesignNavigate] = useAtom(navigateAtom);
  const [functionMenuOepn, furnctionHandleClick] = useAtom(
    handleChatbotDesignHeaderMakeMenuOpenAtom
  );
  const [, onSave] = useAtom(onSaveAtom);
  const [, toggleDrawer] = useAtom(setStateDrawerAtom);

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      {/* 모바일 환경 일 시 */}
      <MobileComponent />

      {/* 모바일 환경 아닐 시 */}
      <S.ChatbotDesignHeaderDiv>
        <Stack
          sx={{ flex: 1, alignItems: "center", justifyContent: "center" }}
          direction={"row"}
        >
          <IconButton onClick={(e) => toggleDrawer(true, e)}>
            <MenuIcon />
          </IconButton>
          <IconButton onClick={() => navigate("/")} sx={{ margin: "0 16px" }}>
            <Avatar alt="Remy Sharp" src={"/favicon.ico"} />
          </IconButton>
          <S.InputField
            placeholder={"챗봇 이름을 입력해주세요."}
            value={flow.name}
            onChange={onTextFieldChange}
          ></S.InputField>
        </Stack>

        <S.ButtonContainer>
          {pageList.map((page, index) =>
            location.pathname.includes(page) ? (
              <S.CurrentPageButton
                key={`page-mosigi-${index}`}
                onClick={async () => {
                  if (location.pathname.includes("flow")) {
                    const result = await onSave();

                    if (result) {
                      chatboDesignNavigate(
                        { navigate, location },
                        location.pathname.split("/")[2],
                        page
                      );
                    }
                  } else {
                    chatboDesignNavigate(
                      { navigate, location },
                      location.pathname.split("/")[2],
                      page
                    );
                  }
                }}
              >
                {page}
              </S.CurrentPageButton>
            ) : (
              <S.ElsePageButton
                key={`page-mosigi-${index}`}
                onClick={async () => {
                  if (location.pathname.includes("flow")) {
                    const result = await onSave();

                    if (result) {
                      chatboDesignNavigate(
                        { navigate, location },
                        location.pathname.split("/")[2],
                        page
                      );
                    }
                  } else {
                    chatboDesignNavigate(
                      { navigate, location },
                      location.pathname.split("/")[2],
                      page
                    );
                  }
                }}
              >
                {page}
              </S.ElsePageButton>
            )
          )}
        </S.ButtonContainer>

        <S.FunctionBox>
          <S.CustomButtonGroup aria-label="Button group with a nested menu">
            <S.FunctionButton variant="outlined" onClick={onSave}>
              저장
            </S.FunctionButton>
            <S.ExpandIconButton
              onClick={furnctionHandleClick}
              size="small"
              sx={{ ml: 2, margin: "0 16px" }}
              aria-controls={
                functionMenuOepn ? "chatbot-design-header-make-menu" : undefined
              }
              aria-haspopup="true"
              aria-expanded={functionMenuOepn ? "true" : undefined}
            >
              <ArrowDropDownIcon />
            </S.ExpandIconButton>
          </S.CustomButtonGroup>
          <S.SignupButton>
            {user?.membership}
            {/* {user!.membership !== "admin" &&
              user!.membership !== "free" &&
              user!.membership !== "mentor" &&
              `:${formatDateToDay(user!.membership_end_date)}`} */}
          </S.SignupButton>
          <IconButton
            onClick={profileHandleClick}
            size="small"
            sx={{ ml: 2, margin: "0 16px" }}
            aria-controls={profileOpen ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={profileOpen ? "true" : undefined}
          >
            <Avatar
              alt="Remy Sharp"
              src={
                user!.image === ""
                  ? ""
                  : `${process.env.REACT_APP_USER_MEDIA_ADDRESS}${user!.image}`
              }
            />
          </IconButton>

          <ChatbotDesignHeaderFunctionMenu />
          <ProfileMenu />
        </S.FunctionBox>
      </S.ChatbotDesignHeaderDiv>
    </>
  );
}

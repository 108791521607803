import { atom } from "jotai";
import {
  characterNameAtom,
  ChattingAtom,
  chattingValueAtom,
  typeAvailableStateAtom,
} from "../Atoms/ChatTestAtom";
import dayjs from "dayjs";
import { flowAtom } from "../Atoms/ChatDesignPublicAtom";
import { requestChatbot } from "../Queries/DokgabiChatbotQueries";
import { getCookie } from "../Utils/CookieUtil";
import { handleApiResponse } from "../Utils/APIUtil";

export const handleInitializeAtom = atom(null, async (get, set) => {
  const flow = get(flowAtom);
  const character = get(characterNameAtom);

  const handleStreamMessage = (
    message: string | null,
    isFirst: boolean,
    json: any
  ) => {
    var prevMessage = "";

    if (isFirst && message) {
      set(ChattingAtom, (current) => [
        ...current,
        {
          name: flow.name,
          image: flow.character_image
            ? `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${flow.character_image.file_path}`
            : "",
          text: message,
          data: null,
          date: dayjs().format("MM.DD HH:mm"),
          chattingUser: "chatbot",
          type: "markdown",
        },
      ]);
    } else if (message) {
      const chats = get(ChattingAtom);
      prevMessage = chats[chats.length - 1].text;

      set(ChattingAtom, (current) => {
        const lastItem = current[current.length - 1];

        if (lastItem) {
          return [
            ...current.slice(0, -1),
            {
              ...lastItem,
              text: message,
            },
          ];
        }

        return current;
      });
    } else if (json) {
      set(ChattingAtom, (current) => [
        ...current,
        {
          name: flow.name,
          image: flow.character_image
            ? `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${flow.character_image.file_path}`
            : "",
          text: ``,
          data: json,
          date: dayjs().format("MM.DD HH:mm"),
          chattingUser: "chatbot",
          type: "interaction",
        },
      ]);
    }
  };

  const refreshCookie = getCookie(
    process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
  );

  const accessCookie = getCookie(
    process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
  );

  const errorFunction = () => {};

  const successFunction = (results: any) => {};

  const newAccessToken = await handleApiResponse(
    refreshCookie,
    set,
    async () =>
      await requestChatbot(
        accessCookie ?? "",
        "",
        get(flowAtom).chatbot_socket_parameter,
        character,
        handleStreamMessage
      ),
    () => errorFunction(),
    (results) => successFunction(results)
  );

  if (newAccessToken) {
    await handleApiResponse(
      null,
      set,
      async () =>
        await requestChatbot(
          newAccessToken ?? "",
          "",
          get(flowAtom).chatbot_socket_parameter,
          character,
          handleStreamMessage
        ),
      () => errorFunction(),
      (results) => successFunction(results)
    );
  }

  // await requestChatbot(
  //   "",
  //   get(flowAtom).chatbot_socket_parameter,
  //   character,
  //   handleStreamMessage
  // );
});

export const handleInputChange = atom(
  null,
  (
    get,
    set,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    set(chattingValueAtom, event.target.value);
  }
);

export const handleSendMessage = atom(null, async (get, set) => {
  const inputValue = get(chattingValueAtom).trim();
  if (inputValue === "") return;
  set(typeAvailableStateAtom, false);
  const flow = get(flowAtom);
  const character = get(characterNameAtom);

  const newMessage = {
    name: flow.name,
    image: flow.character_image
      ? `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${flow.character_image.file_path}`
      : "",
    text: inputValue,
    data: null,
    date: dayjs().format("MM.DD HH:mm"),
    chattingUser: "user",
    type: "text",
  };

  set(ChattingAtom, (prevChatting) => [...prevChatting, newMessage]);
  set(chattingValueAtom, "");

  // 여기에서 backend랑 통신을 하도록 하자.
  const handleStreamMessage = (
    message: string | null,
    isFirst: boolean,
    json: any
  ) => {
    var prevMessage = "";

    if (isFirst && message) {
      set(ChattingAtom, (current) => [
        ...current,
        {
          name: flow.name,
          image: flow.character_image
            ? `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${flow.character_image.file_path}`
            : "",
          text: message,
          data: null,
          date: dayjs().format("MM.DD HH:mm"),
          chattingUser: "chatbot",
          type: "markdown",
        },
      ]);
    } else if (message) {
      const chats = get(ChattingAtom);
      prevMessage = chats[chats.length - 1].text;

      set(ChattingAtom, (current) => {
        const lastItem = current[current.length - 1];

        if (lastItem) {
          return [
            ...current.slice(0, -1),
            {
              ...lastItem,
              text: message,
            },
          ];
        }

        return current;
      });
    } else if (json) {
      set(ChattingAtom, (current) => [
        ...current,
        {
          name: flow.name,
          image: flow.character_image
            ? `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${flow.character_image.file_path}`
            : "",
          text: "",
          data: json,
          date: dayjs().format("MM.DD HH:mm"),
          chattingUser: "chatbot",
          type: "interaction",
        },
      ]);
    }
  };

  const refreshCookie = getCookie(
    process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
  );

  const accessCookie = getCookie(
    process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
  );

  const errorFunction = () => {};

  const successFunction = (results: any) => {};

  const newAccessToken = await handleApiResponse(
    refreshCookie,
    set,
    async () =>
      await requestChatbot(
        accessCookie ?? "",
        inputValue,
        get(flowAtom).chatbot_socket_parameter,
        character,
        handleStreamMessage
      ),
    () => errorFunction(),
    (results) => successFunction(results)
  );

  if (newAccessToken) {
    await handleApiResponse(
      null,
      set,
      async () =>
        await requestChatbot(
          newAccessToken ?? "",
          inputValue,
          get(flowAtom).chatbot_socket_parameter,
          character,
          handleStreamMessage
        ),
      () => errorFunction(),
      (results) => successFunction(results)
    );
  }

  // await requestChatbot(
  //   inputValue,
  //   get(flowAtom).chatbot_socket_parameter,
  //   character,
  //   handleStreamMessage
  // );

  set(typeAvailableStateAtom, true);
});

export const handleKeyDown = atom(
  null,
  async (
    get,
    set,
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      const inputValue = get(chattingValueAtom).trim();
      if (inputValue === "") return;

      set(typeAvailableStateAtom, false);
      const flow = get(flowAtom);
      const character = get(characterNameAtom);

      const newMessage = {
        name: flow.name,
        image: flow.character_image
          ? `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${flow.character_image.file_path}`
          : "",
        text: inputValue,
        data: null,
        date: dayjs().format("MM.DD HH:mm"),
        chattingUser: "user",
        type: "text",
      };

      set(ChattingAtom, (prevChatting) => [...prevChatting, newMessage]);
      set(chattingValueAtom, "");

      // 여기에서 backend랑 통신을 하도록 하자.
      const handleStreamMessage = (
        message: string | null,
        isFirst: boolean,
        json: any
      ) => {
        var prevMessage = "";

        if (isFirst && message) {
          set(ChattingAtom, (current) => [
            ...current,
            {
              name: flow.name,
              image: flow.character_image
                ? `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${flow.character_image.file_path}`
                : "",
              text: message,
              data: null,
              date: dayjs().format("MM.DD HH:mm"),
              chattingUser: "chatbot",
              type: "markdown",
            },
          ]);
        } else if (message) {
          const chats = get(ChattingAtom);
          prevMessage = chats[chats.length - 1].text;

          set(ChattingAtom, (current) => {
            const lastItem = current[current.length - 1];

            if (lastItem) {
              return [
                ...current.slice(0, -1),
                {
                  ...lastItem,
                  text: message,
                },
              ];
            }

            return current;
          });
        } else if (json) {
          set(ChattingAtom, (current) => [
            ...current,
            {
              name: flow.name,
              image: flow.character_image
                ? `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${flow.character_image.file_path}`
                : "",
              text: "",
              data: json,
              date: dayjs().format("MM.DD HH:mm"),
              chattingUser: "chatbot",
              type: "interaction",
            },
          ]);
        }
      };

      const refreshCookie = getCookie(
        process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
      );

      const accessCookie = getCookie(
        process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
      );

      const errorFunction = () => {};

      const successFunction = (results: any) => {};

      const newAccessToken = await handleApiResponse(
        refreshCookie,
        set,
        async () =>
          await requestChatbot(
            accessCookie ?? "",
            inputValue,
            get(flowAtom).chatbot_socket_parameter,
            character,
            handleStreamMessage
          ),
        () => errorFunction(),
        (results) => successFunction(results)
      );

      if (newAccessToken) {
        await handleApiResponse(
          null,
          set,
          async () =>
            await requestChatbot(
              newAccessToken ?? "",
              inputValue,
              get(flowAtom).chatbot_socket_parameter,
              character,
              handleStreamMessage
            ),
          () => errorFunction(),
          (results) => successFunction(results)
        );
      }

      // await requestChatbot(
      //   inputValue,
      //   get(flowAtom).chatbot_socket_parameter,
      //   character,
      //   handleStreamMessage
      // );

      set(typeAvailableStateAtom, true);
    }
  }
);

import {
  Box,
  Card,
  CardContent,
  Grid,
  styled as muiStyled,
} from "@mui/material";
import styled from "styled-components";

export const CustomCard = muiStyled(Card)({
  color: "white",
  background: process.env.REACT_APP_MAIN_COLOR,
  height: 253.125,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
});

export const CustomCardContent = muiStyled(CardContent)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: "column",
  marginBottom: 0,
  paddingBottom: 0,
});

export const CustomGrid = muiStyled(Grid)({
  cursor: "pointer",
  borderRadius: "5px",
});

export const MyChatbotBox = muiStyled(Box)({
  boxSizing: "border-box",
  padding: "10px",
  borderRadius: "4px",
  boxShadow: "5px 5px 10px #c2c2c2",
});

export const ChatbotImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 180px;
  border-radius: 4px;
  border: 1px solid #ececec;
  box-sizing: border-box;
`;

import { atom } from "jotai";

export interface ChattingProps {
  name: string;
  image: string;
  text: string;
  type: string;
  date: string;
  data: any;
  chattingUser: string;
}

export const isChattingAskAtom = atom<HTMLElement | null>(null);
export const chattingValueAtom = atom("");
export const ChattingAtom = atom<ChattingProps[]>([]);
export const ChattingRefAtom = atom<HTMLDivElement | null>(null);

export const typeAvailableStateAtom = atom(true);

export const characterNameAtom = atom("");

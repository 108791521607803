import React from "react";
import { Tooltip } from "@mui/material";
import { useAtom } from "jotai";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ChatIcon from "@mui/icons-material/Chat";
import ForumIcon from "@mui/icons-material/Forum";
import RoundaboutRightIcon from "@mui/icons-material/RoundaboutRight";
import * as S from "./styled";
import { onDragStartAtom } from "../../../ViewModels/ChatFlowViewModel";
import { Cameraswitch } from "@mui/icons-material";
import { userAtom } from "../../../Atoms/PublicAtom";

export function ElementBar() {
  const [, onDragStart] = useAtom(onDragStartAtom);
  const [user] = useAtom(userAtom);

  return (
    <S.ElementBarContainer>
      {/* 말풍선 모양 Element 넣기 */}
      {/* <Tooltip title="드래그해서 Welcome 노드를 만들어보세요" placement="top">
        <S.CustomExpandIconButton
          onDragStart={(event) => onDragStart(event, "welcome")}
          draggable
        >
          <WavingHandIcon />
          <S.CustomTypograpy>Welcome</S.CustomTypograpy>
        </S.CustomExpandIconButton>
      </Tooltip> */}

      {/* <Tooltip title="드래그해서 Target 노드를 만들어보세요" placement="top">
        <S.CustomExpandIconButton
          onDragStart={(event) => onDragStart(event, "target")}
          draggable
        >
          <SwitchAccountIcon />
          <S.CustomTypograpy>target 구성</S.CustomTypograpy>
        </S.CustomExpandIconButton>
      </Tooltip> */}

      <Tooltip
        title="드래그해서 Message Classify 노드를 만들어보세요"
        placement="top"
      >
        <S.CustomExpandIconButton
          onDragStart={(event) => onDragStart(event, "topic")}
          draggable
        >
          <AccountTreeIcon />
          <S.CustomTypograpy>
            사용자 입력을 주제(의도) 별로 분류
          </S.CustomTypograpy>
        </S.CustomExpandIconButton>
      </Tooltip>

      <Tooltip
        title="드래그해서 LLM Response 노드를 만들어보세요"
        placement="top"
      >
        <S.CustomExpandIconButton
          onDragStart={(event) => onDragStart(event, "basicLLM")}
          draggable
        >
          <ChatIcon />
          <S.CustomTypograpy>AI모델 활용 답변 생성</S.CustomTypograpy>
        </S.CustomExpandIconButton>
      </Tooltip>

      <Tooltip
        title="드래그해서 Asset Response 노드를 만들어보세요"
        placement="top"
      >
        <S.CustomExpandIconButton
          onDragStart={(event) => onDragStart(event, "ragLLM")}
          draggable
        >
          <ForumIcon />
          <S.CustomTypograpy>
            Asset을 활용한 AI 모델 답변 생성
          </S.CustomTypograpy>
        </S.CustomExpandIconButton>
      </Tooltip>

      {user?.membership === "admin" && (
        <Tooltip
          title="드래그해서 Interaction 노드를 만들어보세요"
          placement="top"
        >
          <S.CustomExpandIconButton
            onDragStart={(event) => onDragStart(event, "makeJson")}
            draggable
          >
            <Cameraswitch />
            <S.CustomTypograpy>
              텍스트 출력말고 다른 형태로 변경
            </S.CustomTypograpy>
          </S.CustomExpandIconButton>
        </Tooltip>
      )}

      <Tooltip
        title="드래그해서 Pre Trigger 노드를 만들어보세요"
        placement="top"
      >
        <S.CustomExpandIconButton
          onDragStart={(event) => onDragStart(event, "trigger")}
          draggable
        >
          <RoundaboutRightIcon />
          <S.CustomTypograpy>
            다른 path로 유도하기 위한 미리 trigger를 설계
          </S.CustomTypograpy>
        </S.CustomExpandIconButton>
      </Tooltip>
    </S.ElementBarContainer>
  );
}

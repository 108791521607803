import { isJsxFragment } from "typescript";
import { ResponseStatus } from "../Enum/NetworkEnum";

export async function requestChatbot(
  access: string,
  input_message: string,
  flow_socket_name: string,
  character: string,
  handleStreamMessage: (
    message: string | null,
    isFirst: boolean,
    json: any
  ) => void
): Promise<any> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({
      flow: flow_socket_name,
      message: input_message,
      user_id: "DokgabiTestUserAzmzHRDKA2dnfk123eba",
      character_name: character,
    }),
  };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/makeChatbotResponseWithCharacter`,
      requestOptions
    );

    if (response.status === 401) {
      return ResponseStatus.UNAUTHORIZED;
    } else if (response.status === 403) {
      return ResponseStatus.FORBIDDEN;
    } else if (!response.ok) {
      return ResponseStatus.ERROR;
    }

    const reader = response.body?.getReader();
    const decoder = new TextDecoder();
    let isFirst = true;
    let message = "";

    while (true) {
      const { done, value } = await reader!.read();

      if (done) {
        // 여기에 음성을 출력하는 기능 추가
        break;
      }

      const chunk = decoder.decode(value, { stream: true });

      if (chunk.startsWith("JSON:")) {
        // JSON 문자열 파싱 시도
        const json = JSON.parse(chunk.slice(5));

        // JSON 데이터를 handleStreamMessage로 전달 : JSON.stringify
        handleStreamMessage(null, true, json);
      } else {
        message += chunk;
        handleStreamMessage(message, isFirst, null);

        isFirst = false;
      }
    }
  } catch (error) {
    return ResponseStatus.ERROR;
  }
}

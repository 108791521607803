import { Box, Button, styled as muiStyled, Typography } from "@mui/material";

// -------------------- 바뀐거
export const NodeBox = muiStyled(Box)<CircleBoxProps>(
  ({ isclick, iserror }) => ({
    background: "white",
    padding: "30px",
    width: "350px",
    borderRadius: "51px",
    border:
      iserror === "true"
        ? `5px solid red`
        : isclick === "true"
        ? `5px solid ${process.env.REACT_APP_SUB_COLOR}`
        : "1px solid #8f8f8f",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    position: "relative",
    ".handle-right": {
      position: "absolute",
      right: "-10px",
      top: "50%",
      transform: "translateY(-50%)",
      background: process.env.REACT_APP_SUB_COLOR,
      width: "20px",
      height: "20px",
      border: "3px solid white",
    },
    ".handle-left": {
      position: "absolute",
      left: "-10px",
      top: "50%", // NodeBox 높이 기준으로 50%
      transform: "translateY(-50%)",
      background: process.env.REACT_APP_SUB_COLOR,
      width: "20px",
      height: "20px",
      border: "3px solid white",
    },
    ".handle-bottom-right": {
      position: "absolute",
      left: "0px",
      top: "100%", // NodeBox 높이 기준으로 50%
      // transform: "translateY(-50%)",
      background: process.env.REACT_APP_SUB_COLOR,
      width: "20px",
      height: "20px",
      border: "3px solid white",
    },
  })
);

export const NodeButton = muiStyled(Button)({
  background: process.env.REACT_APP_MAIN_COLOR,
  width: "100%",
  "&:hover": {
    backgroundColor: "#3A1A7A",
  },
});

export const ChildrenBox = muiStyled(Box)<CircleBoxProps>(
  ({ isclick, iserror }) => ({
    width: "350px",
    borderRadius: "51px",
    padding: "30px",
    background: "white",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    border:
      iserror === "true"
        ? `5px solid red`
        : isclick === "true"
        ? `5px solid ${process.env.REACT_APP_SUB_COLOR}`
        : "1px solid #8f8f8f",
    ".handle-right": {
      position: "absolute",
      right: "-10px",
      top: "50%",
      transform: "translateY(-50%)",
      background: process.env.REACT_APP_SUB_COLOR,
      width: "20px",
      height: "20px",
      border: "3px solid white",
    },
    ".handle-left": {
      position: "absolute",
      left: "-10px",
      top: "50%",
      transform: "translateY(-50%)",
      background: process.env.REACT_APP_SUB_COLOR,
      width: "20px",
      height: "20px",
      border: "3px solid white",
    },
  })
);

// ------------- 옛날 꺼
export const StartBox = muiStyled(Box)({
  width: "100px",
  height: "100px",
  borderRadius: "75px",
  padding: "20px",
  boxSizing: "border-box",
  background: process.env.REACT_APP_MAIN_COLOR,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  position: "relative",
  ".handle-right": {
    position: "absolute",
    right: "-10px",
    top: "50%",
    transform: "translateY(-50%)",
    background: process.env.REACT_APP_SUB_COLOR,
    width: "20px",
    height: "20px",
    border: "3px solid white",
  },
  ".handle-left": {
    position: "absolute",
    left: "-10px",
    top: "50%",
    transform: "translateY(-50%)",
    background: process.env.REACT_APP_SUB_COLOR,
    width: "20px",
    height: "20px",
    border: "3px solid white",
  },
});

interface CircleBoxProps {
  isclick: string;
  iserror: string;
}

export const CircleBox = muiStyled(Box)<CircleBoxProps>(
  ({ isclick, iserror }) => ({
    width: "100px",
    height: "100px",
    borderRadius: "75px",
    padding: "20px",
    boxSizing: "border-box",
    background: process.env.REACT_APP_MAIN_COLOR,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    position: "relative",
    border:
      iserror === "true"
        ? `5px solid red`
        : isclick === "true"
        ? `5px solid ${process.env.REACT_APP_SUB_COLOR}`
        : "1px solid #8f8f8f",
    ".handle-right": {
      position: "absolute",
      right: "-10px",
      top: "50%",
      transform: "translateY(-50%)",
      background: process.env.REACT_APP_SUB_COLOR,
      width: "20px",
      height: "20px",
      border: "3px solid white",
    },
    ".handle-left": {
      position: "absolute",
      left: "-10px",
      top: "50%",
      transform: "translateY(-50%)",
      background: process.env.REACT_APP_SUB_COLOR,
      width: "20px",
      height: "20px",
      border: "3px solid white",
    },
  })
);

export const ExplainBox = muiStyled(Box)<CircleBoxProps>(
  ({ isclick, iserror }) => ({
    width: "150px",
    borderRadius: "15px",
    padding: "20px",
    boxSizing: "border-box",
    background: process.env.REACT_APP_MAIN_COLOR,
    display: "flex",
    color: "white",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    border:
      iserror === "true"
        ? `5px solid red`
        : isclick === "true"
        ? `5px solid ${process.env.REACT_APP_SUB_COLOR}`
        : "1px solid #8f8f8f",
  })
);

export const NodeNameTypography = muiStyled(Typography)({
  color: "white",
  fontSize: "10px",
});

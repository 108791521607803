import { atom, useAtom } from "jotai";
import {
  completeStepAtom,
  currentStepAtom,
  guideModalIsDraggingAtom,
  hanldeGuideModalStateAtom,
  hanldeGuideModalTypeAtom,
  stepAtom,
  tempAssetAtom,
  tempChatbotNameAtom,
  tempOpenaiAPIKeyAtom,
  tempWelcomeNodeAtom,
} from "../Atoms/GuideModalAtom";
import {
  errorModalAtom,
  noticeModalAtom,
  successModalAtom,
  waitingModalAtom,
} from "../Atoms/RootAtom";
import { openaiAPIKeyAtom, userAtom } from "../Atoms/PublicAtom";
import { GuideType } from "../Enum/GuideModalEnum";
import { makeOrUpdateAsset } from "../Queries/DokgabiAssetQueries";
import { handleApiResponse } from "../Utils/APIUtil";
import { getCookie } from "../Utils/CookieUtil";
import { flowAtom } from "../Atoms/ChatDesignPublicAtom";
import { DokgabiFlowProps } from "../Models/DokgabiFlow";
import {
  BasicLLMNodeDataProps,
  RagBaseLLMDataProps,
  WelcomeDataProps,
} from "../Props/CustomNodeProps";
import { checkOpenAIAPIKey } from "../Utils/CheckOpenAIUtil";
import {
  initializeBasicLLMNodeAtom,
  initializeRAGBaseLLMNodeAtom,
} from "../Initialize/ChatFlowInitialize";
import { MarkerType } from "@xyflow/react";
import { currentMaxIdAtom } from "../Atoms/ChatFlowAtom";

export const clickSkipButtonAtom = atom(null, (get, set) => {
  set(hanldeGuideModalStateAtom, false);
  set(noticeModalAtom, {
    state: true,
    event: () => {
      localStorage.setItem("skip", "true");
      set(noticeModalAtom, (current) => ({
        ...current,
        state: false,
      }));
    },
    eventText: "이 PC에서 다시 보지 않기",
    redirectUrl: "",
    text: "이 PC에서 다시는 가이드를 보지 않으시겠습니까?",
    title: "다시 보지 않기 설정",
  });
});

export const initializeGuideModalAtom = atom(null, (get, set) => {
  set(hanldeGuideModalTypeAtom, GuideType.NONE);
  set(stepAtom, []);
  set(currentStepAtom, 0);
  set(completeStepAtom, {});
  set(tempChatbotNameAtom, "");
  set(tempOpenaiAPIKeyAtom, "");
});

export const whenStartGuideModalAtom = atom(null, (get, set) => {
  const openaiAPIKey = get(openaiAPIKeyAtom);
  set(tempOpenaiAPIKeyAtom, openaiAPIKey ?? "");
  set(tempWelcomeNodeAtom, (current) => ({
    ...current,
    data: {
      ...current.data,
      api_key: openaiAPIKey ?? "",
      type: {
        value: "user_setting_text",
        view_value: "직접 인사말 입력",
      },
    },
  }));

  const user = get(userAtom);

  if (user) {
    set(tempAssetAtom, (current) => ({
      ...current,
      maker: user.membership === "admin" ? "admin" : user.id,
    }));
  }
});

export const handleChatbotNameAtom = atom(
  (get) => get(tempChatbotNameAtom),
  (get, set, e: any) => {
    if (e.target.value === "") {
      set(completeStepAtom, (current) => ({
        ...current,
        0: false,
      }));
    }

    set(tempChatbotNameAtom, e.target.value);
  }
);

export const handleOpenAiAPIKeyAtom = atom(
  (get) => get(tempOpenaiAPIKeyAtom),
  (get, set, e: any) => {
    if (e.target.value === "") {
      set(completeStepAtom, (current) => ({
        ...current,
        0: false,
      }));
    }

    set(tempOpenaiAPIKeyAtom, e.target.value);
  }
);

export const onBlurAtom = atom(null, (get, set) => {
  const tempKey = get(tempOpenaiAPIKeyAtom);
  const name = get(tempChatbotNameAtom);

  if (tempKey !== "" && name !== "") {
    set(currentStepAtom, 1);
    set(completeStepAtom, (current) => ({
      ...current,
      0: true,
    }));

    set(tempWelcomeNodeAtom, (current) => ({
      ...current,
      data: {
        ...current.data,
        api_key: tempKey,
      },
    }));
  }
});

export const finishOneStepAtom = atom(null, (get, set) => {
  const tempKey = get(tempOpenaiAPIKeyAtom);
  const name = get(tempChatbotNameAtom);

  if (tempKey === "" && name === "")
    set(errorModalAtom, {
      state: true,
      event: null,
      eventText: "",
      redirectUrl: "",
      text: "이름과 Open AI API-KEY를 넣어주세요.",
      title: "입력 없음",
    });
  else if (tempKey === "")
    set(errorModalAtom, {
      state: true,
      event: null,
      eventText: "",
      redirectUrl: "",
      text: "Open AI API-KEY를 넣어주세요.",
      title: "입력 없음",
    });
  else if (name === "")
    set(errorModalAtom, {
      state: true,
      event: null,
      eventText: "",
      redirectUrl: "",
      text: "이름을 넣어주세요.",
      title: "입력 없음",
    });
  else {
    set(currentStepAtom, 1);
    set(completeStepAtom, (current) => ({
      ...current,
      0: true,
    }));
  }
});

export const handleNodeSettingUpdateAtom = atom(
  (get) => get(tempWelcomeNodeAtom),
  (get, set, value: any, key: string) => {
    set(tempWelcomeNodeAtom, (current) => {
      if (value === "")
        set(completeStepAtom, (current) => ({
          ...current,
          2: false,
        }));

      return {
        ...current,
        data: {
          ...current.data,
          [key]: value,
        },
      };
    });
  }
);

export const checkFinishInThirdStepAtom = atom(null, (get, set) => {
  const tempWelcomeNode = get(tempWelcomeNodeAtom);

  // 다음 스텝 넘어가기
  if (
    (tempWelcomeNode.data.type.value === "llm" &&
      tempWelcomeNode.data.model_name.value !== "" &&
      tempWelcomeNode.data.api_key !== "" &&
      tempWelcomeNode.data.prompt !== "") ||
    (tempWelcomeNode.data.type.value === "user_setting_text" &&
      tempWelcomeNode.data.welcome_message !== "")
  ) {
    set(currentStepAtom, 3);
    set(completeStepAtom, (current) => ({
      ...current,
      2: true,
    }));
  }
});

export const handleGuideModalDragOverAtom = atom(
  null,
  (get, set, event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    const isDragging = get(guideModalIsDraggingAtom);
    if (!isDragging) {
      set(guideModalIsDraggingAtom, true); // 드래그 중 상태로 전환
    }
  }
);

export const handleGuideModalDragLeaveAtom = atom(
  null,
  (get, set, event: React.DragEvent<HTMLDivElement>) => {
    const target = event.relatedTarget as HTMLElement | null;
    if (!target || !event.currentTarget.contains(target)) {
      const isDragging = get(guideModalIsDraggingAtom);
      if (isDragging) {
        set(guideModalIsDraggingAtom, false); // 드래그 중이 아닐 때만 상태 변경
      }
    }
  }
);

export const handleGuideModalDropAtom = atom(
  null,
  async (get, set, event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    set(guideModalIsDraggingAtom, false);

    // 이벤트 객체가 초기화되기 전에 필요한 데이터를 추출
    const files = event.dataTransfer.files;
    const file = files[0];

    if (files) {
      const selectAsset = get(tempAssetAtom);

      if (selectAsset.name === "") {
        set(errorModalAtom, {
          state: true,
          event: () => {
            set(errorModalAtom, (current) => ({
              ...current,
              state: false,
            }));
          },
          eventText: "어셋 이름 입력하기",
          redirectUrl: "",
          text: "어셋 이름을 넣어주세요.",
          title: "어셋 이름 없음",
        });
      } else {
        const fileNum = files.length;
        const fileSize = file.size / (1024 * 1024); // 파일 크기를 MB로 변환
        const fileName = file.name;
        const fileExp = fileName.split(".").pop()?.toLowerCase() ?? "";

        const allowedExtensions = ["pdf", "csv", "xlsx", "txt", "xlsb"];

        if (fileNum > 1) {
          set(errorModalAtom, {
            state: true,
            event: null,
            eventText: "",
            redirectUrl: "",
            text: "파일은 하나씩만 전송이 가능하고, 50MB 이하여야 합니다.",
            title: "파일 하나 이상 선택",
          });
        } else if (fileSize > 50) {
          set(errorModalAtom, {
            state: true,
            event: null,
            eventText: "",
            redirectUrl: "",
            text: "파일은 최대 50MB 까지 허용됩니다.\n만일 이 크기가 넘어가면 파일을 분할하여 올려주세요.",
            title: "파일 용량 초과",
          });
        } else if (!allowedExtensions.includes(fileExp)) {
          set(errorModalAtom, {
            state: true,
            event: null,
            eventText: "",
            redirectUrl: "",
            text: "허용된 파일 형식은 pdf, csv, xlsx, txt, xlsb 입니다.",
            title: "허용되지 않는 파일 형식",
          });
        } else {
          set(waitingModalAtom, {
            state: true,
            text: "Asset 저장중",
          });

          const selectAsset = get(tempAssetAtom);

          const refreshCookie = getCookie(
            process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
          );

          const accessCookie = getCookie(
            process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
          );

          const errorFunction = () => {
            set(errorModalAtom, {
              state: true,
              event: null,
              eventText: "",
              redirectUrl: "",
              text: "다시 한번 시도해 주세요.",
              title: "Asset 저장 실패",
            });
          };

          const successFunction = (results: any) => {
            set(tempAssetAtom, results);

            set(successModalAtom, {
              state: true,
              event: null,
              eventText: "",
              redirectUrl: "",
              text: "Asset 저장 했습니다.\n생성형 AI가 이해할 수 있는 데이터 구조로는 변환중에 있습니다.\n변환이 완료되면 메일로 알려드릴께요.",
              title: "Asset 저장 성공",
            });

            set(completeStepAtom, (current) => ({
              ...current,
              3: true,
            }));
          };

          const newAccessToken = await handleApiResponse(
            refreshCookie,
            set,
            () => makeOrUpdateAsset(accessCookie ?? "", file, selectAsset),
            () => errorFunction(),
            (results) => successFunction(results)
          );

          if (newAccessToken) {
            await handleApiResponse(
              null,
              set,
              () => makeOrUpdateAsset(newAccessToken ?? "", file, selectAsset),
              () => errorFunction(),
              (results) => successFunction(results)
            );
          }

          set(waitingModalAtom, {
            state: false,
            text: "",
          });
        }
      }
    } else {
      set(errorModalAtom, {
        state: true,
        event: null,
        eventText: "",
        redirectUrl: "",
        text: "파일을 선택해주세요.",
        title: "파일 선택 없음",
      });
    }
  }
);

export const whenFinishButtonClickAtom = atom(null, async (get, set) => {
  const hanldeGuideModalType = get(hanldeGuideModalTypeAtom);
  const tempOpenaiAPIKey = get(tempOpenaiAPIKeyAtom);
  const name = get(tempChatbotNameAtom);
  let openaiAPIKey = get(openaiAPIKeyAtom);

  // API Key 업데이트
  if (openaiAPIKey !== tempOpenaiAPIKey) {
    const result = await checkOpenAIAPIKey(tempOpenaiAPIKey);

    if (result) {
      set(openaiAPIKeyAtom, tempOpenaiAPIKey);
      openaiAPIKey = tempOpenaiAPIKey;
    }
  }

  const currentFlow = get(flowAtom);
  let updatedFlow = { ...currentFlow }; // 기존 flow 객체 복사

  if (hanldeGuideModalType === GuideType.TEMPLATE) {
    updatedFlow = {
      ...updatedFlow,
      name,
      flow_nodes: updatedFlow.flow_nodes.map((node) => {
        if (node.type === "welcome") {
          return {
            ...node,
            data: {
              ...node.data,
              api_key: openaiAPIKey,
            },
          };
        } else if (node.type === "basicLLM") {
          return {
            ...node,
            data: {
              ...node.data,
              api_key: openaiAPIKey,
            },
          };
        } else if (node.type === "ragLLM") {
          return {
            ...node,
            data: {
              ...node.data,
              api_key: openaiAPIKey,
            },
          };
        }
        return node;
      }),
    };
  } else {
    const tempWelcome = get(tempWelcomeNodeAtom);
    const tempAsset = get(tempAssetAtom);

    let updatedNodes = updatedFlow.flow_nodes.map((node) =>
      node.type === "welcome"
        ? {
            ...tempWelcome,
            id: "1",
            position: { x: 265, y: -185 },
            data: {
              ...tempWelcome.data,
              id: "1",
            },
          }
        : node
    );

    if (tempAsset.id !== -1) {
      updatedNodes = [
        ...updatedNodes,
        {
          ...initializeRAGBaseLLMNodeAtom,
          id: "2",
          position: { x: 896, y: -298 },
          data: {
            ...initializeRAGBaseLLMNodeAtom.data,
            id: "2",
            output_type: { value: "text", view_value: "텍스트로 아웃풋 출력" },
            grounding_data: tempAsset,
            model_name: {
              type: "LLM",
              value: "gpt-4o-mini",
              view_value: "GPT 4o mini",
            },
            api_key: openaiAPIKey,
            prompt: "사용자 질문에 해당 Asset을 이용하여 답변해주세요.",
            is_using_generative_ai: true,
          },
        },
      ];
      updatedFlow = {
        ...updatedFlow,
        flow_used_assets: [...updatedFlow.flow_used_assets, tempAsset],
      };
    } else {
      updatedNodes = [
        ...updatedNodes,
        {
          ...initializeBasicLLMNodeAtom,
          id: "2",
          position: { x: 897, y: -206 },
          data: {
            ...initializeBasicLLMNodeAtom.data,
            id: "2",
            model_name: {
              type: "LLM",
              value: "gpt-4o-mini",
              view_value: "GPT 4o mini",
            },
            api_key: openaiAPIKey,
            prompt: "사용자 질문에 답변해주세요.",
          },
        },
      ];
    }

    updatedFlow = {
      ...updatedFlow,
      name,
      flow_nodes: updatedNodes,
      flow_edges: [
        ...updatedFlow.flow_edges,
        {
          id: "xy-edge__11-right-22-left",
          source: "1",
          sourceHandle: "1-right",
          target: "2",
          targetHandle: "2-left",
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 20,
            height: 20,
            color: process.env.REACT_APP_MAIN_COLOR,
          },
          style: {
            strokeWidth: 3,
            stroke: process.env.REACT_APP_MAIN_COLOR,
          },
        },
      ],
    };

    set(currentMaxIdAtom, 2);
  }

  // 업데이트된 flow 상태 설정
  set(flowAtom, updatedFlow);

  // 모달 상태 초기화
  set(hanldeGuideModalStateAtom, false);
  set(hanldeGuideModalTypeAtom, GuideType.NONE);
  set(stepAtom, []);
  set(currentStepAtom, 0);
  set(completeStepAtom, {});
  set(tempChatbotNameAtom, "");
  set(tempOpenaiAPIKeyAtom, "");
});

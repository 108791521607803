export const welcomeOptions = [
  {
    value: "llm",
    view_value: "LLM 이용 인사말 생성",
  },
  {
    value: "user_setting_text",
    view_value: "직접 인사말 입력",
  },
];

export const targetFeatureOptions = [
  { value: "age", view_value: "연령" },
  { value: "gender", view_value: "성별" },
  { value: "job", view_value: "직업" },
];

export const ageOptions = [
  { value: "0", view_value: "10대 미만" },
  { value: "10", view_value: "10대" },
  { value: "20", view_value: "20대" },
  { value: "30", view_value: "30대" },
  { value: "40", view_value: "40대" },
  { value: "50", view_value: "50대" },
  { value: "60", view_value: "50대 이상" },
];

export const genderOptions = [
  { value: "man", view_value: "남성" },
  { value: "woman", view_value: "여성" },
];

export const jobOptions = [
  { value: "True", view_value: "직업 있음" },
  { value: "False", view_value: "직업 없음" },
];

export const modelOptions = [
  { type: "LLM", value: "gpt-3.5-turbo-0125", view_value: "GPT 3.5 TURBO" },
  { type: "LLM", value: "gpt-4-1106-preview", view_value: "GPT 4" },
  { type: "LLM", value: "gpt-4o", view_value: "GPT 4o" },
  { type: "LLM", value: "gpt-4o-mini", view_value: "GPT 4o mini" },
];

// Sorting the options by type
export const sortedModelOptions = modelOptions.sort((a, b) => {
  if (a.type < b.type) return -1;
  if (a.type > b.type) return 1;
  return 0;
});

export const outputTypeOptions = [
  { value: "text", view_value: "텍스트로 아웃풋 출력" },
  { value: "image", view_value: "이미지로 아웃풋 출력" },
  { value: "video", view_value: "영상으로 아웃풋 출력" },
];

// Sorting the options by type
export const sortedoutputTypeOptions = outputTypeOptions.sort((a, b) => {
  if (a.view_value < b.view_value) return -1;
  if (a.view_value > b.view_value) return 1;
  return 0;
});

export const triggerTypeOptions = [
  // {
  //   value: "recommend",
  //   view_value: "추천질문으로 유도",
  // },
  {
    value: "text",
    view_value: "역질문으로 유도",
  },
];

export const jsonTypeOptions = [
  { value: "map", view_value: "지도로 보여주기" },
  { value: "image", view_value: "이미지로 보여주기" },
  { value: "chart", view_value: "차트로 보여주기" },
  { value: "etc", view_value: "내 마음대로 지정하기" },
];

export const adminJsonTypeOptions = [
  { value: "map", view_value: "지도로 보여주기" },
  { value: "image", view_value: "이미지로 보여주기" },
  { value: "chart", view_value: "차트로 보여주기" },
  { value: "etc", view_value: "내 마음대로 지정하기" },
];

export const chartTypeOptions = [
  { value: "bar", view_value: "막대 차트" },
  { value: "line", view_value: "라인 차트" },
  { value: "scatter", view_value: "산점도" },
];

export const dataNumOptions = Array.from({ length: 46 }, (_, i) => `${i + 5}`);

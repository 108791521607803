import React, { useEffect } from "react";
import * as S from "./styled";
import { useAtom } from "jotai";
import { Divider, IconButton, Stack, Typography } from "@mui/material";
import {
  resizeAtom,
  setStateIsResizingAtom,
} from "../../../ViewModels/SettingBarViewModel";
import CloseIcon from "@mui/icons-material/Close";
import { handleNodeSettingDoneAtom } from "../../../ViewModels/ChatFlowViewModel";
import { WelcomeNodeSetting } from "./CustomNodeSetting/WelcomeNodeSetting";
import { TargetNodeSetting } from "./CustomNodeSetting/TargetNodeSetting";
import { TopicNodeSetting } from "./CustomNodeSetting/TopicNodeSetting";
import { BasicLLMNodeSetting } from "./CustomNodeSetting/BasicLLMNodeSetting";
import { RagLLMNodeSetting } from "./CustomNodeSetting/RagLLMNodeSetting";
import { TriggerNodeSetting } from "./CustomNodeSetting/TriggerNodeSetting";
import { MakeJsonNodeSetting } from "./CustomNodeSetting/MakeJsonNodeSetting";

export function SettingBar() {
  const [currentEditingNode, handleNodeSettingDone] = useAtom(
    handleNodeSettingDoneAtom
  );
  const [isResizing, setStateIsResizing] = useAtom(setStateIsResizingAtom);
  const [width, resize] = useAtom(resizeAtom);

  useEffect(() => {
    document.addEventListener("mousemove", resize);
    document.addEventListener("mouseup", () => setStateIsResizing(false));
    return () => {
      document.removeEventListener("mousemove", resize);
      document.removeEventListener("mouseup", () => setStateIsResizing(true));
    };
  }, [isResizing]);

  if (currentEditingNode === null) return null;

  // 선택된 타입에 맞춰 세팅 될 그 템플릿 세팅하기
  let nodeSettingComponent = null;
  let title = "";

  switch (currentEditingNode.type) {
    case "welcome":
      nodeSettingComponent = <WelcomeNodeSetting />;
      title = "Welcome";
      break;
    case "target":
      nodeSettingComponent = <TargetNodeSetting />;
      title = "Target";
      break;
    case "topic":
      nodeSettingComponent = <TopicNodeSetting />;
      title = "Topic";
      break;
    case "basicLLM":
      nodeSettingComponent = <BasicLLMNodeSetting />;
      title = "Generative AI";
      break;
    case "ragLLM":
      nodeSettingComponent = <RagLLMNodeSetting />;
      title = "Asset base AI";
      break;
    case "makeJson":
      nodeSettingComponent = <MakeJsonNodeSetting />;
      title = "Interaction";
      break;
    case "trigger":
      nodeSettingComponent = <TriggerNodeSetting />;
      title = "Trigger";
      break;
    default:
      nodeSettingComponent = null;
  }

  return (
    <S.SettingBarFullContainer
      currenteditingnode={currentEditingNode !== null ? "true" : "false"}
      width={`${width}`}
    >
      <S.SettingBarResizeContainer
        onMouseDown={() => setStateIsResizing(true)}
      />
      <S.SettingBarContainer
        isopen={currentEditingNode !== null ? "true" : "false"}
      >
        <Stack
          direction={"row"}
          sx={{
            justifyContent: "space-between",
            width: "100%",

            alignItems: "center",
          }}
        >
          <Typography>{title}</Typography>
          <IconButton onClick={handleNodeSettingDone}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider sx={{ width: "100%", margin: 0, padding: 0 }} />

        <S.SettingBarInnerContainer>
          {nodeSettingComponent}
        </S.SettingBarInnerContainer>
      </S.SettingBarContainer>
    </S.SettingBarFullContainer>
  );
}

import React from "react";
import { useAtom } from "jotai";
import { Avatar, Button, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as S from "./styled";
import { userAtom } from "../../../Atoms/PublicAtom";
import {
  handleNoticeWhenServiceIsInDevelopAtom,
  handleProfileMenuOpenAtom,
  setStateDrawerAtom,
} from "../../../ViewModels/HeaderViewModel";
import { ProfileMenu } from "./ProfileMenu";
import MenuIcon from "@mui/icons-material/Menu";
import { formatDateToDay } from "../../../Utils/DatetimeUtil";

export function Header() {
  const [user] = useAtom(userAtom);
  const [profileOpen, profileHandleClick] = useAtom(handleProfileMenuOpenAtom);

  const [, toggleDrawer] = useAtom(setStateDrawerAtom);
  const [, handleNoticeWhenServiceIsInDevelop] = useAtom(
    handleNoticeWhenServiceIsInDevelopAtom
  );

  const navigate = useNavigate();

  return (
    <S.HeaderContainer>
      {/* 모바일 환경일 시 */}
      <S.HeaderMobileContainer>
        <IconButton onClick={(e) => toggleDrawer(true, e)}>
          <MenuIcon />
        </IconButton>
      </S.HeaderMobileContainer>

      {/* 모바일 환경 아닐 시 */}
      <S.HeaderInnerContainer>
        <S.HeaderInnerInnerContainer gap="0">
          <IconButton onClick={(e) => toggleDrawer(true, e)}>
            <MenuIcon />
          </IconButton>
          <IconButton onClick={() => navigate("/")} sx={{ margin: "0 16px" }}>
            <Avatar alt="Remy Sharp" src={"/favicon.ico"} />
          </IconButton>
          <Button
            onClick={() =>
              (window.location.href = process.env.REACT_APP_DOKGABI_PAY_ADDRESS)
            }
            aria-haspopup="true"
            sx={{
              color: "black",
              height: "100%",
              borderRadius: "0",
              padding: "0 20px",
              "&:hover": {
                background: "rgba(211,211,211,0.2)",
              },
            }}
          >
            요금제
          </Button>
          <Button
            onClick={handleNoticeWhenServiceIsInDevelop}
            aria-haspopup="true"
            sx={{
              color: "black",
              height: "100%",
              borderRadius: "0",
              padding: "0 20px",
              "&:hover": {
                background: "rgba(211,211,211,0.2)",
              },
            }}
          >
            튜토리얼
          </Button>
        </S.HeaderInnerInnerContainer>

        <S.HeaderInnerInnerContainer gap="0px">
          <S.SignupButton onClick={() => navigate("/profile")}>
            {user?.membership}
            {user!.membership !== "admin" &&
            user!.membership !== "free" &&
            user!.membership !== "mentor"
              ? new Date(user!.membership_end_date).getTime() ===
                new Date("9999-12-31T00:00:00").getTime()
                ? ":permanent"
                : `:${formatDateToDay(user!.membership_end_date)}`
              : null}
          </S.SignupButton>
          <IconButton
            onClick={profileHandleClick}
            size="small"
            sx={{ ml: 2, margin: "0 16px" }}
            aria-controls={profileOpen ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={profileOpen ? "true" : undefined}
          >
            <Avatar
              alt="Remy Sharp"
              src={
                user!.image === ""
                  ? ""
                  : `${process.env.REACT_APP_USER_MEDIA_ADDRESS}${user?.image}`
              }
            />
          </IconButton>
        </S.HeaderInnerInnerContainer>
      </S.HeaderInnerContainer>

      {/* <FunctionMenu />
      <MakeMenu /> */}
      <ProfileMenu />
    </S.HeaderContainer>
  );
}

import { Avatar, Button, Card, Grid, styled as muiStyled } from "@mui/material";

export const CustomCard = muiStyled(Card)({
  boxShadow: "5px 5px 10px #c2c2c2",
  padding: "10px",
});

export const CustomAvatar = muiStyled(Avatar)({
  background: "transparent",
});

export const CustomGrid = muiStyled(Grid)({
  position: "relative",
  padding: "10px 30px",
  alignItems: "center",
  justifyContent: "center",
  "& button": {
    visibility: "hidden",
  },
  "&:hover img": {
    opacity: 0.3,
  },
  "&:hover button": {
    visibility: "visible",
  },
});

export const TestButton = muiStyled(Button)({
  position: "absolute",
  zIndex: 1,
  background: process.env.REACT_APP_MAIN_COLOR,
  "&:hover": {
    background: process.env.REACT_APP_MAIN_COLOR,
  },
});

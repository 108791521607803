import React from "react";
import * as S from "../Components/Error/styled";

export function UserLoginError() {
  return (
    <S.ErrorFullContainer>
      <S.TitleText>DOKGABI™</S.TitleText>
      <S.SubText>로그인 후에 이용해주세요.</S.SubText>
      <S.CustomButton
        variant={"outlined"}
        onClick={() =>
          (window.location.href = `${process.env.REACT_APP_DOKGABI_HOME_ADDRESS}`)
        }
      >
        독갑이 홈페이지로
      </S.CustomButton>
    </S.ErrorFullContainer>
  );
}

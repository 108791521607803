import { atom, useAtom } from "jotai";
import {
  assetLibraryCurrentPageAtom,
  assetLibraryExpandedRowAtom,
  assetLibraryListAtom,
  assetLibrarytabValueAtom,
  assetNameRefAtom,
  assetViewFileCurrentPageAtom,
  assetViewFileCurrentRowsAtom,
  assetViewFileCurrentSelectedRowAtom,
  currentAssetViewFileAtom,
  currentEditAssetLibraryIndexAtom,
  isDraggingAtom,
  keywordInputValueAtom,
  tabValueAtom,
} from "../Atoms/ChatMakeAssetAtom";
import { selectAssetAtom } from "../Atoms/ChatAssetAtom";
import { initialDokgabiAsset } from "../Initialize/ChatFlowInitialize";
import {
  errorModalAtom,
  noticeModalAtom,
  successModalAtom,
  waitingModalAtom,
} from "../Atoms/RootAtom";
import {
  deleteAssetLibrary,
  deleteFileFromVector,
  makeOrUpdateAsset,
  updateAssetLibrary,
} from "../Queries/DokgabiAssetQueries";
import {
  DokgabiAssetLibraryProps,
  DokgabiAssetProps,
} from "../Models/DokgabiFlow";
import { getCookie } from "../Utils/CookieUtil";
import { handleApiResponse } from "../Utils/APIUtil";

export const delteFileFromVectorAtom = atom(
  null,
  async (
    get,
    set,
    asset: DokgabiAssetProps,
    file: {
      file_name: string;
      file_type: string;
      file_view_name: string;
      file_vector_progress: number;
      task_id: string;
    },
    index: number
  ) => {
    if (
      asset.before_vector_file_path.length > 1 &&
      file.file_vector_progress === 100
    ) {
      const deleteEvent = async (
        asset: DokgabiAssetProps,
        file: {
          file_name: string;
          file_type: string;
          file_view_name: string;
          file_vector_progress: number;
          task_id: string;
        },
        index: number
      ) => {
        set(noticeModalAtom, (current) => ({
          ...current,
          state: false,
        }));

        set(waitingModalAtom, {
          state: true,
          text: "삭제 중",
        });

        const refreshCookie = getCookie(
          process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
        );

        const accessCookie = getCookie(
          process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
        );

        const errorFunction = () => {
          set(waitingModalAtom, {
            state: false,
            text: "삭제 중",
          });
          set(errorModalAtom, {
            state: true,
            event: null,
            eventText: "",
            redirectUrl: "",
            text: "삭제에 실패하셨습니다.",
            title: "삭제 실패",
          });
        };

        const successFunction = (results: any) => {
          set(selectAssetAtom, (current) => ({
            ...current,
            before_vector_file_path: current.before_vector_file_path.filter(
              (value, i) => i !== index
            ),
          }));

          set(waitingModalAtom, {
            state: false,
            text: "삭제 중",
          });
          set(successModalAtom, {
            state: true,
            event: null,
            eventText: "",
            redirectUrl: "",
            text: "삭제에 성공하셨습니다.",
            title: "삭제 성공",
          });
        };

        const newAccessToken = await handleApiResponse(
          refreshCookie,
          set,
          () => deleteFileFromVector(accessCookie ?? "", asset, file, index),
          () => errorFunction(),
          (results) => successFunction(results)
        );

        if (newAccessToken) {
          await handleApiResponse(
            null,
            set,
            () =>
              deleteFileFromVector(newAccessToken ?? "", asset, file, index),
            () => errorFunction(),
            (results) => successFunction(results)
          );
        }
      };

      set(noticeModalAtom, {
        state: true,
        event: () => deleteEvent(asset, file, index),
        eventText: "삭제",
        redirectUrl: "",
        text: "정말로 삭제하시겠습니까?",
        title: "삭제 요청",
      });
    } else if (file.file_vector_progress < 100) {
      set(errorModalAtom, {
        state: true,
        event: () => window.location.reload(),
        eventText: "새로고침",
        redirectUrl: "",
        text: "Asset 완료 이후 삭제가 가능합니다.\n반영이 안되었다면 새로고침 해주세요",
        title: "삭제 실패",
      });
    } else {
      set(errorModalAtom, {
        state: true,
        event: null,
        eventText: "",
        redirectUrl: "",
        text: "사용중인 File이 하나 이상은 존재해야 합니다.",
        title: "삭제 실패",
      });
    }
  }
);

export const updateAssetLibraryRowAtom = atom(
  null,
  async (get, set, row: DokgabiAssetLibraryProps, queryClient: any) => {
    set(currentEditAssetLibraryIndexAtom, null);

    const refreshCookie = getCookie(
      process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
    );

    const accessCookie = getCookie(
      process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
    );
    const errorFunction = () => {};
    const successFunction = (results: any) => {};

    const newAccessToken = await handleApiResponse(
      refreshCookie,
      set,
      () => updateAssetLibrary(accessCookie ?? "", row),
      () => errorFunction(),
      (results) => successFunction(results)
    );

    if (newAccessToken) {
      await handleApiResponse(
        null,
        set,
        () => updateAssetLibrary(newAccessToken ?? "", row),
        () => errorFunction(),
        (results) => successFunction(results)
      );
    }
  }
);

export const handleAssetLibraryRowUpdateAtom = atom(
  null,
  (
    get,
    set,
    value: string,
    key: keyof DokgabiAssetLibraryProps,
    row: DokgabiAssetLibraryProps
  ) => {
    set(assetLibraryListAtom, (current) => {
      return current.map((item) =>
        item.id === row.id ? { ...item, [key]: value } : item
      );
    });
  }
);

export const handleAssetLibraryDeleteAtom = atom(
  null,
  async (get, set, row: DokgabiAssetLibraryProps) => {
    const refreshCookie = getCookie(
      process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
    );

    const accessCookie = getCookie(
      process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
    );
    const errorFunction = () => {
      set(errorModalAtom, {
        state: true,
        event: null,
        eventText: "",
        redirectUrl: "",
        text: "삭제에 실패했습니다. 다시 시도해주세요.",
        title: "실패",
      });
    };
    const successFunction = (results: any) => {
      set(assetLibraryListAtom, (current) =>
        current.filter((value, index) => value !== row)
      );
    };

    const newAccessToken = await handleApiResponse(
      refreshCookie,
      set,
      () => deleteAssetLibrary(accessCookie ?? "", row),
      () => errorFunction(),
      (results) => successFunction(results)
    );

    if (newAccessToken) {
      await handleApiResponse(
        null,
        set,
        () => deleteAssetLibrary(newAccessToken ?? "", row),
        () => errorFunction(),
        (results) => successFunction(results)
      );
    }
  }
);

export const handleRowClickAtom = atom(
  (get) => get(assetViewFileCurrentSelectedRowAtom),
  (get, set, row: any) => set(assetViewFileCurrentSelectedRowAtom, row)
);

export const handleUsingFilesGridScrollAtom = atom(
  null,
  (get, set, event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

    // 스크롤이 거의 맨 밑에 도달했는지 확인 (여유값으로 5px을 줌)
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      set(assetViewFileCurrentPageAtom, (current) => current + 1);

      set(waitingModalAtom, {
        state: true,
        text: "데이터 가져오는 중",
      });
    }
  }
);

export const handleAsetLibraryTableScrollAtom = atom(
  null,
  (get, set, event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    const buffer = 100; // 50px 정도의 여유를 줌

    // 스크롤이 맨 밑에 거의 도달했는지 확인 (버퍼를 둠)
    if (scrollTop + clientHeight + buffer >= scrollHeight) {
      set(assetLibraryCurrentPageAtom, (current) => current + 1);
    }
  }
);

export const handleExpandClickAtom = atom(
  (get) => get(assetLibraryExpandedRowAtom),
  (get, set, index: number) => {
    const expandedRow = get(assetLibraryExpandedRowAtom);
    set(assetLibraryExpandedRowAtom, expandedRow === index ? null : index);
  }
);

export const handleTabChangeAtom = atom(
  (get) => get(assetLibrarytabValueAtom),
  (get, set, value: number) => set(assetLibrarytabValueAtom, value)
);

export const handleChipValueKeyDownAtom = atom(
  null,
  (get, set, event: React.KeyboardEvent<HTMLInputElement>) => {
    const chipValue = get(keywordInputValueAtom).trim();
    const currentKeywords = get(selectAssetAtom).keywords || [];

    // Enter 키를 누르고, chipValue가 빈 값이 아니면서 이미 존재하지 않을 때만 추가
    if (
      event.key === "Enter" &&
      chipValue !== "" &&
      !currentKeywords.includes(chipValue)
    ) {
      set(selectAssetAtom, (current) => ({
        ...current,
        keywords: [...currentKeywords, chipValue],
      }));

      // 입력값 초기화
      set(keywordInputValueAtom, "");
    }
  }
);

export const handleDeleteKeywordAtom = atom(null, (get, set, value: string) => {
  set(selectAssetAtom, (current) => ({
    ...current,
    keywords: current.keywords
      ? current.keywords.filter((keyword) => keyword !== value)
      : current.keywords,
  }));
});

export const handleFileSelectAtom = atom(
  (get) => get(currentAssetViewFileAtom),
  (
    get,
    set,
    file: {
      file_name: string;
      file_type: string;
      file_view_name: string;
      file_vector_progress: number;
      task_id: string;
    }
  ) => {
    set(tabValueAtom, 1);
    set(currentAssetViewFileAtom, (current) => {
      if (current === file) return current;
      else {
        set(assetViewFileCurrentPageAtom, 1);
        set(assetViewFileCurrentSelectedRowAtom, null);
        set(assetViewFileCurrentRowsAtom, []);
        return file;
      }
    });
  }
);

export const handleAssetLibraryAtom = atom(null, (get, set) => {
  set(tabValueAtom, 0);
  set(currentAssetViewFileAtom, null);
  set(assetViewFileCurrentPageAtom, 1);
  set(assetViewFileCurrentSelectedRowAtom, null);
  set(assetViewFileCurrentRowsAtom, []);
});

export const initializeMakeAssestAtom = atom(null, (get, set) => {
  set(selectAssetAtom, initialDokgabiAsset);

  set(keywordInputValueAtom, "");
  set(tabValueAtom, 0);
  set(currentAssetViewFileAtom, null);

  set(assetLibraryCurrentPageAtom, 1);
  set(assetLibraryExpandedRowAtom, null);
  set(assetLibrarytabValueAtom, 0);
  set(assetLibraryListAtom, []);

  set(assetViewFileCurrentPageAtom, 1);
  set(assetViewFileCurrentSelectedRowAtom, null);
  set(assetViewFileCurrentRowsAtom, []);
});

export const handleDragOverAtom = atom(
  null,
  (get, set, event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    const isDragging = get(isDraggingAtom);
    if (!isDragging) {
      set(isDraggingAtom, true); // 드래그 중 상태로 전환
    }
  }
);

export const handleDragLeaveAtom = atom(
  null,
  (get, set, event: React.DragEvent<HTMLDivElement>) => {
    const target = event.relatedTarget as HTMLElement | null;
    if (!target || !event.currentTarget.contains(target)) {
      const isDragging = get(isDraggingAtom);
      if (isDragging) {
        set(isDraggingAtom, false); // 드래그 중이 아닐 때만 상태 변경
      }
    }
  }
);

export const handleDropAtom = atom(
  null,
  async (get, set, event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    set(isDraggingAtom, false);

    // 이벤트 객체 초기화 전에 파일 데이터 추출
    const files = event.dataTransfer.files;
    if (files) {
      const file = files[0]; // 첫 번째 파일 추출
      const selectAsset = get(selectAssetAtom);
      const assetNameRef = get(assetNameRefAtom);

      if (selectAsset.name === "") {
        set(errorModalAtom, {
          state: true,
          event: () => {
            set(errorModalAtom, (current) => ({
              ...current,
              state: false,
            }));

            setTimeout(() => {
              if (assetNameRef && assetNameRef.current) {
                assetNameRef.current.focus();
              }
            }, 0);
          },
          eventText: "어셋 이름 입력하기",
          redirectUrl: "",
          text: "어셋 이름을 넣어주세요.",
          title: "어셋 이름 없음",
        });
      } else {
        const fileNum = files.length;
        const fileSize = file.size / (1024 * 1024); // 파일 크기를 MB로 변환
        const fileName = file.name;
        const fileExp = fileName.split(".").pop()?.toLowerCase() ?? "";

        const allowedExtensions = ["pdf", "csv", "xlsx", "txt"];

        if (fileNum > 1) {
          set(errorModalAtom, {
            state: true,
            event: null,
            eventText: "",
            redirectUrl: "",
            text: "파일은 하나씩만 전송이 가능하고, 50MB 이하여야 합니다.",
            title: "파일 하나 이상 선택",
          });
        } else if (fileSize > 50) {
          set(errorModalAtom, {
            state: true,
            event: null,
            eventText: "",
            redirectUrl: "",
            text: "파일은 최대 50MB 까지 허용됩니다.\n만일 이 크기가 넘어가면 파일을 분할하여 올려주세요.",
            title: "파일 용량 초과",
          });
        } else if (!allowedExtensions.includes(fileExp)) {
          set(errorModalAtom, {
            state: true,
            event: null,
            eventText: "",
            redirectUrl: "",
            text: "허용된 파일 형식은 pdf, csv, xlsx, txt 입니다.",
            title: "허용되지 않는 파일 형식",
          });
        } else {
          set(waitingModalAtom, {
            state: true,
            text: "Asset 저장중",
          });

          const refreshCookie = getCookie(
            process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
          );

          const accessCookie = getCookie(
            process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
          );

          const errorFunction = () => {
            set(errorModalAtom, {
              state: true,
              event: null,
              eventText: "",
              redirectUrl: "",
              text: "다시 한번 시도해 주세요.",
              title: "Asset 저장 실패",
            });
          };

          const successFunction = (results: any) => {
            set(selectAssetAtom, results);

            set(successModalAtom, {
              state: true,
              event: null,
              eventText: "",
              redirectUrl: "",
              text: "Asset 저장 했습니다.\n생성형 AI가 이해할 수 있는 데이터 구조로는 변환중에 있습니다.\n변환이 완료되면 메일로 알려드릴께요.",
              title: "Asset 저장 성공",
            });
          };

          const newAccessToken = await handleApiResponse(
            refreshCookie,
            set,
            () => makeOrUpdateAsset(accessCookie ?? "", file, selectAsset),
            () => errorFunction(),
            (results) => successFunction(results)
          );

          if (newAccessToken) {
            await handleApiResponse(
              null,
              set,
              () => makeOrUpdateAsset(newAccessToken ?? "", file, selectAsset),
              () => errorFunction(),
              (results) => successFunction(results)
            );
          }

          set(waitingModalAtom, {
            state: false,
            text: "",
          });
        }
      }
    } else {
      set(errorModalAtom, {
        state: true,
        event: null,
        eventText: "",
        redirectUrl: "",
        text: "파일을 선택해주세요.",
        title: "파일 선택 없음",
      });
    }
  }
);

export const handleFileChangeAtom = atom(
  null,
  async (get, set, event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0]; // 첫 번째 파일 추출
      const selectAsset = get(selectAssetAtom);
      const assetNameRef = get(assetNameRefAtom);

      if (selectAsset.name === "") {
        set(errorModalAtom, {
          state: true,
          event: () => {
            set(errorModalAtom, (current) => ({
              ...current,
              state: false,
            }));

            setTimeout(() => {
              if (assetNameRef && assetNameRef.current) {
                assetNameRef.current.focus();
              }
            }, 0);
          },
          eventText: "어셋 이름 입력하기",
          redirectUrl: "",
          text: "어셋 이름을 넣어주세요.",
          title: "어셋 이름 없음",
        });
      } else {
        const fileNum = event.target.files.length;
        const fileSize = file.size / (1024 * 1024); // 파일 크기를 MB로 변환
        const fileName = file.name;
        const fileExp = fileName.split(".").pop()?.toLowerCase() ?? "";

        const allowedExtensions = ["pdf", "csv", "xlsx", "txt"];

        if (fileNum === 0) {
          set(errorModalAtom, {
            state: true,
            event: null,
            eventText: "",
            redirectUrl: "",
            text: "파일은 하나씩만 전송이 가능하고, 50MB 이하여야 합니다.",
            title: "파일 선택 안함",
          });
        } else if (fileNum > 1) {
          set(errorModalAtom, {
            state: true,
            event: null,
            eventText: "",
            redirectUrl: "",
            text: "파일은 하나씩만 전송이 가능하고, 50MB 이하여야 합니다.",
            title: "파일 하나 이상 선택",
          });
        } else if (fileSize > 50) {
          set(errorModalAtom, {
            state: true,
            event: null,
            eventText: "",
            redirectUrl: "",
            text: "파일은 최대 50MB 까지 허용됩니다.\n만일 이 크기가 넘어가면 파일을 분할하여 올려주세요.",
            title: "파일 용량 초과",
          });
        } else if (!allowedExtensions.includes(fileExp)) {
          set(errorModalAtom, {
            state: true,
            event: null,
            eventText: "",
            redirectUrl: "",
            text: "허용된 파일 형식은 pdf, csv, xlsx, txt 입니다.",
            title: "허용되지 않는 파일 형식",
          });
        } else {
          set(waitingModalAtom, {
            state: true,
            text: "Asset 저장중",
          });

          const refreshCookie = getCookie(
            process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
          );

          const accessCookie = getCookie(
            process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
          );

          const errorFunction = () => {
            set(errorModalAtom, {
              state: true,
              event: null,
              eventText: "",
              redirectUrl: "",
              text: "다시 한번 시도해 주세요.",
              title: "Asset 저장 실패",
            });
          };

          const successFunction = (results: any) => {
            set(selectAssetAtom, results);

            set(successModalAtom, {
              state: true,
              event: null,
              eventText: "",
              redirectUrl: "",
              text: "Asset 저장 했습니다.\n생성형 AI가 이해할 수 있는 데이터 구조로는 변환중에 있습니다.\n변환이 완료되면 메일로 알려드릴께요.",
              title: "Asset 저장 성공",
            });
          };

          const newAccessToken = await handleApiResponse(
            refreshCookie,
            set,
            () => makeOrUpdateAsset(accessCookie ?? "", file, selectAsset),
            () => errorFunction(),
            (results) => successFunction(results)
          );

          if (newAccessToken) {
            await handleApiResponse(
              null,
              set,
              () => makeOrUpdateAsset(newAccessToken ?? "", file, selectAsset),
              () => errorFunction(),
              (results) => successFunction(results)
            );
          }

          set(waitingModalAtom, {
            state: false,
            text: "",
          });
        }
      }
    } else {
      set(errorModalAtom, {
        state: true,
        event: null,
        eventText: "",
        redirectUrl: "",
        text: "파일을 선택해주세요.",
        title: "파일 선택 없음",
      });
    }
  }
);

export const handleAssetInfoAtom = atom(null, async (get, set) => {
  const selectAsset = get(selectAssetAtom);
  const assetNameRef = get(assetNameRefAtom);

  if (selectAsset.name === "") {
    set(errorModalAtom, {
      state: true,
      event: () => {
        set(errorModalAtom, (current) => ({
          ...current,
          state: false,
        }));

        setTimeout(() => {
          if (assetNameRef && assetNameRef.current) {
            assetNameRef.current.focus();
          }
        }, 0);
      },
      eventText: "어셋 이름 입력하기",
      redirectUrl: "",
      text: "어셋 이름을 넣어주세요.",
      title: "어셋 이름 없음",
    });
  } else {
    set(waitingModalAtom, {
      state: true,
      text: "Asset 저장중",
    });

    const refreshCookie = getCookie(
      process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
    );

    const accessCookie = getCookie(
      process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
    );

    const errorFunction = () => {
      set(errorModalAtom, {
        state: true,
        event: null,
        eventText: "",
        redirectUrl: "",
        text: "다시 한번 시도해 주세요.",
        title: "Asset 저장 실패",
      });
    };

    const successFunction = (results: any) => {
      set(selectAssetAtom, results);

      set(successModalAtom, {
        state: true,
        event: null,
        eventText: "",
        redirectUrl: "",
        text: "Asset 정보가 업데이트 됐습니다.",
        title: "Asset 저장 성공",
      });
    };

    const newAccessToken = await handleApiResponse(
      refreshCookie,
      set,
      () => makeOrUpdateAsset(accessCookie ?? "", null, selectAsset),
      () => errorFunction(),
      (results) => successFunction(results)
    );

    if (newAccessToken) {
      await handleApiResponse(
        null,
        set,
        () => makeOrUpdateAsset(newAccessToken ?? "", null, selectAsset),
        () => errorFunction(),
        (results) => successFunction(results)
      );
    }

    set(waitingModalAtom, {
      state: false,
      text: "",
    });
  }
});

export const handleIsPublicAssetAtom = atom(
  null,
  (get, set, state: boolean) => {
    set(selectAssetAtom, (current) => ({
      ...current,
      is_public: state,
    }));
  }
);

import { Avatar, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useRef } from "react";
import * as S from "./styled";
import { useAtom } from "jotai";
import { userAtom } from "../../../Atoms/PublicAtom";
import {
  handleImageChangeAtom,
  onSubmitNicknameAtom,
  whenNicknameChangeAtom,
  whenPageComeAtom,
} from "../../../ViewModels/ProfileViewModel";

export function AccountManage() {
  const [user] = useAtom(userAtom);

  const [tempNickname] = useAtom(whenPageComeAtom);
  const [, whenNicknameChange] = useAtom(whenNicknameChangeAtom);
  const [, onSubmitNickname] = useAtom(onSubmitNicknameAtom);
  const [, handleImageChange] = useAtom(handleImageChangeAtom);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  return (
    <>
      <Typography fontSize={"12px"} color={"#8f8f8f"}>
        기본 설정
      </Typography>
      <Stack
        direction={"row"}
        gap={"20px"}
        sx={{
          gap: "20px",
          alignItems: "center",
          padding: "5px",
        }}
      >
        <Avatar
          src={`${process.env.REACT_APP_USER_MEDIA_ADDRESS}${user?.image}`} // 현재 프로필 이미지 표시
          onClick={() => fileInputRef.current?.click()}
          sx={{ cursor: "pointer" }}
        />
        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleImageChange}
        />

        <S.InputContainer>
          <S.CustomInputBase
            placeholder={"닉네임을 입력해주세요"}
            value={tempNickname}
            onChange={(e) => whenNicknameChange(e.target.value)}
            sx={{
              fontSize: "14px",
              "&::placeholder": { fontSize: "12px" },
            }}
          />
        </S.InputContainer>
        <S.SubmitButton onClick={onSubmitNickname}>저장</S.SubmitButton>
      </Stack>
    </>
  );
}

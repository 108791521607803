import React from "react";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  ListItem,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import {
  handleCurrentMyStateMenuOpenAtom,
  handleInviteUserAtom,
  handleLogoutItemClickAtom,
  handleNoticeWhenServiceIsInDevelopAtom,
  makeChatbotAtom,
  setStateDrawerAtom,
} from "../../../../ViewModels/HeaderViewModel";
import { userAtom } from "../../../../Atoms/PublicAtom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";
import * as S from "./styled";
import { useGetFlowDataQuery } from "../../../../Queries/DokgabiFlowQueries";
import { editMyChatbotAtom } from "../../../../ViewModels/MyChatbotListViewModel";
import { CurrentMyStateMenu } from "./CurrentMyStateMenu";
import { DokgabiFlowProps } from "../../../../Models/DokgabiFlow";

export function CustomDrawer() {
  const [drawerOpen, toggleDrawer] = useAtom(setStateDrawerAtom);
  const [user] = useAtom(userAtom);

  const [, makeChatbot] = useAtom(makeChatbotAtom);

  const [, handleLogout] = useAtom(handleLogoutItemClickAtom);

  const [, handleWaitingNotice] = useAtom(
    handleNoticeWhenServiceIsInDevelopAtom
  );

  const [, editMyChatbot] = useAtom(editMyChatbotAtom);

  const [, handleUserCheck] = useAtom(handleInviteUserAtom);

  // 나중을 위해 필요
  // const [currentMyStateOpen, setCurrentMyStateMenuOpen] = useAtom(
  //   handleCurrentMyStateMenuOpenAtom
  // );

  const { data: flowsData } = useGetFlowDataQuery(
    user!.membership === "admin" ? "admin" : user!.id
  );

  const navigate = useNavigate();

  if (flowsData === null || flowsData === undefined) return null;

  if (user === null) return null;

  return (
    <Drawer
      anchor="left"
      open={drawerOpen}
      onClick={(e) => toggleDrawer(false, e)}
    >
      <CurrentMyStateMenu />
      <S.CustomList
        onClick={(e) => toggleDrawer(false, e)}
        onKeyDown={(e) => toggleDrawer(false, e)}
      >
        <Box>
          {/* 회원정보 */}
          <ListItem>
            <Avatar
              alt="Remy Sharp"
              src={
                user?.image === ""
                  ? ""
                  : `${process.env.REACT_APP_USER_MEDIA_ADDRESS}${user?.image}`
              }
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 2,
              }}
            >
              <Typography variant="body1">{user?.nickname}</Typography>
              <Typography variant="subtitle2" color="text.secondary">
                {user?.id}
              </Typography>
              <Typography variant="subtitle2" color="text.secondary">
                {user?.membership}
              </Typography>
            </Box>
          </ListItem>
          <Divider />
          <S.MakeChatbotButton
            startIcon={<AddIcon />}
            onClick={makeChatbot}
            variant="contained"
          >
            챗봇 만들기
          </S.MakeChatbotButton>
          <S.CustomListItem onClick={() => navigate("/")}>
            <S.CustomTypo>챗봇 템플릿 보기</S.CustomTypo>
            <S.CustomListItemIcon>
              <KeyboardArrowRightIcon />
            </S.CustomListItemIcon>
          </S.CustomListItem>
          <S.CustomListItem
            onClick={() => handleUserCheck(user.id, { navigate: navigate })}
            // onClick={setCurrentMyStateMenuOpen}
            // aria-controls={
            //   currentMyStateOpen ? "current-my-state-menu" : undefined
            // }
            // aria-haspopup="true"
            // aria-expanded={currentMyStateOpen ? "true" : undefined}
          >
            <S.CustomTypo>내 챗봇 보기</S.CustomTypo>
            <S.CustomListItemIcon>
              <KeyboardArrowRightIcon />
              {/* <KeyboardArrowDownIcon /> */}
            </S.CustomListItemIcon>
          </S.CustomListItem>
          <Divider />
          <S.CustomMyChatbotTypo>내 챗봇</S.CustomMyChatbotTypo>
          <S.CustomMyChatbotListBox>
            {flowsData.length >= 1 &&
              (flowsData as DokgabiFlowProps[]).map((flow, idx) => (
                <S.MyChatbotButton
                  key={`drawer-my-chatbot-list-${idx}`}
                  onClick={() => editMyChatbot(flow.id)}
                  sx={{ wordBreak: "break-word", textAlign: "start" }}
                >
                  {flow.name}
                </S.MyChatbotButton>
              ))}
          </S.CustomMyChatbotListBox>
        </Box>

        <Box sx={{ padding: 0, margin: 0 }}>
          <Divider />
          <S.CustomListItem onClick={handleWaitingNotice}>
            <S.CustomTypo>콘텐츠 만들러가기</S.CustomTypo>
            <S.CustomListItemIcon>
              <KeyboardArrowRightIcon />
            </S.CustomListItemIcon>
          </S.CustomListItem>
          <Divider />
          <S.CustomListItem
            onClick={() =>
              (window.location.href = process.env.REACT_APP_DOKGABI_PAY_ADDRESS)
            }
          >
            <S.CustomTypo>가격표</S.CustomTypo>
            <S.CustomListItemIcon>
              <KeyboardArrowRightIcon />
            </S.CustomListItemIcon>
          </S.CustomListItem>

          <S.CustomListItem onClick={handleWaitingNotice}>
            <S.CustomTypo>튜토리얼</S.CustomTypo>
            <S.CustomListItemIcon>
              <KeyboardArrowRightIcon />
            </S.CustomListItemIcon>
          </S.CustomListItem>

          <Divider />

          <S.CustomListItem onClick={() => navigate("/profile")}>
            <S.CustomTypo>내 프로필 설정</S.CustomTypo>
            <S.CustomListItemIcon>
              <KeyboardArrowRightIcon />
            </S.CustomListItemIcon>
          </S.CustomListItem>
          <S.CustomListItem
            onClick={() =>
              (window.location.href = process.env.REACT_APP_DOKGABI_PAY_ADDRESS)
            }
          >
            <S.CustomTypo>플랜 업그레이드</S.CustomTypo>
            <S.CustomListItemIcon>
              <KeyboardArrowRightIcon />
            </S.CustomListItemIcon>
          </S.CustomListItem>
          <S.CustomListItem
            onClick={() =>
              (window.location.href = process.env.REACT_APP_DOKGABI_PAY_ADDRESS)
            }
          >
            <S.CustomTypo>플랜 연장</S.CustomTypo>
            <S.CustomListItemIcon>
              <KeyboardArrowRightIcon />
            </S.CustomListItemIcon>
          </S.CustomListItem>
          <S.CustomBackgroundListItem onClick={handleLogout}>
            <Typography sx={{ fontSize: "14px", color: "white" }}>
              로그아웃
            </Typography>
          </S.CustomBackgroundListItem>
        </Box>
      </S.CustomList>
    </Drawer>
  );
}

import React, { useEffect, useState } from "react";
import { Box, Modal, Typography, Button, TextField } from "@mui/material";
import { useAtom } from "jotai";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"; // MUI icon
import { useNavigate } from "react-router-dom";
import { errorModalAtom } from "../../../Atoms/RootAtom";
import * as S from "./styled";
import {
  flowAtom,
  flowFrontElementAtom,
  settingModalAtom,
} from "../../../Atoms/ChatDesignPublicAtom";
import { useGetMyFlowFrontElement } from "../../../Queries/DokgabiFlowQueries";
import { handleCloseAtom } from "../../../ViewModels/SettingModalViewModel";

const SettingModal: React.FC = () => {
  const [settingState, setSettingState] = useAtom(settingModalAtom);
  const [flow, setFlow] = useAtom(flowAtom);
  const [flowFrontElement, setflowFrontElement] = useAtom(flowFrontElementAtom);
  const [, handleClose] = useAtom(handleCloseAtom);

  // const handleClose = async () => {
  //   const result = await insertFrontElement(flowFrontElement ?? [], flow.id);
  //   if (result) setflowFrontElement(result);

  //   setSettingState(false);
  // };

  const navigate = useNavigate();

  const handleAddQuestion = () => {
    setflowFrontElement((current) => [
      ...(current || []),
      {
        id: -1,
        key: "추천질문",
        value: "",
        flow_id_id: flow.id,
      },
    ]);
  };

  const handleQuestionChange = (index: number, value: string) => {
    setflowFrontElement((current) =>
      current
        ? current.map((item, i) => (i === index ? { ...item, value } : item))
        : []
    );
  };

  const { data: myChatbotFrontElementList } = useGetMyFlowFrontElement(flow.id);

  useEffect(() => {
    if (myChatbotFrontElementList) {
      if (myChatbotFrontElementList.length >= 1) {
        setflowFrontElement(myChatbotFrontElementList);
      } else {
        setflowFrontElement([
          {
            id: -1,
            key: "추천질문",
            value: "",
            flow_id_id: flow.id,
          },
        ]);
      }
    }
  }, [myChatbotFrontElementList, setflowFrontElement]);

  return (
    <Modal open={settingState} onClose={handleClose}>
      <S.CustomBox>
        <Typography>설명</Typography>
        <TextField
          sx={{ width: "100%" }}
          value={flow.desc}
          onChange={(e) =>
            setFlow((current) => ({
              ...current,
              desc: e.target.value,
            }))
          }
          multiline
        />

        <Typography sx={{ marginTop: "20px" }}>추천질문 설정</Typography>
        {flowFrontElement?.map((flowFront, index) => (
          <TextField
            key={`${index}-my-chatbot-flow-front-element`}
            sx={{ width: "100%", marginBottom: "10px" }}
            multiline
            value={flowFront.value}
            onChange={(e) => handleQuestionChange(index, e.target.value)}
          />
        ))}

        <Button sx={{ marginTop: "10px" }} onClick={handleAddQuestion}>
          + 질문 추가
        </Button>
      </S.CustomBox>
    </Modal>
  );
};

export default SettingModal;

import React from "react";
import { useAtom } from "jotai";
import { handleNodeSettingWhenInFlowUpdateAtom } from "../../../../../ViewModels/ChatFlowViewModel";
import * as S from "../styled";
import { triggerTypeOptions } from "../../../../../Initialize/CustomNodeOptions";
import { TriggerNodeProps } from "../../../../../Props/CustomNodeProps";

export function TriggerNodeInFlowSetting({ data }: TriggerNodeProps) {
  const [, handleNodeUpdate] = useAtom(handleNodeSettingWhenInFlowUpdateAtom);

  if (data === null) return null;

  return (
    <>
      <S.CustomAutoComplete
        value={data.type.view_value}
        sx={{ marginBottom: "20px" }}
        options={triggerTypeOptions.map((value) => value.view_value)}
        onChange={(event, value) => {
          if (value)
            handleNodeUpdate(
              triggerTypeOptions.find((val) => val.view_value === value),
              "type",
              data.id
            );
        }}
        renderInput={(params) => (
          <S.CustomTextField
            {...params}
            label="다른 Path로 유도를 할 Trigger의 타입을 선택해 주세요."
          />
        )}
      />

      {data.type.value === "recommend" ? (
        <S.CustomTextField
          value={data.recommend_messages}
          placeholder="추천질문 리스트를 넣어주세요."
          onChange={(event) =>
            handleNodeUpdate(event.target.value, "recommend_messages", data.id)
          }
        />
      ) : data.type.value === "text" ? (
        <S.CustomTextField
          value={data.trigger_message}
          placeholder={`역질문할 내용을 넣어주세요.
예시) 저는 000 이런 것도 알려줄 수 있는데, 00, 00 같은 항목을 넣어서 질문해보는게 어떻겠어요?`}
          onChange={(event) =>
            handleNodeUpdate(event.target.value, "trigger_message", data.id)
          }
          minRows={5}
          maxRows={5}
          multiline
        />
      ) : null}
    </>
  );
}

import React from "react";
import * as S from "./styled";
import { Button, Stack, Typography } from "@mui/material";
import { useAtom } from "jotai";
import {
  finishOneStepAtom,
  handleChatbotNameAtom,
  handleOpenAiAPIKeyAtom,
  onBlurAtom,
} from "../../../ViewModels/GuideModalViewModel";

export function FirstStep() {
  const [tempChatbotName, setTempChatbotName] = useAtom(handleChatbotNameAtom);
  const [openaiAPIKey, setOpenaiAPIKey] = useAtom(handleOpenAiAPIKeyAtom);
  const [, onBlur] = useAtom(onBlurAtom);
  const [, finishOneStep] = useAtom(finishOneStepAtom);

  return (
    <Stack direction="column" gap="20px" sx={{ padding: "20px" }}>
      {/* 챗봇 이름 입력 */}
      <S.ResponsiveRow>
        <Typography className="label">챗봇이름</Typography>
        <S.InputContainer>
          <S.CustomInputBase
            placeholder={"챗봇이름을 입력해주세요"}
            value={tempChatbotName}
            onChange={(e) => setTempChatbotName(e)}
            onBlur={onBlur}
          />
        </S.InputContainer>
      </S.ResponsiveRow>

      {/* OPEN AI API Key 입력 */}
      <S.ResponsiveRow>
        <Typography className="label">OPEN AI</Typography>
        <S.CustomStack direction="column">
          <S.InputContainer>
            <S.CustomInputBase
              placeholder={"OPEN AI API 키를 입력해주세요."}
              value={openaiAPIKey ?? ""}
              onChange={(e) => setOpenaiAPIKey(e)}
              onBlur={onBlur}
            />
          </S.InputContainer>
          <S.CustomButton
            onClick={() =>
              window.open(
                "https://platform.openai.com/settings/profile/api-keys",
                "_blank"
              )
            }
          >
            API키 발급받으러 가기
          </S.CustomButton>
          <Typography marginTop={0} fontSize={"13px"} color={"#8f8f8f"}>
            저희 챗봇은 GPT를 이용하여 답변을 생성하고 있습니다."API키
            발급받으러 가기" 버튼을 눌러 발급을 받은 후 다시 챗봇을
            만들어주세요.
          </Typography>
          <Typography marginTop={0} fontSize={"13px"} color={"#8f8f8f"}>
            버튼 클릭 하여 로그인이 되어 있으시면 "+ create new secret key"를
            통해 발급 또는 로그인이 안되어 있다면 로그인 후에 "+ create new
            secret key"를 통해 발급 진행해주세요.
          </Typography>
        </S.CustomStack>
      </S.ResponsiveRow>

      <Button
        variant="contained"
        sx={{
          borderRadius: "20px",
          background: process.env.REACT_APP_MAIN_COLOR,
          "&:hover": {
            background: "#3A1A7A",
          },
        }}
        onClick={finishOneStep}
      >
        1단계 완료
      </Button>
    </Stack>
  );
}

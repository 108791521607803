import { Button, Menu, styled as muiStyled } from "@mui/material";

export const CustomMenu = muiStyled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 12, // 모서리를 둥글게 설정
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.12)", // 그림자 설정
    marginTop: theme.spacing(2),
    minWidth: 220, // 최소 너비 설정
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    "& .MuiAvatar-root": {
      width: 80,
      height: 80,
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(2),
    },
  },
  "& .MuiMenu-list": {
    padding: "8px",
  },
  "& .MuiMenuItem-root": {
    padding: "10px 16px",
    "& .MuiListItemIcon-root": {
      minWidth: 40,
    },
  },
}));

import {
  Box,
  InputBase,
  ListItem,
  ListItemIcon,
  styled,
  Typography,
} from "@mui/material";

export const SidebarContainer = styled(Box)({
  minWidth: "15%",
  boxSizing: "border-box",
  borderRight: `1px solid #ececec`,
  height: "100%",
});

interface CustomListItemProps {
  isselect: string;
}

export const CustomListItem = styled(ListItem)<CustomListItemProps>(
  ({ isselect }) => ({
    cursor: "pointer",
    padding: "16px",
    background:
      isselect === "true" ? process.env.REACT_APP_MAIN_COLOR : "white",
    color: isselect === "true" ? "white" : "black",
    "&:hover": {
      background: isselect === "true" ? "#3A1A7A" : "#ececec",
    },
  })
);

export const CustomTypo = styled(Typography)({
  fontSize: "14px",
});

export const CustomListItemIcon = styled(ListItemIcon)<CustomListItemProps>(
  ({ isselect }) => ({
    fontSize: "14px",
    color: isselect === "true" ? "white" : "black",
  })
);

export const InputContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: "#e5e8eb",
  borderRadius: "30px",
  padding: "0px 5px",
  height: "35px",
}));

export const CustomInputBase = styled(InputBase)(({ theme }) => ({
  flex: 1,
  marginLeft: "15px",
  fontSize: "0.8rem",
  backgroundColor: "transparent",
}));

export const SubmitButton = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: process.env.REACT_APP_MAIN_COLOR,
  borderRadius: "30px",
  padding: "0 15px",
  height: "35px",
  color: "white",
  fontSize: "11px",
  cursor: "pointer",
  "&:hover": {
    background: "#3A1A7A",
  },
}));

import React from "react";
import { useAtom } from "jotai";
import { handleNodeTwoDepthSettingWhenInFlowUpdateAtom } from "../../../../../ViewModels/ChatFlowViewModel";
import * as S from "../styled";
import {
  handleAddExampleAtom,
  handleAddTopicAtom,
  handleEditExamAtom,
  handleEditTopicAtom,
  handleMakeDeleteIndexExampleAtom,
  handleMakeEditIndexExampleAtom,
  handleMakeEditTopicAtom,
} from "../../../../../ViewModels/ChatFlowTopicSettingViewModel";
import { Box, IconButton, MenuItem, Stack, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { TopicClassifyNodeProps } from "../../../../../Props/CustomNodeProps";

export function TopicNodeInFlowSetting({ data }: TopicClassifyNodeProps) {
  const [, handleNodeUpdate] = useAtom(
    handleNodeTwoDepthSettingWhenInFlowUpdateAtom
  );

  const [, addExam] = useAtom(handleAddExampleAtom);
  const [exampleEditIndex, makeEditIndex] = useAtom(
    handleMakeEditIndexExampleAtom
  );
  const [, editExam] = useAtom(handleEditExamAtom);
  const [, deleteExam] = useAtom(handleMakeDeleteIndexExampleAtom);

  const [, addTopic] = useAtom(handleAddTopicAtom);
  const [editTopicIndex] = useAtom(handleMakeEditTopicAtom);
  const [, editTopic] = useAtom(handleEditTopicAtom);

  if (data === null) return null;

  return (
    <Stack sx={{ gap: "20px" }}>
      <S.CustomTextField
        placeholder="분류할 주제의 이름을 설정해주세요."
        value={data.temporary_intend?.intend_name ?? ""}
        onChange={(event) =>
          handleNodeUpdate(
            event.target.value,
            "temporary_intend",
            "intend_name",
            data.id
          )
        }
      />
      <S.CustomTextField
        placeholder="분류할 주제에 대한 설명을 적어주세요."
        value={data.temporary_intend?.intend_desc ?? ""}
        onChange={(event) =>
          handleNodeUpdate(
            event.target.value,
            "temporary_intend",
            "intend_desc",
            data.id
          )
        }
        minRows={3}
        maxRows={10}
        multiline
      />
      <Stack direction={"row"} sx={{ gap: "20px", alignItems: "end" }}>
        <S.CustomTextField
          sx={{ flex: 4 }}
          placeholder={`분류할 주제에 대한 질문 예시들을 넣어주세요.
예시) 수학 공부 하고 싶은데 어떻게 공부를 시작해야 될지 모르겠어요.`}
          value={data.temporary_intend?.intend_exam ?? ""}
          onChange={(event) =>
            handleNodeUpdate(
              event.target.value,
              "temporary_intend",
              "intend_exam",
              data.id
            )
          }
          onKeyDown={(event) => {
            if (event.key === "Enter" && !event.shiftKey) {
              event.preventDefault();
              addExam(data.id);
            }
          }}
          minRows={1}
          maxRows={5}
          multiline
        />
        <S.CustomButton
          sx={{ flex: 1, height: "50px" }}
          onClick={
            exampleEditIndex !== null && exampleEditIndex.id === data.id
              ? () => editExam(data.id)
              : () => addExam(data.id)
          }
        >
          {exampleEditIndex !== null && exampleEditIndex.id === data.id
            ? "예제 수정"
            : "예제 추가"}
        </S.CustomButton>
      </Stack>

      {data.temporary_intend?.intend_exam_list &&
        data.temporary_intend?.intend_exam_list.map((exam, index) => (
          <Stack
            key={`topic-exam-list-${index}`}
            direction={"row"}
            sx={{
              padding: "10px",
              display: "flex",
              alignItems: "start",
              justifyContent: "space-between",
              borderBottom: "1px solid #ececec",
            }}
          >
            <Typography sx={{ flex: 4 }}>{exam}</Typography>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "end",
                height: "40px",
              }}
            >
              <IconButton onClick={() => makeEditIndex(index, data.id)}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => deleteExam(index, data.id)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          </Stack>
        ))}

      <S.CustomButton
        onClick={
          editTopicIndex !== null && editTopicIndex.id === data.id
            ? () => editTopic(data.id)
            : () => addTopic(data.id)
        }
      >
        {editTopicIndex !== null && editTopicIndex.id === data.id
          ? "토픽 수정"
          : "토픽 추가"}
      </S.CustomButton>
    </Stack>
  );
}

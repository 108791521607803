import React from "react";
import { useAtom } from "jotai";
import { handleNodeSettingUpdateAtom } from "../../../../../ViewModels/ChatFlowViewModel";
import * as S from "../styled";
import { sortedModelOptions } from "../../../../../Initialize/CustomNodeOptions";
import { BasicLLMNodeDataProps } from "../../../../../Props/CustomNodeProps";

export function BasicLLMNodeSetting() {
  const [currentEditingNode, handleNodeUpdate] = useAtom(
    handleNodeSettingUpdateAtom
  );

  if (currentEditingNode === null) return null;

  return (
    <>
      <S.CustomAutoComplete
        value={
          (currentEditingNode.data as unknown as BasicLLMNodeDataProps)
            .model_name.view_value
        }
        options={sortedModelOptions.map((value) => value.view_value)}
        onChange={(event, value) => {
          if (value)
            handleNodeUpdate(
              sortedModelOptions.find((val) => val.view_value === value),
              "model_name"
            );
        }}
        renderInput={(params) => (
          <S.CustomTextField
            {...params}
            label="사용할 AI 모델을 선택해주세요."
          />
        )}
      />
      <S.CustomTextField
        value={
          (currentEditingNode.data as unknown as BasicLLMNodeDataProps).api_key
        }
        placeholder="Open AI API-KEY 입력"
        onChange={(event) => handleNodeUpdate(event.target.value, "api_key")}
      />

      <S.CustomTextField
        value={
          (currentEditingNode.data as unknown as BasicLLMNodeDataProps).prompt
        }
        placeholder={`프롬프트 입력
예시) 사용자 입력에 답변해주고, 감사합니다 를 붙여서 출력해줘.`}
        onChange={(event) => handleNodeUpdate(event.target.value, "prompt")}
        minRows={8}
        maxRows={22}
        multiline
      />
    </>
  );
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dokgabi-character-image-item:hover {
  .dokgabi-character-image-hover {
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.dokgabi-character-image-hover {
  display: none;
}

.Mui-selected {
  color: #5340ff;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/ChatSkin.css"],"names":[],"mappings":"AAAA;EACE;IACE,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;AACF;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".dokgabi-character-image-item:hover {\r\n  .dokgabi-character-image-hover {\r\n    background: rgba(255, 255, 255, 0.7);\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n  }\r\n}\r\n\r\n.dokgabi-character-image-hover {\r\n  display: none;\r\n}\r\n\r\n.Mui-selected {\r\n  color: #5340ff;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

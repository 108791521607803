import React from "react";
import { useGetTemplateFlowDataQuery } from "../Queries/DokgabiFlowQueries";
import { Box, CardHeader, CardMedia, Grid } from "@mui/material";
import { formatDateToDay } from "../Utils/DatetimeUtil";
import * as S from "../Components/Template/styled";
import { useAtom } from "jotai";
import { takeToMyChatbotAtom } from "../ViewModels/TemplateViewModel";
import { DokgabiFlowProps } from "../Models/DokgabiFlow";

export function Template() {
  const [, takeToMyChatbot] = useAtom(takeToMyChatbotAtom);
  const { data: flowsData } = useGetTemplateFlowDataQuery();

  if (flowsData === null || flowsData === undefined) return null;

  return (
    <Box sx={{ flexGrow: 1 }} padding={5}>
      <Grid
        container
        spacing={{ xs: 5, md: 5 }}
        columns={{ xs: 4, sm: 8, md: 12, lg: 20 }}
      >
        {flowsData.length >= 1 &&
          (flowsData as DokgabiFlowProps[]).map((flow, index) => (
            <Grid
              item
              key={`tempalte-chatbot-list-${index}`}
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              sx={{
                cursor: "pointer",
              }}
              onClick={() => takeToMyChatbot(flow.id)}
            >
              <S.CustomCard>
                <CardHeader
                  avatar={
                    <S.CustomAvatar
                      src={`${process.env.REACT_APP_DOKGABI_DASHBOARD_ADDRESS}/ieum_logo.png`}
                    ></S.CustomAvatar>
                  }
                  title={flow.name}
                  subheader={
                    flow.update_date === null
                      ? `최근 ${formatDateToDay(new Date(flow.init_date))} 생성`
                      : `최근 ${formatDateToDay(
                          new Date(flow.update_date)
                        )} 수정`
                  }
                />
                <S.CustomGrid
                  container
                  alignItems="center"
                  justifyContent="center"
                >
                  <CardMedia
                    component="img"
                    sx={{
                      objectFit: "cover",
                      border: "none",
                      borderRadius: "50%",
                    }}
                    image={
                      flow.character_image !== null
                        ? `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${flow.character_image.file_path}`
                        : `/no-thumbnail.png`
                    }
                    alt="chatbot icon"
                  />
                  {/* <S.TestButton
                  variant="contained"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  챗봇 테스트
                </S.TestButton> */}
                </S.CustomGrid>
              </S.CustomCard>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}

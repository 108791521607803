import { atom } from "jotai";
import { defaultDokgabiFlowProps } from "../Initialize/ChatFlowInitialize";
import {
  DokgabiFlowProps,
  DokgabiFrontElementProps,
} from "../Models/DokgabiFlow";
import { encryptedAtomWithStorage } from "./EncryptedAtomWithStorage";

export const flowAtom = encryptedAtomWithStorage<DokgabiFlowProps>(
  "flowAtom",
  defaultDokgabiFlowProps
);

export const chatbotDesignHeaderMakingAnchorElAtom = atom<null | HTMLElement>(
  null
);

export const pageListAtom = atom<string[]>([
  "asset",
  "flow",
  "skin",
  "test",
  "build",
]);

export const judgeRightRouteAtom = encryptedAtomWithStorage<boolean>(
  "judgeRightRouteAtom",
  false
);

export const currentMakerAtom = encryptedAtomWithStorage<string | null>(
  "currentMakerAtom",
  null
);

export const settingModalAtom = atom(false);

export const flowFrontElementAtom = encryptedAtomWithStorage<
  DokgabiFrontElementProps[] | null
>("flowFrontElementAtom", null);

export const errorIdListAtom = encryptedAtomWithStorage<string[]>(
  "errorIdListAtom",
  []
);

import { atom } from "jotai";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PaymentIcon from "@mui/icons-material/Payment";
import { SvgIconComponent } from "@mui/icons-material"; // SvgIconComponent 타입 사용

interface SidebarItem {
  label: string;
  icon: SvgIconComponent; // SvgIconComponent 타입으로 지정
}

export const profileSidebarAtom = atom<SidebarItem[]>([
  { label: "계정 관리", icon: AccountCircleIcon },
  { label: "사용량 관리", icon: PaymentIcon },
]);

export const selectSidebarAtom = atom("계정 관리");

export const isAccordionOpenAtom = atom(false);

export const tempNicknameFieldAtom = atom("");

import React from "react";
import { useAtom } from "jotai";
import {
  handleAddFlowAssetAtom,
  handleNodeSettingUpdateAtom,
} from "../../../../../ViewModels/ChatFlowViewModel";
import * as S from "../styled";
import {
  chartTypeOptions,
  dataNumOptions,
  jsonTypeOptions,
  sortedModelOptions,
  sortedoutputTypeOptions,
} from "../../../../../Initialize/CustomNodeOptions";
import { MakeJsonNodeDataProps } from "../../../../../Props/CustomNodeProps";
import { useGetMyAssetQuery } from "../../../../../Queries/DokgabiAssetQueries";
import { DokgabiAssetProps } from "../../../../../Models/DokgabiFlow";
import { currentMakerAtom } from "../../../../../Atoms/ChatDesignPublicAtom";

export function MakeJsonNodeSetting() {
  const [user] = useAtom(currentMakerAtom);
  const [currentEditingNode, handleNodeUpdate] = useAtom(
    handleNodeSettingUpdateAtom
  );

  const [, addFlowAsset] = useAtom(handleAddFlowAssetAtom);

  const { data: knwoledgeBaseData } = useGetMyAssetQuery(user ?? "");

  if (currentEditingNode === null) return null;

  if (knwoledgeBaseData === null || knwoledgeBaseData === undefined)
    return null;

  return (
    <>
      {/* <S.CustomAutoComplete
        value={
          (currentEditingNode.data as unknown as RagBaseLLMDataProps)
            .output_type.view_value
        }
        options={sortedoutputTypeOptions.map((value) => value.view_value)}
        onChange={(event, value) => {
          if (value)
            handleNodeUpdate(
              sortedoutputTypeOptions.find((val) => val.view_value === value),
              "output_type"
            );
        }}
        renderInput={(params) => (
          <S.CustomTextField
            {...params}
            label="Asset을 선택해주시면 해당 Asset의 타입을 알려드립니다."
          />
        )}
        disabled={true}
      /> */}
      <S.CustomAutoComplete
        value={
          (currentEditingNode.data as unknown as MakeJsonNodeDataProps)
            .grounding_data === ""
            ? ""
            : (
                (currentEditingNode.data as unknown as MakeJsonNodeDataProps)
                  .grounding_data as unknown as DokgabiAssetProps
              ).name
        }
        options={(knwoledgeBaseData as DokgabiAssetProps[]).map(
          (value) => value.name
        )}
        onChange={(event, value) => {
          if (value) {
            const findAsset = (knwoledgeBaseData as DokgabiAssetProps[]).find(
              (val) => val.name === value
            );

            if (findAsset) {
              handleNodeUpdate(findAsset, "grounding_data");

              addFlowAsset(findAsset);

              handleNodeUpdate(
                sortedoutputTypeOptions.find(
                  (val) => val.value === findAsset?.output_type
                ),
                "output_type"
              );
            }
          }
        }}
        renderInput={(params) => (
          <S.CustomTextField {...params} label="사용할 Asset을 선택해주세요." />
        )}
      />
      <S.CustomAutoComplete
        value={
          (currentEditingNode.data as unknown as MakeJsonNodeDataProps).data_num
        }
        options={dataNumOptions.map((value) => value)}
        onChange={(event, value) => {
          if (value)
            handleNodeUpdate(
              dataNumOptions.find((val) => val === value),
              "data_num"
            );
        }}
        renderInput={(params) => (
          <S.CustomTextField
            {...params}
            label="가져올 데이터 개수를 선택해주세요."
          />
        )}
      />
      <S.CustomAutoComplete
        value={
          (currentEditingNode.data as unknown as MakeJsonNodeDataProps)
            .model_name.view_value
        }
        options={sortedModelOptions.map((value) => value.view_value)}
        onChange={(event, value) => {
          if (value)
            handleNodeUpdate(
              sortedModelOptions.find((val) => val.view_value === value),
              "model_name"
            );
        }}
        renderInput={(params) => (
          <S.CustomTextField
            {...params}
            label="사용할 AI 모델을 선택해주세요."
          />
        )}
      />
      <S.CustomTextField
        value={
          (currentEditingNode.data as unknown as MakeJsonNodeDataProps).api_key
        }
        placeholder="Open AI API-KEY 입력"
        onChange={(event) => handleNodeUpdate(event.target.value, "api_key")}
      />

      <S.CustomAutoComplete
        value={
          (currentEditingNode.data as unknown as MakeJsonNodeDataProps)
            .json_type.view_value ?? ""
        }
        options={jsonTypeOptions.map((value) => value.view_value)}
        onChange={(event, value) => {
          if (value)
            handleNodeUpdate(
              jsonTypeOptions.find((val) => val.view_value === value),
              "json_type"
            );
        }}
        renderInput={(params) => (
          <S.CustomTextField
            {...params}
            label="상호작용할 타입을 골라주세요."
          />
        )}
      />

      {(currentEditingNode.data as unknown as MakeJsonNodeDataProps).json_type
        .value == "chart" && (
        <>
          <S.CustomAutoComplete
            value={
              (currentEditingNode.data as unknown as MakeJsonNodeDataProps)
                .chart_type.view_value ?? ""
            }
            options={chartTypeOptions.map((value) => value.view_value)}
            onChange={(event, value) => {
              if (value)
                handleNodeUpdate(
                  chartTypeOptions.find((val) => val.view_value === value),
                  "chart_type"
                );
            }}
            renderInput={(params) => (
              <S.CustomTextField {...params} label="차트 타입을 골라주세요." />
            )}
          />
          <S.CustomTextField
            value={
              (currentEditingNode.data as unknown as MakeJsonNodeDataProps)
                .chart_description
            }
            placeholder="만들고 싶은 차트의 설명을 적어주세요.
            예시) 브랜드별로 상품을 가져와서 그것의 평균 가격을 막대차트 형태로 보여주고 싶어.
            "
            onChange={(event) =>
              handleNodeUpdate(event.target.value, "chart_description")
            }
            minRows={3}
            maxRows={7}
            multiline
          />
        </>
      )}

      {(currentEditingNode.data as unknown as MakeJsonNodeDataProps).json_type
        .value == "etc" && (
        <S.CustomTextField
          value={
            (currentEditingNode.data as unknown as MakeJsonNodeDataProps)
              .etc_json_data
          }
          placeholder="json 형식으로 입력해주세요.
            에시) {{'job': <job name> , 'desc' : <job description> }}
            "
          onChange={(event) =>
            handleNodeUpdate(event.target.value, "etc_json_data")
          }
          minRows={3}
          maxRows={7}
          multiline
        />
      )}
    </>
  );
}

import {
  AccordionSummary,
  Box,
  Chip,
  MenuItem,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";

export const FullStack = styled(Stack)({
  width: "100%",
  height: "100%",
  maxHeight: "100%",
  boxSizing: "border-box",
});

export const LeftSideBar = styled(Box)({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  height: "100%",
  boxSizing: "border-box",
  padding: "20px",
  borderRight: "1px solid #ececec",
});

export const CenterSideBar = styled(Box)({
  flex: 3,
  height: "100%",
  padding: "10px",
  boxSizing: "border-box",
  borderRight: "1px solid #ececec",
  position: "relative",
});

export const RightSideBar = styled(Box)({
  flex: 1,
  height: "100%",
  maxHeight: "100%",
  padding: "10px 0",
  boxSizing: "border-box",
  borderRight: "1px solid #ececec",
});

export const OuterBox = styled(Box)({
  width: "100%",
  height: "100%",
  padding: "100px",
  boxSizing: "border-box",
});

export const FileUploadBox = styled(Box)({
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: "100%",
  height: "100%",
  boxSizing: "border-box",
  background: "rgba(0, 0, 0, 0.7)", // 배경에만 투명도 적용
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 10,
});

export const FileUploadTypo = styled(Typography)({
  color: "white",
  opacity: 1,
});

import React, { useEffect } from "react";
import * as S from "../Components/ChatMakeAsset/styled";
import { useAtom } from "jotai";
import {
  currentAssetViewFileAtom,
  isDraggingAtom,
  tabValueAtom,
} from "../Atoms/ChatMakeAssetAtom";
import {
  handleAssetInfoAtom,
  handleDragLeaveAtom,
  handleDragOverAtom,
  handleDropAtom,
  handleFileChangeAtom,
  initializeMakeAssestAtom,
} from "../ViewModels/ChatMakeAssetViewModel";
import { AssetLibrary } from "../Components/ChatMakeAsset/AssetLibrary";
import { AssetFile } from "../Components/ChatMakeAsset/AssetFile";
import { InnerLeftBar } from "../Components/ChatMakeAsset/LeftBar";
import { NoFileUpload } from "../Components/ChatMakeAsset/NoFileUpload";
import { selectAssetAtom } from "../Atoms/ChatAssetAtom";

export function ChatMakeAsset() {
  const [selectAsset] = useAtom(selectAssetAtom);
  const [tabValue] = useAtom(tabValueAtom);
  const [currentAssetViewFile] = useAtom(currentAssetViewFileAtom);
  const [, initializeMakeAssest] = useAtom(initializeMakeAssestAtom);

  const [, handleFileChange] = useAtom(handleFileChangeAtom);

  const [isDragging] = useAtom(isDraggingAtom);
  const [, handleDragOver] = useAtom(handleDragOverAtom);
  const [, handleDragLeave] = useAtom(handleDragLeaveAtom);
  const [, handleDrop] = useAtom(handleDropAtom);

  useEffect(() => {
    return () => {
      initializeMakeAssest();
    };
  }, []);

  return (
    <S.FullStack direction={"row"}>
      <S.LeftSideBar>
        <InnerLeftBar />
      </S.LeftSideBar>
      <S.CenterSideBar
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {isDragging && (
          <S.FileUploadBox>
            <S.FileUploadTypo>파일을 올려주세요.</S.FileUploadTypo>
            <S.FileUploadTypo>
              파일 형식은 txt, pdf, csv, xlsx를 지원하고 있습니다.
            </S.FileUploadTypo>
          </S.FileUploadBox>
        )}

        {selectAsset.id === -1 ? (
          <S.OuterBox>
            <NoFileUpload />
          </S.OuterBox>
        ) : tabValue === 0 ? (
          <AssetLibrary />
        ) : (
          <AssetFile />
        )}
        <input
          type="file"
          hidden
          onChange={handleFileChange} // 파일 선택 처리
        />
      </S.CenterSideBar>
      {/* <S.RightSideBar>설정</S.RightSideBar> */}
    </S.FullStack>
  );
}

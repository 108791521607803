import React from "react";
import { useAtom } from "jotai";
import {
  handleAddFlowAssetAtom,
  handleNodeSettingWhenInFlowUpdateAtom,
} from "../../../../../ViewModels/ChatFlowViewModel";
import * as S from "../styled";
import {
  sortedModelOptions,
  sortedoutputTypeOptions,
} from "../../../../../Initialize/CustomNodeOptions";
import { RAGbaseLLMNodeProps } from "../../../../../Props/CustomNodeProps";
import { useGetMyAssetQuery } from "../../../../../Queries/DokgabiAssetQueries";
import { DokgabiAssetProps } from "../../../../../Models/DokgabiFlow";
import { currentMakerAtom } from "../../../../../Atoms/ChatDesignPublicAtom";

export function RagLLMNodeInFlowSetting({ data }: RAGbaseLLMNodeProps) {
  const [user] = useAtom(currentMakerAtom);
  const [, handleNodeUpdate] = useAtom(handleNodeSettingWhenInFlowUpdateAtom);

  const [, addFlowAsset] = useAtom(handleAddFlowAssetAtom);

  const { data: knwoledgeBaseData } = useGetMyAssetQuery(user ?? "");

  if (data === null) return null;

  if (knwoledgeBaseData === null || knwoledgeBaseData === undefined)
    return null;

  return (
    <>
      <S.StyledFormControlLabel
        control={
          <S.StyledSwitch
            checked={data.is_using_generative_ai}
            onChange={(event) =>
              handleNodeUpdate(
                event.target.checked,
                "is_using_generative_ai",
                data.id
              )
            }
          />
        }
        label="Asset Library 적용"
      />
      <S.CustomAutoComplete
        value={data.output_type.view_value}
        options={sortedoutputTypeOptions.map((value) => value.view_value)}
        onChange={(event, value) => {
          if (value)
            handleNodeUpdate(
              sortedoutputTypeOptions.find((val) => val.view_value === value),
              "output_type",
              data.id
            );
        }}
        renderInput={(params) => (
          <S.CustomTextField
            {...params}
            label="Asset을 선택해주시면 해당 Asset의 타입을 알려드립니다."
          />
        )}
        disabled={true}
      />
      <S.CustomAutoComplete
        value={
          data.grounding_data === ""
            ? ""
            : (data.grounding_data as unknown as DokgabiAssetProps).name
        }
        options={(knwoledgeBaseData as DokgabiAssetProps[]).map(
          (value) => value.name
        )}
        onChange={(event, value) => {
          if (value) {
            const findAsset = (knwoledgeBaseData as DokgabiAssetProps[]).find(
              (val) => val.name === value
            );

            if (findAsset) {
              handleNodeUpdate(findAsset, "grounding_data", data.id);

              addFlowAsset(findAsset);

              handleNodeUpdate(
                sortedoutputTypeOptions.find(
                  (val) => val.value === findAsset?.output_type
                ),
                "output_type",
                data.id
              );
            }
          }
        }}
        renderInput={(params) => (
          <S.CustomTextField {...params} label="사용할 Asset을 선택해주세요." />
        )}
      />
      <S.CustomAutoComplete
        value={data.model_name.view_value}
        options={sortedModelOptions.map((value) => value.view_value)}
        onChange={(event, value) => {
          if (value)
            handleNodeUpdate(
              sortedModelOptions.find((val) => val.view_value === value),
              "model_name",
              data.id
            );
        }}
        renderInput={(params) => (
          <S.CustomTextField
            {...params}
            label="사용할 AI 모델을 선택해주세요."
          />
        )}
      />
      <S.CustomTextField
        value={data.api_key}
        placeholder="Open AI API-KEY 입력"
        onChange={(event) =>
          handleNodeUpdate(event.target.value, "api_key", data.id)
        }
      />

      <S.CustomTextField
        value={data.prompt}
        placeholder={`프롬프트 입력
예시) 사용자 입력으로 인해 추출된 Asset을 표형태로 보여줘.`}
        onChange={(event) =>
          handleNodeUpdate(event.target.value, "prompt", data.id)
        }
        minRows={5}
        maxRows={5}
        multiline
      />
    </>
  );
}

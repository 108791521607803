import React, { useEffect, useRef } from "react";
import * as S from "./styled";
import { useAtom } from "jotai";
import { selectAssetAtom } from "../../../Atoms/ChatAssetAtom";
import {
  assetNameRefAtom,
  keywordInputValueAtom,
  tabValueAtom,
} from "../../../Atoms/ChatMakeAssetAtom";
import {
  delteFileFromVectorAtom,
  handleAssetInfoAtom,
  handleAssetLibraryAtom,
  handleChipValueKeyDownAtom,
  handleDeleteKeywordAtom,
  handleFileChangeAtom,
  handleFileSelectAtom,
  handleIsPublicAssetAtom,
} from "../../../ViewModels/ChatMakeAssetViewModel";
import {
  Accordion,
  AccordionDetails,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import { Add, Close, Delete, ExpandMore, Save } from "@mui/icons-material";

export function InnerLeftBar() {
  const [selectAsset, setSelectAsset] = useAtom(selectAssetAtom);
  const [tabValue, setTabValue] = useAtom(tabValueAtom);
  const [keywordInputValue, setKeywordInputValue] = useAtom(
    keywordInputValueAtom
  );
  const [currentFile, handleFileSelect] = useAtom(handleFileSelectAtom);
  const [, handleKeyDown] = useAtom(handleChipValueKeyDownAtom);
  const [, handleDeleteKeyword] = useAtom(handleDeleteKeywordAtom);
  const [, handleAssetLibrary] = useAtom(handleAssetLibraryAtom);
  const [, handleFileChange] = useAtom(handleFileChangeAtom);
  const [, deleteFile] = useAtom(delteFileFromVectorAtom);

  const [assetNameRef] = useAtom(assetNameRefAtom);
  const [, handleAssetInfo] = useAtom(handleAssetInfoAtom);

  const [, handleIsPublic] = useAtom(handleIsPublicAssetAtom);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // 파일 선택창 열기
    }
  };

  return (
    <>
      <S.StyledFormControlLabel
        control={
          <S.StyledSwitch
            checked={selectAsset.is_public}
            onChange={(event) => handleIsPublic(event.target.checked)}
          />
        }
        label="어셋 공개 여부"
      />
      <S.FirstTitleTypography>Asset 이름</S.FirstTitleTypography>
      <S.CustomTextField
        value={selectAsset ? selectAsset?.name ?? "" : ""}
        placeholder="Asset명을 입력해주세요."
        inputRef={assetNameRef}
        onChange={(event) =>
          setSelectAsset((current) => ({
            ...current,
            name: event.target.value,
          }))
        }
      />
      <S.TitleTypography>Asset 설명</S.TitleTypography>
      <S.CustomTextField
        value={selectAsset ? selectAsset?.desc ?? "" : ""}
        placeholder="Asset의 설명을 입력해주세요."
        onChange={(event) =>
          setSelectAsset((current) => ({
            ...current,
            desc: event.target.value,
          }))
        }
        minRows={3}
        maxRows={3}
        multiline
      />
      <S.TitleTypography>Asset 키워드</S.TitleTypography>
      <S.CustomTextField
        value={keywordInputValue}
        placeholder="Asset의 키워드를 입력해주세요."
        onChange={(event) => setKeywordInputValue(event.target.value)}
        onKeyDown={handleKeyDown}
      />
      <S.ChiBox>
        {selectAsset &&
          selectAsset.keywords &&
          selectAsset.keywords.map((keyword, index) => (
            <S.KeywordChip
              key={`${index}-keyword-asset-list`}
              label={keyword}
              onDelete={() => handleDeleteKeyword(keyword)}
              deleteIcon={
                <IconButton sx={{ padding: 0 }}>
                  <Close sx={{ color: "white" }} />
                </IconButton>
              }
            />
          ))}
      </S.ChiBox>

      <ListItem sx={{ padding: "20px 10px" }}>
        <Button
          sx={{
            width: "100%",
            background: process.env.REACT_APP_MAIN_COLOR,
            "&:hover": {
              background: process.env.REACT_APP_MAIN_COLOR,
            },
          }}
          variant="contained"
          onClick={handleAssetInfo}
        >
          <Save />
          어셋 정보 저장
        </Button>
      </ListItem>

      <Divider />

      <ListItem sx={{ padding: "20px 10px" }}>
        <Button
          sx={{
            width: "100%",
            background: process.env.REACT_APP_MAIN_COLOR,
            "&:hover": {
              background: process.env.REACT_APP_MAIN_COLOR,
            },
          }}
          variant="contained"
          onClick={handleButtonClick} // 버튼 클릭 시 파일 선택창 열기
        >
          <Add />
          어셋에 내 데이터 추가
        </Button>
      </ListItem>

      <input
        type="file"
        ref={fileInputRef}
        hidden
        onChange={handleFileChange} // 파일 선택 처리
      />

      <ListItem
        onClick={handleAssetLibrary}
        sx={{
          padding: "20px 10px",
          background:
            tabValue === 0 ? process.env.REACT_APP_MAIN_COLOR : "transparent",
          color: tabValue === 0 ? "white" : "black",
          boxSizing: "border-box",
          cursor: "pointer",
        }}
      >
        Asset Library
      </ListItem>

      <S.ScrollableBox>
        {/* Accordion */}
        <Accordion disableGutters sx={{ boxShadow: "none" }}>
          <S.CustomAccordionSummary
            tabvalue={`${tabValue}`}
            expandIcon={
              <ExpandMore
                sx={{
                  color: tabValue === 1 ? "white" : "black",
                }}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setTabValue(1)}
          >
            <Typography>사용된 데이터</Typography>
          </S.CustomAccordionSummary>

          {/* 펼쳐지는 ListItems */}
          <AccordionDetails>
            <List>
              {selectAsset &&
                selectAsset.before_vector_file_path.map((file, index) => (
                  <ListItem
                    sx={{
                      display: "block",
                      cursor: "pointer",
                    }}
                    key={`${index}-asset-using-file`}
                    onClick={() => handleFileSelect(file)}
                  >
                    <Stack
                      direction="row"
                      sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "10px",
                        boxSizing: "border-box",
                      }}
                    >
                      <Stack direction="column">
                        <Typography
                          sx={{
                            fontSize: "18px",
                            marginBottom: "5px",
                            color:
                              currentFile === file
                                ? process.env.REACT_APP_MAIN_COLOR
                                : "black",
                          }}
                        >
                          {file.file_view_name}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color:
                              currentFile === file
                                ? process.env.REACT_APP_MAIN_COLOR
                                : "#8f8f8f",
                          }}
                        >
                          {file.file_type}
                        </Typography>
                      </Stack>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteFile(selectAsset, file, index);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Stack>
                    <Divider />
                  </ListItem>
                ))}
            </List>
          </AccordionDetails>
        </Accordion>
      </S.ScrollableBox>
    </>
  );
}

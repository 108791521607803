import { atom } from "jotai";
import { isResizingAtom, settingWidthAtom } from "../Atoms/SettingBarAtom";

export const setStateIsResizingAtom = atom(
  (get) => get(isResizingAtom),
  (get, set, state: boolean) => set(isResizingAtom, state)
);

export const resizeAtom = atom(
  (get) => get(settingWidthAtom),
  (get, set, e: MouseEvent) => {
    const isResizing = get(isResizingAtom);

    if (isResizing) {
      const newWidth = window.innerWidth - e.clientX;
      set(settingWidthAtom, newWidth > 500 ? newWidth : 500);
    }
  }
);

import { Box, styled as muiStyled } from "@mui/material";
import styled from "styled-components";

interface SettingBarFullContainerProps {
  currenteditingnode: string | null;
  width: string;
}

export const SettingBarFullContainer = styled.div<SettingBarFullContainerProps>(
  ({ currenteditingnode, width }) => ({
    width: `${width}px`,
    maxWidth: "80%",
    position: "fixed",
    right: 0,
    top: "56px", // 상단에 고정된 위치 설정
    height: "calc(100vh - 56px)",
    zIndex: 100,
    display: currenteditingnode == "true" ? "block" : "none",
  })
);

export const SettingBarResizeContainer = styled.div({
  width: "5px",
  cursor: "ew-resize",
  position: "absolute",
  left: 0,
  top: 0,
  bottom: 0,
  backgroundColor: "#ececec",
  zIndex: 101,
});

interface SettingBarContainerProps {
  isopen: string;
}

export const SettingBarContainer = muiStyled(Box)<SettingBarContainerProps>(
  ({ isopen }) => ({
    height: "100%", // 부모 div의 높이를 차지하도록 변경
    background: "white",
    padding: "20px",
    borderRight: "1px solid #ececec",
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    flexDirection: "column",
    overflow: "hidden",
    transition: "opacity 0.5s ease, visibility 0.5s ease",
    opacity: isopen === "true" ? 1 : 0,
    visibility: isopen === "true" ? "visible" : "hidden",
  })
);

export const SettingBarInnerContainer = muiStyled(Box)({
  padding: "20px 0",
  width: "100%",
  gap: "20px",
  display: "flex",
  flexDirection: "column",
  overflowY: "auto",
});

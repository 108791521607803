import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useAtom } from "jotai";
import React, { useState } from "react";
import { FiCopy } from "react-icons/fi";
import { flowAtom } from "../Atoms/ChatDesignPublicAtom";

export function ChatBuild() {
  const [flow] = useAtom(flowAtom);

  return (
    <Box
      sx={{
        flexGrow: 1,
        height: "100%",
        background: "#ececec",
        boxSizing: "border-box",
        display: "flex",
        padding: "20px",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "60px",
          boxSizing: "border-box",
          padding: "0 20px",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            padding: "20px",
            boxSizing: "border-box",
            borderRadius: "5px",
            background: process.env.REACT_APP_MAIN_COLOR,
          }}
        >
          <Typography sx={{ color: "white" }}>
            내사이트에 챗봇 배포하는 방법
          </Typography>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          boxSizing: "border-box",
          padding: "20px",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            padding: "20px",
            boxSizing: "border-box",
            borderRadius: "5px",
            background: "white",
          }}
        >
          <Typography sx={{ color: process.env.REACT_APP_MAIN_COLOR }}>
            API 방식으로 배포받는 방법
          </Typography>
          <hr style={{ width: "100%", border: "1px solid #ececec" }}></hr>
          <Typography>
            챗봇 flow로 통해 만든 커스텀화된 프로세스를 사용해서 자신만의 챗봇
            로직을 자신의 사이트에 적용하실 수 있습니다.
          </Typography>
          <Typography>
            아래 API URL과 REQUEST BODY 부분을 참조하여 주세요.
          </Typography>
          <div style={{ height: "50px" }}></div>
          <Typography color={"#8f8f8f"}>API URL</Typography>
          <div style={{ height: "10px" }}></div>
          <CodeBlock
            code={
              process.env.REACT_APP_DOKGABI_API_ADDRESS + "/makeChatbotResponse"
            }
          />

          <div style={{ height: "50px" }}></div>
          <Typography color={"#8f8f8f"}>REQUEST BODY</Typography>
          <div style={{ height: "10px" }}></div>
          <CodeBlock
            code={`{
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      flow: ${flow.chatbot_socket_parameter},
      input_message: "사용자 메시지",
      user_id: "사용자 유저 아이디", 사용자 유저 아이디는 하나만 존재하는 값이여야 합니다.
    }),
  }`}
          />
        </div>
      </div>
    </Box>
  );
}

const CodeBlock = ({ code }: { code: string }) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyCodeToClipboard = () => {
    navigator.clipboard.writeText(code);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1500); // Hide "copied" tooltip after 1.5 seconds
  };

  return (
    <div
      className="code-block"
      style={{
        position: "relative",
        background: "#ececec",
        borderRadius: "5px",
        boxSizing: "border-box",
        padding: "20px",
      }}
    >
      <pre style={{ whiteSpace: "pre-wrap" }}>{code}</pre>
      <div
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        {isCopied && (
          <Box
            sx={{
              background: process.env.REACT_APP_MAIN_COLOR,
              color: "white",
              padding: "5px 20px",
              borderRadius: "20px",
              height: "100%",
            }}
          >
            <Typography sx={{ fontSize: "12px" }}>복사완료</Typography>
          </Box>
        )}
        <Tooltip title="코드 복사">
          <IconButton onClick={copyCodeToClipboard}>
            <FiCopy />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

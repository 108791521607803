import {
  Box,
  Button,
  ImageList,
  ImageListItem,
  Typography,
} from "@mui/material";
import React from "react";
import * as S from "../Components/ChatSkin/styled";
import { useAtom } from "jotai";
import {
  useGetCharacterDataQuery,
  useGetLeftCharMakeNum,
} from "../Queries/DokgabiCharacterQueries";
import "./ChatSkin.css";
import { currentMakerAtom, flowAtom } from "../Atoms/ChatDesignPublicAtom";
import { ImageMakeInput } from "../Components/ChatSkin/ImageMakeInput";
import { isMakingAtom } from "../Atoms/ChatSkinAtom";
import Lottie from "react-lottie";
import LottieData from "./Animation/Animation - 1723387593175.json";
import { onSaveImageAtom } from "../ViewModels/ChatSkinViewModel";
import { DokgabiCharacterProps } from "../Models/DokgabiFlow";

export function ChatSkin() {
  const [currentMaker] = useAtom(currentMakerAtom);
  const [flow, setFlow] = useAtom(flowAtom);
  const [isMaking] = useAtom(isMakingAtom);
  const [, onSaveImage] = useAtom(onSaveImageAtom);

  const { data: itemData } = useGetCharacterDataQuery(currentMaker ?? "");
  const { data: leftNum } = useGetLeftCharMakeNum(currentMaker ?? "");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <S.FullStack direction={"row"}>
      <S.LeftSideBar>
        <ImageList variant="masonry" cols={3} gap={8}>
          {itemData !== null &&
            itemData !== undefined &&
            (itemData as DokgabiCharacterProps[]).map((item, idx) => (
              <ImageListItem
                key={`${idx}-chat-skin-character-list`}
                className="dokgabi-character-image-item"
                sx={{ display: "flex" }}
              >
                <img
                  srcSet={`${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${item.file_path}`}
                  src={`${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${item.file_path}`}
                  alt={`${item.id}`}
                  loading="lazy"
                />
                <div
                  className="dokgabi-character-image-hover"
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    zIndex: 5,
                  }}
                >
                  <Button
                    onClick={() => onSaveImage(item)}
                    variant="contained"
                    sx={{
                      background: "rgb(83, 64, 255)",
                      "&:hover": {
                        background: "rgb(83, 64, 255)",
                      },
                    }}
                  >
                    적용
                  </Button>
                </div>
              </ImageListItem>
            ))}
        </ImageList>
      </S.LeftSideBar>
      <S.RightSidebar>
        {currentMaker !== "admin" && (
          <Box
            sx={{
              width: "65%",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                padding: "10px",
                background: process.env.REACT_APP_MAIN_COLOR,
                borderRadius: "40px",
                color: "white",
              }}
            >
              <Typography sx={{ fontSize: "11px" }}>
                캐릭터 생성 횟수 : {leftNum?.toString()}
              </Typography>
            </Box>
          </Box>
        )}

        {isMaking ? (
          <Box
            sx={{
              flex: 3,
              height: "50%",
              borderRadius: "5px",
              padding: "20px",
              margin: "20px",
              border: `1px solid ${process.env.REACT_APP_MAIN_COLOR}`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Lottie
              options={defaultOptions}
              height={150}
              width={300}
              isClickToPauseDisabled={true}
            />
            <Typography sx={{ color: "#afafaf" }}>이미지 생성 중</Typography>
          </Box>
        ) : (
          <img
            srcSet={
              flow.character_image
                ? `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
                    flow.character_image.file_path ?? ""
                  }`
                : "/no-thumbnail.png"
            }
            src={
              flow.character_image
                ? `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
                    flow.character_image.file_path ?? ""
                  }`
                : "/no-thumbnail.png"
            }
            alt={
              flow.character_image
                ? `${flow.character_image.id}`
                : "챗봇 캐릭터를 선택해주세요"
            }
            loading="lazy"
            style={{
              flex: 3,
              height: "50%",
              borderRadius: "5px",
              padding: "20px",
              margin: "20px",
              border: `1px solid ${process.env.REACT_APP_MAIN_COLOR}`,
            }}
          />
        )}
        <Box
          sx={{
            flex: 1,
            width: "100%",
            display: "flex",
            alignItems: "end",
            justifyContent: "center",
          }}
        >
          <ImageMakeInput />
        </Box>
      </S.RightSidebar>
    </S.FullStack>
  );
}

import React from "react";
import { useGetFlowDataQuery } from "../Queries/DokgabiFlowQueries";
import { useAtom } from "jotai";
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import * as S from "../Components/MyChatbotList/styled";
import { formatDateToDay } from "../Utils/DatetimeUtil";
import {
  deleteFlowAtom,
  editMyChatbotAtom,
  makeChatbotInMyChatbotListAtom,
} from "../ViewModels/MyChatbotListViewModel";
import { myChatbotListUserAtom } from "../Atoms/MyChatbotListAtom";
import { userAtom } from "../Atoms/PublicAtom";
import { Delete } from "@mui/icons-material";
import { DokgabiFlowProps } from "../Models/DokgabiFlow";

export function MyChatbotList() {
  const [myChatbotListUser] = useAtom(myChatbotListUserAtom);
  const [user] = useAtom(userAtom);
  const [, makeChatbot] = useAtom(makeChatbotInMyChatbotListAtom);
  const [, editMyChatbot] = useAtom(editMyChatbotAtom);
  const [, deleteChatbot] = useAtom(deleteFlowAtom);

  const { data: flowsData } = useGetFlowDataQuery(
    user?.id === myChatbotListUser
      ? user.membership === "admin"
        ? "admin"
        : myChatbotListUser
      : myChatbotListUser ?? ""
  );

  if (flowsData === null || flowsData === undefined) return null;

  return (
    <Box sx={{ flexGrow: 1 }} padding={5}>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12, lg: 20 }}
      >
        <S.CustomGrid xs={4} sm={4} md={4} lg={4} xl={4} key={0} item>
          <S.CustomCard
            sx={{
              color: "white",
              background: process.env.REACT_APP_MAIN_COLOR,
              height: "100%", // Fill the grid item height
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              cursor: "pointer",
              minHeight: 253.125, // Set the minimum height
            }}
            onClick={makeChatbot}
          >
            <S.CustomCardContent>
              <IconButton aria-label="add">
                <AddIcon sx={{ color: "white", fontSize: "50px" }} />
              </IconButton>
              <Typography gutterBottom variant="body1" component="div">
                새로운 챗봇 만들기
              </Typography>
            </S.CustomCardContent>
          </S.CustomCard>
        </S.CustomGrid>
        {flowsData.length >= 1 &&
          (flowsData as DokgabiFlowProps[]).map((flow, index) => (
            <S.CustomGrid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              key={`my-chatbot-list-${index}`}
              onClick={() => editMyChatbot(flow.id)}
              sx={{ minHeight: 253.125 }} // Match the minHeight for consistent card height
            >
              <Box
                sx={{
                  boxSizing: "border-box",
                  padding: "10px",
                  borderRadius: "4px",
                  boxShadow: "5px 5px 10px #c2c2c2",
                  "&:hover": {
                    background: "#f1f1f1",
                  },
                  height: "100%", // Ensures full height usage within the grid
                }}
              >
                <img
                  src={
                    flow.character_image !== null
                      ? `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${flow.character_image.file_path}`
                      : `/no-thumbnail.png`
                  }
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    borderRadius: "4px",
                    border: "1px solid #ececec",
                    boxSizing: "border-box",
                  }}
                  alt="chatbot logo"
                />
                <Stack
                  direction={"row"}
                  sx={{ width: "100%", justifyContent: "space-between" }}
                >
                  <Stack direction={"column"} sx={{ flexGrow: 1 }}>
                    <Typography
                      variant="subtitle2"
                      sx={{ wordBreak: "break-word" }}
                    >
                      {flow.name}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color={"#8f8f8f"}
                      sx={{ wordBreak: "break-word" }}
                    >
                      {flow.update_date === null
                        ? `최근 ${formatDateToDay(
                            new Date(flow.init_date)
                          )} 생성`
                        : `최근 ${formatDateToDay(
                            new Date(flow.update_date)
                          )} 수정`}
                    </Typography>
                  </Stack>
                  {user?.membership === "admin" && (
                    <IconButton
                      onClick={async (e) => {
                        e.stopPropagation();
                        deleteChatbot(flow.id);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  )}
                </Stack>
              </Box>
            </S.CustomGrid>
          ))}
      </Grid>
    </Box>
  );
}

import React from "react";
import { useAtom } from "jotai";
import { ListItemIcon, MenuItem, Typography } from "@mui/material";
import * as S from "./styled";
import {
  handleChatbotDesignHeaderMakeMenuCloseAtom,
  handleChatbotDesignHeaderMakeMenuOpenAtom,
} from "../../../ViewModels/ChatbotDesignHeaderViewModel";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export function ChatbotDesignHeaderFunctionMenu() {
  const [makeOpen] = useAtom(handleChatbotDesignHeaderMakeMenuOpenAtom);
  const [makeAnchorEl, makeHandleClose] = useAtom(
    handleChatbotDesignHeaderMakeMenuCloseAtom
  );

  return (
    <S.CustomMenu
      anchorEl={makeAnchorEl}
      id="chatbot-design-header-make-menu"
      open={makeOpen}
      onClose={makeHandleClose}
      onClick={makeHandleClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem>
        <Typography sx={{ fontSize: "12px" }}>BOOSTER</Typography>
        <ListItemIcon sx={{ fontSize: "12px", color: "white" }}>
          <KeyboardArrowRightIcon />
        </ListItemIcon>
      </MenuItem>
      <MenuItem>
        <Typography sx={{ fontSize: "12px" }}>ASSET 생성</Typography>
        <ListItemIcon sx={{ fontSize: "12px", color: "white" }}>
          <KeyboardArrowRightIcon />
        </ListItemIcon>
      </MenuItem>
    </S.CustomMenu>
  );
}

import { atom } from "jotai";

export const functionAnchorElAtom = atom<null | HTMLElement>(null);
export const pricingAnchorElAtom = atom<null | HTMLElement>(null);
export const tutorialAnchorElAtom = atom<null | HTMLElement>(null);
export const makingAnchorElAtom = atom<null | HTMLElement>(null);
export const profileAnchorElAtom = atom<null | HTMLElement>(null);
export const currentMyStateElAtom = atom<null | HTMLElement>(null);

export const drawerOpenAtom = atom(false);

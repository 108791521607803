import { atom } from "jotai";
import {
  currentEditingNodeIndexAtom,
  oneTargetAtom,
  oneTargetEditAtom,
  oneTargetFeatureEditAtom,
  oneTargetOneFeatureAtom,
} from "../Atoms/ChatFlowAtom";
import {
  ageOptions,
  genderOptions,
  jobOptions,
  targetFeatureOptions,
} from "../Initialize/CustomNodeOptions";
import { errorModalAtom } from "../Atoms/RootAtom";
import {
  initializeOneTargetAtom,
  initializeOneTargetOneFeatureAtom,
} from "../Initialize/ChatFlowInitialize";
import { flowAtom } from "../Atoms/ChatDesignPublicAtom";
import {
  TargetNodeDataProps,
  TargetNodeFeatureListProps,
  TargetNodeListProps,
} from "../Props/CustomNodeProps";

export const handleOneTargetNameAtom = atom(
  (get) => get(oneTargetAtom).name,
  (
    get,
    set,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) =>
    set(oneTargetAtom, (current) => ({ ...current, name: event.target.value }))
);

export const handleOneTargetFeatureKeyAtom = atom(
  (get) => get(oneTargetOneFeatureAtom).feature_key,
  (get, set, value: unknown) => {
    if (value)
      set(oneTargetOneFeatureAtom, (current) => ({
        ...current,
        feature_key: targetFeatureOptions.find(
          (val) => val.view_value === value
        ) ?? {
          value: "",
          view_value: "",
        },
        feature_value: {
          value: "",
          view_value: "",
        },
      }));
  }
);

export const handleOneTargetFeatureValueAtom = atom(
  (get) => get(oneTargetOneFeatureAtom).feature_value,
  (get, set, value: unknown) => {
    const oneTargetFeautureKey = get(oneTargetOneFeatureAtom).feature_key;

    if (value)
      set(oneTargetOneFeatureAtom, (current) => ({
        ...current,
        feature_value:
          oneTargetFeautureKey.value === "age"
            ? ageOptions.find((val) => val.view_value === value) ?? {
                value: "",
                view_value: "",
              }
            : oneTargetFeautureKey.value === "gender"
            ? genderOptions.find((val) => val.view_value === value) ?? {
                value: "",
                view_value: "",
              }
            : jobOptions.find((val) => val.view_value === value) ?? {
                value: "",
                view_value: "",
              },
      }));
  }
);

export const handleFeatureAddAtom = atom(null, (get, set) => {
  const oneTargetOneFeature = get(oneTargetOneFeatureAtom);
  const oneTarget = get(oneTargetAtom);

  if (oneTargetOneFeature.feature_key.value === "")
    set(errorModalAtom, {
      state: true,
      event: null,
      eventText: "",
      redirectUrl: "",
      text: "타겟의 특징을 무조건 선택해주세야 합니다.",
      title: "타겟 특징 선택 필수",
    });
  else if (oneTargetOneFeature.feature_value.value === "")
    set(errorModalAtom, {
      state: true,
      event: null,
      eventText: "",
      redirectUrl: "",
      text: "타겟의 특징을 무조건 선택해주세야 합니다.",
      title: "타겟 특징 선택 필수",
    });
  else if (
    oneTarget.target_feature_list.find(
      (val) => val.feature_key.value === oneTargetOneFeature.feature_key.value
    )
  )
    set(errorModalAtom, {
      state: true,
      event: null,
      eventText: "",
      redirectUrl: "",
      text: "중복된 타겟 특징을 가진 것을 이미 저장하였습니다.",
      title: "타겟 특징 선택 중복",
    });
  else {
    set(oneTargetAtom, (current) => ({
      ...current,
      target_feature_list: [
        ...current.target_feature_list,
        oneTargetOneFeature,
      ],
    }));

    set(oneTargetOneFeatureAtom, initializeOneTargetOneFeatureAtom);
  }
});

export const handleFeatureEditAtom = atom(null, (get, set) => {
  const oneTargetOneFeature = get(oneTargetOneFeatureAtom);
  const oneTarget = get(oneTargetAtom);
  const editIndex = get(oneTargetFeatureEditAtom);

  const foundFeature = oneTarget.target_feature_list.find(
    (val) => val.feature_key.value === oneTargetOneFeature.feature_key.value
  );

  const foundIndex = oneTarget.target_feature_list.findIndex(
    (val) => val.feature_key.value === oneTargetOneFeature.feature_key.value
  );

  if (oneTargetOneFeature.feature_key.value === "")
    set(errorModalAtom, {
      state: true,
      event: null,
      eventText: "",
      redirectUrl: "",
      text: "타겟의 특징을 무조건 선택해주세야 합니다.",
      title: "타겟 특징 선택 필수",
    });
  else if (oneTargetOneFeature.feature_value.value === "")
    set(errorModalAtom, {
      state: true,
      event: null,
      eventText: "",
      redirectUrl: "",
      text: "타겟의 특징을 무조건 선택해주세야 합니다.",
      title: "타겟 특징 선택 필수",
    });
  else if (foundFeature && foundIndex !== editIndex)
    set(errorModalAtom, {
      state: true,
      event: null,
      eventText: "",
      redirectUrl: "",
      text: "중복된 타겟 특징을 가진 것을 이미 저장하였습니다.",
      title: "타겟 특징 선택 중복",
    });
  else if (editIndex === null)
    set(errorModalAtom, {
      state: true,
      event: null,
      eventText: "",
      redirectUrl: "",
      text: "네트워크 오류 발생",
      title: "네트워크 오류",
    });
  else {
    set(oneTargetAtom, (current) => ({
      ...current,
      target_feature_list: current.target_feature_list.map((feature, index) =>
        index === editIndex ? oneTargetOneFeature : feature
      ),
    }));

    set(oneTargetOneFeatureAtom, initializeOneTargetOneFeatureAtom);
    set(oneTargetFeatureEditAtom, null);
  }
});

export const handleTargetAddAtom = atom(
  (get) => {
    const flow = get(flowAtom);
    const currentEditingNodeIndex = get(currentEditingNodeIndexAtom);

    return currentEditingNodeIndex !== null
      ? flow.flow_nodes[currentEditingNodeIndex]
      : null;
  },
  (get, set) => {
    const oneTarget = get(oneTargetAtom);
    const currentIndex = get(currentEditingNodeIndexAtom);

    if (oneTarget.name === "")
      set(errorModalAtom, {
        state: true,
        event: null,
        eventText: "",
        redirectUrl: "",
        text: "타겟이름 설정은 필수 입니다.",
        title: "타겟 이름 설정",
      });
    else if (oneTarget.target_feature_list.length < 1)
      set(errorModalAtom, {
        state: true,
        event: null,
        eventText: "",
        redirectUrl: "",
        text: "타겟특징 설정 하나 이상은 필수 입니다.",
        title: "타겟 특징 설정",
      });
    else if (currentIndex === null)
      set(errorModalAtom, {
        state: true,
        event: null,
        eventText: "",
        redirectUrl: "",
        text: "네트워크 오류 발생했습니다.\n잠시 뒤에 시도해주세요.",
        title: "네트워크 오류",
      });
    else {
      set(flowAtom, (current) => {
        const updatedNodes = [...current.flow_nodes];
        const updatedNode = {
          ...updatedNodes[currentIndex],
          data: {
            ...updatedNodes[currentIndex].data,
            target_list: [
              oneTarget,
              ...(
                updatedNodes[currentIndex]
                  .data as unknown as TargetNodeDataProps
              ).target_list,
            ],
          },
        };
        updatedNodes[currentIndex] = updatedNode;

        return {
          ...current,
          flow_nodes: updatedNodes,
        };
      });

      set(oneTargetAtom, initializeOneTargetAtom);
      set(oneTargetOneFeatureAtom, initializeOneTargetOneFeatureAtom);
    }
  }
);

export const handleTargetEditingAtom = atom(null, (get, set) => {
  const oneTarget = get(oneTargetAtom);
  const currentIndex = get(currentEditingNodeIndexAtom);
  const editingIndex = get(oneTargetEditAtom);

  if (oneTarget.name === "")
    set(errorModalAtom, {
      state: true,
      event: null,
      eventText: "",
      redirectUrl: "",
      text: "타겟이름 설정은 필수 입니다.",
      title: "타겟 이름 설정",
    });
  else if (oneTarget.target_feature_list.length < 1)
    set(errorModalAtom, {
      state: true,
      event: null,
      eventText: "",
      redirectUrl: "",
      text: "타겟특징 설정 하나 이상은 필수 입니다.",
      title: "타겟 특징 설정",
    });
  else if (currentIndex === null)
    set(errorModalAtom, {
      state: true,
      event: null,
      eventText: "",
      redirectUrl: "",
      text: "네트워크 오류 발생했습니다.\n잠시 뒤에 시도해주세요.",
      title: "네트워크 오류",
    });
  else {
    set(flowAtom, (current) => {
      const updatedNodes = [...current.flow_nodes];
      const updatedNode = {
        ...updatedNodes[currentIndex],
        data: {
          ...updatedNodes[currentIndex].data,
          target_list: (
            updatedNodes[currentIndex].data as unknown as TargetNodeDataProps
          ).target_list.map((target, index) =>
            index === editingIndex ? oneTarget : target
          ),
        },
      };
      updatedNodes[currentIndex] = updatedNode;

      return {
        ...current,
        flow_nodes: updatedNodes,
      };
    });

    set(oneTargetAtom, initializeOneTargetAtom);
    set(oneTargetOneFeatureAtom, initializeOneTargetOneFeatureAtom);
  }
});

export const hanldeTargetEditAtom = atom(
  null,
  (get, set, target: TargetNodeListProps, index: number) => {
    const currentIndex = get(currentEditingNodeIndexAtom);

    if (currentIndex) {
      const curNode = get(flowAtom).flow_nodes[currentIndex];

      if (
        (curNode.data as unknown as TargetNodeDataProps).target_list[index]
          .name === "기타"
      ) {
        set(errorModalAtom, {
          state: true,
          event: null,
          eventText: "",
          redirectUrl: "",
          text: "기타는 수정할 수 없습니다.",
          title: "수정 불가",
        });
      } else {
        set(oneTargetAtom, target);
        set(oneTargetEditAtom, index);
      }
    }
  }
);

export const hanldeTargetDeleteAtom = atom(null, (get, set, index: number) => {
  const currentIndex = get(currentEditingNodeIndexAtom);

  if (currentIndex) {
    const curNode = get(flowAtom).flow_nodes[currentIndex];

    if (
      (curNode.data as unknown as TargetNodeDataProps).target_list[index]
        .name === "기타"
    ) {
      set(errorModalAtom, {
        state: true,
        event: null,
        eventText: "",
        redirectUrl: "",
        text: "기타는 삭제할 수 없습니다.",
        title: "삭제 불가",
      });
    } else {
      set(flowAtom, (current) => {
        const updatedNodes = [...current.flow_nodes];

        const updatedNode = {
          ...updatedNodes[currentIndex],
          data: {
            ...updatedNodes[currentIndex].data,
            target_list: (
              updatedNodes[currentIndex].data as unknown as TargetNodeDataProps
            ).target_list.filter((_, i) => i !== index),
          },
        };
        updatedNodes[currentIndex] = updatedNode;

        return {
          ...current,
          flow_nodes: updatedNodes,
        };
      });

      set(oneTargetAtom, initializeOneTargetAtom);
      set(oneTargetOneFeatureAtom, initializeOneTargetOneFeatureAtom);
      set(oneTargetFeatureEditAtom, null);
    }
  }
});

export const handleTargetFeatureEditAtom = atom(
  (get) => get(oneTargetFeatureEditAtom),
  (get, set, feature: TargetNodeFeatureListProps, index: number) => {
    set(oneTargetFeatureEditAtom, index);
    set(oneTargetOneFeatureAtom, feature);
  }
);

export const handleTargetFeatureDeleteAtom = atom(
  null,
  (get, set, index: number) =>
    set(oneTargetAtom, (current) => ({
      ...current,
      target_feature_list: current.target_feature_list.filter(
        (_, i) => i !== index
      ),
    }))
);

export const handleTargetInitialzieAtom = atom(null, (get, set) => {
  set(oneTargetAtom, initializeOneTargetAtom);
  set(oneTargetOneFeatureAtom, initializeOneTargetOneFeatureAtom);
  set(oneTargetFeatureEditAtom, null);
});

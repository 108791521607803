import { useAtom } from "jotai";
import React from "react";
import {
  handleLogoutItemClickAtom,
  handleNoticeWhenServiceIsInDevelopAtom,
  handleProfileMenuCloseAtom,
  handleProfileMenuOpenAtom,
} from "../../../../ViewModels/HeaderViewModel";
import {
  Avatar,
  Box,
  Divider,
  ListItemIcon,
  MenuItem,
  Typography,
} from "@mui/material";
import { userAtom } from "../../../../Atoms/PublicAtom";
import * as S from "./styled";
import {
  AddCard,
  EventRepeat,
  Logout,
  ManageAccounts,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export function ProfileMenu() {
  const [user] = useAtom(userAtom);

  const [profileOpen] = useAtom(handleProfileMenuOpenAtom);
  const [profileAnchorEl, profileHandleClose] = useAtom(
    handleProfileMenuCloseAtom
  );

  const [, handleWaitingNotice] = useAtom(
    handleNoticeWhenServiceIsInDevelopAtom
  );

  const [, handleLogout] = useAtom(handleLogoutItemClickAtom);

  const navigate = useNavigate();

  return (
    <S.CustomMenu
      anchorEl={profileAnchorEl}
      id="account-menu"
      open={profileOpen}
      onClose={profileHandleClose}
      onClick={profileHandleClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem onClick={() => navigate("/profile")}>
        <Avatar
          alt="Remy Sharp"
          src={
            user?.image === ""
              ? ""
              : `${process.env.REACT_APP_USER_MEDIA_ADDRESS}${user?.image}`
          }
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginLeft: 2,
          }}
        >
          <Typography variant="h6">{user?.nickname}</Typography>
          <Typography variant="body2" color="text.secondary">
            {user?.id}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {user?.membership}
          </Typography>
        </Box>
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => navigate("/profile")}>
        <ListItemIcon>
          <ManageAccounts fontSize="small" />
        </ListItemIcon>
        내 계정 설정
      </MenuItem>
      <MenuItem
        onClick={() =>
          (window.location.href = process.env.REACT_APP_DOKGABI_PAY_ADDRESS)
        }
      >
        <ListItemIcon>
          <EventRepeat fontSize="small" />
        </ListItemIcon>
        플랜 연장
      </MenuItem>
      <MenuItem
        onClick={() =>
          (window.location.href = process.env.REACT_APP_DOKGABI_PAY_ADDRESS)
        }
      >
        <ListItemIcon>
          <AddCard fontSize="small" />
        </ListItemIcon>
        플랜 업그레이드
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        로그아웃
      </MenuItem>
    </S.CustomMenu>
  );
}

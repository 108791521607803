import React from "react";
import { Typography } from "@mui/material";
import { FileDownload } from "@mui/icons-material";
import * as S from "./styled";

export function NoFileUpload() {
  return (
    <S.FileUploadInnerBox>
      <FileDownload sx={{ width: "30%", height: "30%", color: "#8f8f8f" }} />
      <Typography sx={{ color: "#8f8f8f" }}>파일을 업로드 해주세요</Typography>
      <Typography sx={{ color: "#8f8f8f" }}>
        파일 형식은 txt, pdf, csv, xlsx를 지원하고 있습니다.
      </Typography>
    </S.FileUploadInnerBox>
  );
}

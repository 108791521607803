import React from "react";
import {
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Divider,
  Button,
} from "@mui/material";
import { userAtom } from "../../../Atoms/PublicAtom";
import { useAtom } from "jotai";
import {
  usecalculateUsage,
  useGetUsage,
} from "../../../Queries/DokgabiUsageQueries";

export function UsageManage() {
  const [user] = useAtom(userAtom);

  const mainColor = process.env.REACT_APP_MAIN_COLOR || "#1976d2"; // 기본 색상 설정
  const hoverColor = "#381775"; // Hover 시 어둡게 표시할 색상

  if (user === null) return null;

  const { data: usageData } = usecalculateUsage(
    user.membership === "admin" ? "admin" : user?.id
  );
  const { data: maxUsageData } = useGetUsage(
    user.membership === "admin" ? "admin" : user?.id
  );

  if (
    usageData === null ||
    usageData === undefined ||
    maxUsageData === null ||
    maxUsageData === undefined
  )
    return null;

  const chattingNum =
    usageData.chatting_count -
      maxUsageData.maximum_chatting_number -
      maxUsageData.pay_chatting_number <=
    0
      ? 0
      : usageData.chatting_count -
        maxUsageData.maximum_chatting_number -
        maxUsageData.pay_chatting_number;

  const assetNum =
    usageData.asset_count -
      maxUsageData.maximum_asset_number -
      maxUsageData.pay_asset_number <=
    0
      ? 0
      : usageData.asset_count -
        maxUsageData.maximum_asset_number -
        maxUsageData.pay_asset_number;

  const chattingCost =
    (usageData.chatting_count -
      maxUsageData.maximum_chatting_number -
      maxUsageData.pay_chatting_number) *
      10 <=
    0
      ? 0
      : (usageData.chatting_count -
          maxUsageData.maximum_chatting_number -
          maxUsageData.pay_chatting_number) *
        10;
  const assetCost =
    (usageData.asset_count -
      maxUsageData.maximum_asset_number -
      maxUsageData.pay_asset_number) *
      0.45 <=
    0
      ? 0
      : (usageData.asset_count -
          maxUsageData.maximum_asset_number -
          maxUsageData.pay_asset_number) *
        0.45;

  const totalCost =
    Math.floor(chattingCost + assetCost) <= 0
      ? 0
      : Math.floor(chattingCost + assetCost);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="end" p={2}>
        <Box display="flex" gap={2}>
          {totalCost > 0 && (
            <Button
              variant="contained"
              sx={{
                backgroundColor: mainColor,
                color: "#fff",
                borderRadius: "30px",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: hoverColor,
                },
              }}
              onClick={() =>
                (window.location.href = `https://pay.dokgabi.ai/extra?chattingnum=${chattingNum}&assetnum=${assetNum}&totalprice=${totalCost}`)
              }
            >
              초과 사용량 결제
            </Button>
          )}
        </Box>
      </Box>

      {/* 사용량 카드 */}
      <Box mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  서비스 사용량
                </Typography>
                <Box display="flex" justifyContent="space-between">
                  <Typography>사용 플랜</Typography>
                  <Typography fontWeight="bold">
                    {user.membership} 플랜 사용중
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography>만든 챗봇 수</Typography>
                  <Typography>
                    {usageData.chatbot_count.toLocaleString()}개
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography>현재까지 생성한 챗봇 대화 수</Typography>
                  <Typography>
                    {usageData.chatting_count.toLocaleString()}개
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography>사용 Asset 수</Typography>
                  <Typography>
                    {usageData.asset_count.toLocaleString()}개
                  </Typography>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Box display="flex" justifyContent="space-between">
                  <Typography>무료 제공 챗봇 수</Typography>
                  <Typography>
                    {maxUsageData.user_id.membership === "lite"
                      ? "챗봇 1개"
                      : maxUsageData.user_id.membership === "essential"
                      ? "커스텀 챗봇 2개 + 템플릿 챗봇 1개"
                      : maxUsageData.user_id.membership === "pro"
                      ? "커스텀 챗봇 2개 + 템플릿 챗봇 1개"
                      : maxUsageData.user_id.membership === "premium"
                      ? "커스텀 챗봇 4개 + 템플릿 챗봇 1개"
                      : maxUsageData.user_id.membership === "admin"
                      ? "무제한"
                      : "free 유저는 만들지 못해요ㅠㅠ"}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography>무료 제공 최대 대화 수</Typography>
                  <Typography>
                    {maxUsageData.maximum_chatting_number.toLocaleString()}개
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography>이미 결제한 초과 대화 수</Typography>
                  <Typography>
                    {maxUsageData.pay_chatting_number.toLocaleString()}개
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography>무료 제공 최대 Asset 수</Typography>
                  <Typography>
                    {maxUsageData.maximum_asset_number.toLocaleString()}개
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography>이미 결제한 초과 Asset 수</Typography>
                  <Typography>
                    {maxUsageData.pay_asset_number.toLocaleString()}개
                  </Typography>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Box>
                  <Typography fontWeight="bold">비용 계산</Typography>
                  <Typography
                    sx={{
                      backgroundColor: "#f5f5f5",
                      padding: "10px",
                      borderRadius: "8px",
                      marginTop: "10px",
                    }}
                  >
                    {chattingCost > 0 && (
                      <>
                        ( {usageData.chatting_count.toLocaleString()} -{" "}
                        {maxUsageData.maximum_chatting_number.toLocaleString()}{" "}
                        - {maxUsageData.pay_chatting_number.toLocaleString()} )
                        × 10원/대화
                      </>
                    )}
                    {chattingCost > 0 && assetCost > 0 && <br />}
                    {assetCost > 0 && (
                      <>
                        ( {usageData.asset_count.toLocaleString()} -{" "}
                        {maxUsageData.maximum_asset_number.toLocaleString()} -{" "}
                        {maxUsageData.pay_asset_number.toLocaleString()} ) ×
                        0.45원/문서
                      </>
                    )}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" mt={2}>
                  <Typography fontWeight="bold">총 비용</Typography>
                  <Typography fontWeight="bold">
                    ₩{Math.floor(totalCost).toLocaleString()}
                  </Typography>
                </Box>
                <Typography
                  sx={{ fontSize: "12px", color: "gray", marginTop: "5px" }}
                >
                  ※ 총 비용은 소수점을 버리고 계산됩니다.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

import React, { useEffect } from "react";
import { useAtom } from "jotai";
import {} from "../../../../../ViewModels/ChatFlowViewModel";
import * as S from "../styled";
import {
  ageOptions,
  genderOptions,
  jobOptions,
  targetFeatureOptions,
} from "../../../../../Initialize/CustomNodeOptions";
import { Box, IconButton, MenuItem, Typography } from "@mui/material";
import {
  oneTargetAtom,
  oneTargetEditAtom,
} from "../../../../../Atoms/ChatFlowAtom";
import { TargetNodeDataProps } from "../../../../../Props/CustomNodeProps";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  handleFeatureAddAtom,
  handleFeatureEditAtom,
  handleOneTargetFeatureKeyAtom,
  handleOneTargetFeatureValueAtom,
  handleOneTargetNameAtom,
  handleTargetAddAtom,
  handleTargetEditingAtom,
  handleTargetFeatureDeleteAtom,
  handleTargetFeatureEditAtom,
  handleTargetInitialzieAtom,
  hanldeTargetDeleteAtom,
  hanldeTargetEditAtom,
} from "../../../../../ViewModels/ChatFlowTargetSettingViewModel";

export function TargetNodeSetting() {
  const [currentEditingNode, handleNodeAdd] = useAtom(handleTargetAddAtom);
  const [, handleNodeEdit] = useAtom(handleTargetEditingAtom);
  const [oneTarget] = useAtom(oneTargetAtom);

  const [oneTargetName, handleOneTargetName] = useAtom(handleOneTargetNameAtom);
  const [oneTargetFeautureKey, handleOneTargetFeatureKey] = useAtom(
    handleOneTargetFeatureKeyAtom
  );
  const [oneTargetFeautureValue, handleOneTargetFeatureValue] = useAtom(
    handleOneTargetFeatureValueAtom
  );

  const [, handleFeatureAdd] = useAtom(handleFeatureAddAtom);
  const [, handleFeatureEdit] = useAtom(handleFeatureEditAtom);

  const [, handleTargetEdit] = useAtom(hanldeTargetEditAtom);
  const [, handleTargetDelete] = useAtom(hanldeTargetDeleteAtom);

  const [isEditing, handleTargetFeatureEdit] = useAtom(
    handleTargetFeatureEditAtom
  );

  const [isTargetEditing] = useAtom(oneTargetEditAtom);

  const [, handleFeatureDelete] = useAtom(handleTargetFeatureDeleteAtom);
  const [, initialize] = useAtom(handleTargetInitialzieAtom);

  useEffect(() => {
    return () => initialize();
  }, []);

  if (currentEditingNode === null) return null;

  return (
    <>
      <S.CustomTextField value={oneTargetName} onChange={handleOneTargetName} />
      <S.CustomAutoComplete
        value={oneTargetFeautureKey.view_value}
        options={targetFeatureOptions.map((value) => value.view_value)}
        onChange={(event, value) => handleOneTargetFeatureKey(value)}
        renderInput={(params) => (
          <S.CustomTextField {...params} label="타겟 피쳐" />
        )}
      />

      <S.CustomAutoComplete
        value={oneTargetFeautureValue.view_value}
        options={
          oneTargetFeautureKey.value === "age"
            ? ageOptions.map((value) => value.view_value)
            : oneTargetFeautureKey.value === "gender"
            ? genderOptions.map((value) => value.view_value)
            : jobOptions.map((value) => value.view_value)
        }
        onChange={(event, value) => handleOneTargetFeatureValue(value)}
        renderInput={(params) => (
          <S.CustomTextField {...params} label="타겟 피쳐 벨류" />
        )}
      />
      <S.CustomButton
        onClick={isEditing !== null ? handleFeatureEdit : handleFeatureAdd}
      >
        {isEditing !== null ? "타겟 옵션 수정" : "타겟 옵션 추가"}
      </S.CustomButton>
      {oneTarget.target_feature_list.map((feature, index) => (
        <MenuItem
          key={`target-feautre-list-${index}`}
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography>
            {feature.feature_key.view_value} :{" "}
            {feature.feature_value.view_value}
          </Typography>
          <Box>
            <IconButton onClick={() => handleTargetFeatureEdit(feature, index)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => handleFeatureDelete(index)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </MenuItem>
      ))}
      <S.CustomButton
        onClick={isTargetEditing === null ? handleNodeAdd : handleNodeEdit}
      >
        {isTargetEditing === null ? "타겟 추가" : "타겟 수정"}
      </S.CustomButton>
      {(
        currentEditingNode.data as unknown as TargetNodeDataProps
      ).target_list.map((target, index) => (
        <MenuItem
          key={`target-list-${index}`}
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography>{target.name}</Typography>
          <Box>
            <IconButton onClick={() => handleTargetEdit(target, index)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => handleTargetDelete(index)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </MenuItem>
      ))}
    </>
  );
}

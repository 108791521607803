import React from "react";

import { Stack, Typography } from "@mui/material";
import { Handle, Position } from "@xyflow/react";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import * as S from "../styled";

export function StartNode() {
  return (
    <>
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          cursor: "pointer",
        }}
      >
        <S.StartBox id={"start"}>
          <PlayCircleFilledIcon
            sx={{ color: "white", width: "100%", height: "80%" }}
          />
          <S.NodeNameTypography>Start</S.NodeNameTypography>
          <Handle
            type="source"
            position={Position.Right}
            id={`start-right`}
            className="handle-right"
          />
        </S.StartBox>
      </Stack>
    </>
  );
}

import { Card, CardContent, styled as muiStyled } from "@mui/material";

export const CustomCard = muiStyled(Card)({
  color: "white",
  background: process.env.REACT_APP_MAIN_COLOR,
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
});

export const CustomCardContent = muiStyled(CardContent)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: "column",
  marginBottom: 0,
  paddingBottom: 0,
});

import {
  Box,
  styled,
  Tab,
  TableCell,
  TableContainer,
  Tabs,
} from "@mui/material";

export const OuterBox = styled(Box)({
  width: "100%",
  height: "100%",
  padding: "100px",
  boxSizing: "border-box",
});

export const NoRowBox = styled(Box)({
  width: "100%",
  height: "100%",
  padding: "50px",
  boxSizing: "border-box",
  border: "3px dashed #8f8f8f",
  borderRadius: "15px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

export const AssetLibraryTableContainer = styled(TableContainer)({
  overflow: "auto",
  maxHeight: "100%",
});

export const HeaderCell = styled(TableCell)({
  background: process.env.REACT_APP_MAIN_COLOR,
  color: "white",
  whiteSpace: "nowrap",
  padding: "8px",
});

export const OneLineCell = styled(TableCell)({
  whiteSpace: "nowrap",
});

export const ExpandCell = styled(TableCell)({
  paddingBottom: 0,
  paddingTop: 0,
  background: "#f5f5f5",
});

export const ExpandBox = styled(Box)({
  margin: "8px",
});

export const CustomTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    backgroundColor: process.env.REACT_APP_MAIN_COLOR, // Indicator 색상 변경
  },
}));

interface CustomTabProps {
  tabvalue: string;
}

export const CustomTab = styled(Tab)<CustomTabProps>(({ tabvalue }) => ({
  color: tabvalue === "true" ? process.env.REACT_APP_MAIN_COLOR : "#8f8f8f",
  "&.Mui-selected": {
    color: process.env.REACT_APP_MAIN_COLOR,
  },
}));

export const InnerTabBox = styled(Box)({
  padding: "16px",
});

import { atom } from "jotai";
import { userAtom } from "../Atoms/PublicAtom";
import { openNewWindowWithPostData } from "../Utils/WindowOpenNavigateUtil";
import { errorModalAtom, successModalAtom } from "../Atoms/RootAtom";
import {
  checkUserChatbotNum,
  checkUserEndDate,
} from "../Queries/PlanValidQueries";
import { myChatbotListUserAtom } from "../Atoms/MyChatbotListAtom";
import { deleteFlow } from "../Queries/DokgabiFlowQueries";
import { handleApiResponse } from "../Utils/APIUtil";
import { getCookie } from "../Utils/CookieUtil";
import { queryClient } from "..";

export const deleteFlowAtom = atom(null, async (get, set, flowId: number) => {
  const refreshCookie = getCookie(
    process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
  );

  const accessCookie = getCookie(
    process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
  );

  const errorFunction = () => {
    set(errorModalAtom, {
      state: true,
      event: null,
      eventText: "",
      redirectUrl: "",
      text: "해당 Flow가 삭제에 실패했습니다.",
      title: "삭제 실패",
    });
  };

  const successFunction = (results: any) => {
    set(successModalAtom, {
      state: true,
      event: null,
      eventText: "",
      redirectUrl: "",
      text: "해당 Flow가 삭제 되었습니다.",
      title: "삭제 성공",
    });

    const user = get(userAtom);
    const myChatbotListUser = get(myChatbotListUserAtom);

    const userId =
      user?.id === myChatbotListUser
        ? user.membership === "admin"
          ? "admin"
          : myChatbotListUser
        : myChatbotListUser ?? "";

    queryClient.invalidateQueries({
      queryKey: [`${userId}-flow`],
    });
  };

  const newAccessToken = await handleApiResponse(
    refreshCookie,
    set,
    () => deleteFlow(accessCookie ?? "", flowId),
    () => errorFunction(),
    (results) => successFunction(results)
  );

  if (newAccessToken) {
    await handleApiResponse(
      null,
      set,
      () => deleteFlow(newAccessToken ?? "", flowId),
      () => errorFunction(),
      (results) => successFunction(results)
    );
  }
});

export const editMyChatbotAtom = atom(
  null,
  async (get, set, flowId: number) => {
    const selectUser = get(myChatbotListUserAtom);
    const user = get(userAtom);
    if (selectUser) {
      const refreshCookie = getCookie(
        process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
      );

      const accessCookie = getCookie(
        process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
      );

      const errorFunction = () => {
        set(errorModalAtom, {
          state: true,
          event:
            user?.id === selectUser
              ? () =>
                  (window.location.href =
                    process.env.REACT_APP_DOKGABI_PAY_ADDRESS)
              : null,
          eventText: user?.id === selectUser ? "플랜 갱신" : "",
          redirectUrl: "",
          title: "플랜 기간 만료",
          text:
            user?.id === selectUser
              ? "현재 플랜의 기간이 만료되었습니다.\n플랜 갱신 후 다시 시도해 주세요."
              : "현재 플랜의 기간이 만료되었습니다.\n초대한 유저에게 플랜 갱신 요청해 주세요.",
        });
      };

      const successFunction = (results: any) => {
        const data = {
          message: "chatbotDesign",
          type: "edit",
          id: flowId,
        };

        openNewWindowWithPostData(
          `${process.env.REACT_APP_DOKGABI_DASHBOARD_ADDRESS}/chatbotDesign/flow`,
          data
        );
      };

      const newAccessToken = await handleApiResponse(
        refreshCookie,
        set,
        () => checkUserEndDate(accessCookie ?? "", selectUser),
        () => errorFunction(),
        (results) => successFunction(results)
      );

      if (newAccessToken) {
        await handleApiResponse(
          null,
          set,
          () => checkUserEndDate(newAccessToken ?? "", selectUser),
          () => errorFunction(),
          (results) => successFunction(results)
        );
      }
    } else {
      set(errorModalAtom, {
        state: true,
        event: null,
        eventText: "",
        redirectUrl: "",
        title: "네트워크 오류",
        text: "네트워크 오류가 났습니다.\n새로고침 후에 시도해주세요.",
      });
    }
  }
);

export const makeChatbotInMyChatbotListAtom = atom(null, async (get, set) => {
  const selectUser = get(myChatbotListUserAtom);
  const user = get(userAtom);

  if (user?.id) {
    const refreshCookie = getCookie(
      process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
    );

    const accessCookie = getCookie(
      process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
    );

    const errorFunction = () => {
      set(errorModalAtom, {
        state: true,
        event: () =>
          (window.location.href = process.env.REACT_APP_DOKGABI_PAY_ADDRESS),
        eventText: "플랜 갱신",
        redirectUrl: "",
        title: "플랜 기간 만료",
        text: "현재 플랜의 기간이 만료되었습니다.\n플랜 갱신 후 다시 시도해 주세요.",
      });
    };

    const successFunction = async (results: any) => {
      const refreshCookie = getCookie(
        process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
      );

      const accessCookie = getCookie(
        process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
      );

      const errorFunction = () => {
        set(errorModalAtom, {
          state: true,
          event: () =>
            (window.location.href = process.env.REACT_APP_DOKGABI_PAY_ADDRESS),
          eventText: "플랜 갱신",
          redirectUrl: "",
          title: "챗봇 생성 제한",
          text: "현재 플랜에서 최대 챗봇 생성에 도달 했습니다.\n플랜 업그레이드 후에 다시 시도해 주세요.",
        });
      };

      const successFunction = (results: any) => {
        if (results === true) {
          const data = {
            message: "chatbotDesign",
            type: "create",
            id: -1,
          };

          openNewWindowWithPostData(
            `${process.env.REACT_APP_DOKGABI_DASHBOARD_ADDRESS}/chatbotDesign/flow`,
            data
          );
        } else {
          set(errorModalAtom, {
            state: true,
            event: () =>
              (window.location.href =
                process.env.REACT_APP_DOKGABI_PAY_ADDRESS),
            eventText: "플랜 갱신",
            redirectUrl: "",
            title: "챗봇 생성 제한",
            text: "현재 플랜에서 최대 챗봇 생성에 도달 했습니다.\n플랜 업그레이드 후에 다시 시도해 주세요.",
          });
        }
      };

      const newAccessToken = await handleApiResponse(
        refreshCookie,
        set,
        () => checkUserChatbotNum(accessCookie ?? "", user?.id),
        () => errorFunction(),
        (results) => successFunction(results)
      );

      if (newAccessToken) {
        await handleApiResponse(
          null,
          set,
          () => checkUserChatbotNum(newAccessToken ?? "", user?.id),
          () => errorFunction(),
          (results) => successFunction(results)
        );
      }
    };

    const newAccessToken = await handleApiResponse(
      refreshCookie,
      set,
      () => checkUserEndDate(accessCookie ?? "", user?.id),
      () => errorFunction(),
      (results) => successFunction(results)
    );

    if (newAccessToken) {
      await handleApiResponse(
        null,
        set,
        () => checkUserEndDate(newAccessToken ?? "", user?.id),
        () => errorFunction(),
        (results) => successFunction(results)
      );
    }
  } else {
    set(errorModalAtom, {
      state: true,
      event: null,
      eventText: "",
      redirectUrl: "",
      title: "네트워크 오류",
      text: "네트워크 오류가 났습니다.\n새로고침 후에 시도해주세요.",
    });
  }
});

import { MarkerType } from "@xyflow/react";
import {
  DokgabiAssetProps,
  DokgabiCharacterProps,
  DokgabiFlowProps,
  DokgabiFrontendProps,
} from "../Models/DokgabiFlow";

export const defaultDokgabiCharacterProps: DokgabiCharacterProps = {
  id: 1,
  character_type: "animation",
  file_path: "character/mentor.png",
  is_public: true,
  maker: "admin",
  init_date: new Date("2024-09-03 00:16:06.000000"),
  update_date: null,
  delete_date: null,
};

export const defaultDokgabiFrontendProps: DokgabiFrontendProps = {
  id: 1,
  file_path: "frontend/index.html",
  front_type: "test",
  is_public: true,
  maker: "admin",
  init_date: new Date("2024-09-03 00:17:26.000000"),
  update_date: null,
  delete_date: null,
};

export const defaultDokgabiFlowProps: DokgabiFlowProps = {
  id: -1,
  name: "",
  flow_nodes: [],
  flow_edges: [],
  chatbot_socket_parameter: "",
  character_image: null,
  flow_used_assets: [],
  flow_used_frontend: null,
  maker: "",
  is_public: true,
  init_date: new Date(0),
  update_date: null,
  delete_date: null,
  chatbot_type: null,
  desc: null,
};

export const defaultNodesAtom = [
  {
    id: "0",
    type: "start",
    position: { x: 0, y: 0 },
    data: {
      id: "0",
      label: "",
    },
  },
  {
    id: "1",
    type: "welcome",
    position: { x: 250, y: 0 },
    data: {
      hide: false,
      id: "1",
      type: { value: "", view_value: "" },
      model_name: {
        type: "",
        value: "",
        view_value: "",
      },
      api_key: "",
      prompt: "",
      welcome_message: "",
    },
  },
];

export const defaultEdgesAtom = [
  {
    id: "xy-edge__0start-right-1-left",
    source: "0",
    sourceHandle: "start-right",
    target: "1",
    targetHandle: "1-left",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 20,
      height: 20,
      color: process.env.REACT_APP_MAIN_COLOR,
    },
    style: {
      strokeWidth: 3,
      stroke: process.env.REACT_APP_MAIN_COLOR,
    },
  },
];

// custom Node
export const initializeWelcomeNodeAtom = {
  id: "",
  type: "welcome",
  position: { x: 0, y: 0 },
  data: {
    id: "",
    type: { value: "", view_value: "" },
    model_name: {
      type: "",
      value: "",
      view_value: "",
    },
    api_key: "",
    prompt: "",
    welcome_message: "",
    hide: false,
  },
};

export const initializeTargetNodeAtom = {
  id: "",
  type: "target",
  position: { x: 0, y: 0 },
  data: {
    id: "",
    target_list: [
      {
        name: "기타",
        target_feature_list: [],
      },
    ],
    hide: false,
  },
};

export const initializeTopicClassifyNodeAtom = {
  id: "",
  type: "topic",
  position: { x: 0, y: 0 },
  data: {
    hide: false,
    id: "",
    intend_list: [
      {
        intend_name: "기타",
        intend_desc: "분류한 주제 이외의 모든 질문은 이에 해당",
        intend_exam: ["점심 메뉴 추천 좀", "오늘 날씨가 좋아."],
      },
    ],
    temporary_intend: {
      intend_name: "",
      intend_desc: "",
      intend_exam: "",
      intend_exam_list: [],
    },
  },
};

export const initializeBasicLLMNodeAtom = {
  id: "",
  type: "basicLLM",
  position: { x: 0, y: 0 },
  data: {
    hide: false,
    id: "",
    model_name: {
      type: "",
      value: "",
      view_value: "",
    },
    api_key: "",
    prompt: "",
  },
};

export const initializeRAGBaseLLMNodeAtom = {
  id: "",
  type: "ragLLM",
  position: { x: 0, y: 0 },
  data: {
    hide: false,
    id: "",
    output_type: {
      value: "",
      view_value: "",
    },
    grounding_data: "",
    model_name: {
      type: "",
      value: "",
      view_value: "",
    },
    api_key: "",
    prompt: "",
  },
};

export const initializeMakeJsonNodeAtom = {
  id: "",
  type: "makeJson",
  position: { x: 0, y: 0 },
  data: {
    hide: false,
    id: "",
    grounding_data: "",
    data_num: "5",
    model_name: {
      type: "",
      value: "",
      view_value: "",
    },
    api_key: "",
    json_type: {
      value: "",
      view_value: "",
    },
    chart_type: {
      value: "",
      view_value: "",
    },
    chart_description: "",
    etc_json_data: "",
  },
};

export const initializeTriggerNodeAtom = {
  id: "",
  type: "trigger",
  position: { x: 0, y: 0 },
  data: {
    hide: false,
    id: "",
    type: { value: "", view_value: "" },
    recommend_messages: [],
    trigger_message: "",
  },
};

export const initializeOneTargetAtom = {
  name: "",
  target_feature_list: [],
};

export const initializeOneTargetOneFeatureAtom = {
  feature_key: {
    value: "",
    view_value: "",
  },
  feature_value: {
    value: "",
    view_value: "",
  },
};

export const initializeOneTopicAtom = {
  intend_name: "",
  intend_desc: "",
  intend_exam: [],
};

export const initialDokgabiAsset: DokgabiAssetProps = {
  id: -1,
  name: "",
  verctor_file_path: "",
  desc: null,
  keywords: null,
  before_vector_file_path: [],
  output_type: "",
  maker: "",
  is_public: false,
  thumbnail: null,
  init_date: new Date(),
  update_date: null,
  delete_date: null,
};

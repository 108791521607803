import { atom } from "jotai";
import {
  TargetNodeFeatureListProps,
  TargetNodeListProps,
  TopicClassifyNodeListProps,
} from "../Props/CustomNodeProps";
import {
  initializeOneTargetAtom,
  initializeOneTargetOneFeatureAtom,
  initializeOneTopicAtom,
} from "../Initialize/ChatFlowInitialize";
import { encryptedAtomWithStorage } from "./EncryptedAtomWithStorage";

export const typeAtom = atom<string | null>(null);

export const currentMaxIdAtom = encryptedAtomWithStorage<number | null>(
  "currentMaxIdAtom",
  null
);

export const currentEditingNodeIndexAtom = atom<number | null>(null);

// 타겟 세팅 관련
export const oneTargetAtom = atom<TargetNodeListProps>(initializeOneTargetAtom);

export const oneTargetOneFeatureAtom = atom<TargetNodeFeatureListProps>(
  initializeOneTargetOneFeatureAtom
);

export const oneTargetFeatureEditAtom = atom<number | null>(null);
export const oneTargetEditAtom = atom<number | null>(null);

// 토픽 세팅 관련
export const oneTopicAtom = atom<TopicClassifyNodeListProps>(
  initializeOneTopicAtom
);

export const oneExampleAtom = atom<string>("");

export const oneExampleEditStateAtom = atom<{
  id: string;
  editIndex: number;
} | null>(null);
export const oneTopicEditStateAtom = atom<{
  id: string;
  editIndex: number;
} | null>(null);

import React from "react";
import * as S from "../Components/Error/styled";

export function NotRightPathError() {
  return (
    <S.ErrorFullContainer>
      <S.TitleText>DOKGABI™</S.TitleText>
      <S.SubText>올바르지 않은 경로로 접근하셨습니다.</S.SubText>
      <S.SubText>
        독갑이 페이지에서 챗봇 만들기 또는 템플릿 선택 또는 내 챗봇 선택을 통해
        접근해 주세요.
      </S.SubText>
      <S.CustomButton
        variant={"outlined"}
        onClick={() =>
          (window.location.href = process.env.REACT_APP_DOKGABI_HOME_ADDRESS)
        }
      >
        독갑이 홈페이지로
      </S.CustomButton>
    </S.ErrorFullContainer>
  );
}

import { atom } from "jotai";
import React from "react";
import { DokgabiAssetLibraryProps } from "../Models/DokgabiFlow";

export const keywordInputValueAtom = atom("");

export const tabValueAtom = atom(0);

export const currentAssetViewFileAtom = atom<{
  file_name: string;
  file_type: string;
  file_view_name: string;
  file_vector_progress: number;
  task_id: string;
} | null>(null);

export const isDraggingAtom = atom(false);

export const assetNameRefAtom = atom(() => React.createRef<HTMLInputElement>());

export const assetLibraryCurrentPageAtom = atom(1);

export const assetLibraryExpandedRowAtom = atom<number | null>(null);

export const assetLibrarytabValueAtom = atom(0);

export const assetLibraryListAtom = atom<DokgabiAssetLibraryProps[]>([]);

export const assetViewFileCurrentPageAtom = atom(1);

export const assetViewFileCurrentSelectedRowAtom = atom(null);

export const assetViewFileCurrentRowsAtom = atom<any[]>([]);

export const currentEditAssetLibraryIndexAtom = atom<number | null>(null);

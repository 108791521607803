import { ResponseStatus } from "../Enum/NetworkEnum";

export async function checkUserChatbotNum(access: string, id: string) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json", // JSON 형식으로 지정
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({
      id: id,
    }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_USER_API_ADDRESS}/mysql/checkDokgabiChatbotNum`,
    requestOptions
  );
  if (response.status === 200) {
    const result = await response.json();
    return result.results;
  } else if (response.status === 401) {
    return ResponseStatus.UNAUTHORIZED;
  } else if (response.status === 403) {
    return ResponseStatus.FORBIDDEN;
  } else {
    return ResponseStatus.ERROR;
  }
}

export async function checkUserCharNum(access: string, id: string) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json", // JSON 형식으로 지정
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({
      id: id,
    }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_USER_API_ADDRESS}/mysql/checkDokgabiCharacterNum`,
    requestOptions
  );
  if (response.status === 200) {
    const result = await response.json();
    return result.results;
  } else if (response.status === 401) {
    return ResponseStatus.UNAUTHORIZED;
  } else if (response.status === 403) {
    return ResponseStatus.FORBIDDEN;
  } else {
    return ResponseStatus.ERROR;
  }
}

export async function checkUserEndDate(access: string, id: string) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json", // JSON 형식으로 지정
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({
      id: id,
    }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_USER_API_ADDRESS}/mysql/checkDokgabiEndDate`,
    requestOptions
  );
  if (response.status === 200) {
    const result = await response.json();
    return result.results;
  } else if (response.status === 401) {
    return ResponseStatus.UNAUTHORIZED;
  } else if (response.status === 403) {
    return ResponseStatus.FORBIDDEN;
  } else {
    return ResponseStatus.ERROR;
  }
}

import { atom } from "jotai";
import { deleteAuthCookies, getCookie } from "../Utils/CookieUtil";
import { openaiAPIKeyAtom, userAtom } from "../Atoms/PublicAtom";
import {
  errorModalAtom,
  initializeStateAtom,
  waitingModalAtom,
} from "../Atoms/RootAtom";
import { getSelectFlow } from "../Queries/DokgabiFlowQueries";
import {
  currentMakerAtom,
  flowAtom,
  judgeRightRouteAtom,
} from "../Atoms/ChatDesignPublicAtom";
import {
  defaultDokgabiFlowProps,
  defaultEdgesAtom,
  defaultNodesAtom,
} from "../Initialize/ChatFlowInitialize";
import { currentMaxIdAtom } from "../Atoms/ChatFlowAtom";
import { findMaxId } from "../Utils/FindMaxNumUtil";
import {
  BasicLLMNodeDataProps,
  RagBaseLLMDataProps,
  WelcomeDataProps,
} from "../Props/CustomNodeProps";
import { myChatbotListUserAtom } from "../Atoms/MyChatbotListAtom";
import { getUserInfoWhenStart } from "../Queries/AuthQueries";
import { handleApiResponse } from "../Utils/APIUtil";
import { DokgabiFlowProps } from "../Models/DokgabiFlow";
import {
  hanldeGuideModalStateAtom,
  hanldeGuideModalTypeAtom,
  stepAtom,
} from "../Atoms/GuideModalAtom";
import { GuideType } from "../Enum/GuideModalEnum";

export const checkCookieAndAutoLoginAtom = atom(
  (get) => get(initializeStateAtom),
  async (get, set) => {
    const refreshCookie = getCookie(
      process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
    );

    const accessCookie = getCookie(
      process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
    );

    if (refreshCookie && accessCookie) {
      const errorFunction = () => {
        deleteAuthCookies();
        set(errorModalAtom, {
          state: true,
          event: () =>
            (window.location.href =
              process.env.REACT_APP_DOKGABI_LOGIN_ADDRESS),
          eventText: "로그인",
          redirectUrl: "",
          text: "오류가 발생하셨습니다.\n서비스를 사용하시고 싶으시면 다시 로그인 해주세요.",
          title: "오류 발생",
        });
      };

      const successFunction = (results: any) => {
        set(userAtom, {
          idx: results.id,
          id: results.user.username,
          image: results.profile_image ?? "",
          membership: results.membership,
          nickname: results.nickname,
          membership_end_date: new Date(results.end_date),
        });

        // set(inviteUserAtom, invite_user_info);

        set(myChatbotListUserAtom, results.user.username);

        if (new Date() > new Date(results.end_date)) {
          set(errorModalAtom, {
            state: true,
            event: () =>
              (window.location.href =
                process.env.REACT_APP_DOKGABI_PAY_ADDRESS),
            eventText: "플랜 갱신",
            redirectUrl: "",
            text: "플랜을 갱신해주세요.\n만료되었어요.",
            title: "플랜 만료",
          });
        }
      };

      const newAccessToken = await handleApiResponse(
        refreshCookie,
        set,
        () => getUserInfoWhenStart(accessCookie),
        () => errorFunction(),
        (results) => successFunction(results)
      );

      if (newAccessToken) {
        await handleApiResponse(
          null,
          set,
          () => getUserInfoWhenStart(newAccessToken),
          () => errorFunction(),
          (results) => successFunction(results)
        );
      }

      set(initializeStateAtom, true);
    }
  }
);

export const judgeRightRouteForChatbotDesignAtom = atom(
  (get) => get(judgeRightRouteAtom),
  (get, set, location: any) => {
    if (location.pathname.split("/")[1] !== "chatbotDesign") {
      set(judgeRightRouteAtom, true);
    }
  }
);

export const initializeLandingAppAtom = atom(null, (get, set) => {
  window.addEventListener("message", async (event) => {
    const allowedOrigins =
      process.env.REACT_APP_ALLOWED_WEB_ADDRESSES.split(",");

    if (!allowedOrigins.includes(event.origin)) {
      return;
    }
    const data = event.data;
    const user = get(userAtom);

    if (data.message === "chatbotDesign" && user) {
      set(judgeRightRouteAtom, true);

      set(waitingModalAtom, {
        state: true,
        text:
          data.type === "edit"
            ? "데이터를 가지고 오는 중입니다."
            : "기본 설정을 진행중입니다.",
      });

      // 새로 생성일 때
      if (data.id === -1) {
        // 가이드 모달 세팅
        const skip = localStorage.getItem("skip");

        if (!skip) {
          set(hanldeGuideModalStateAtom, true);
          set(hanldeGuideModalTypeAtom, GuideType.NEW);
          set(stepAtom, [
            "챗봇 초기 설정",
            "챗봇 캐릭터 설정",
            "인사말 설정",
            "만들고 싶은 챗봇 특징",
          ]);
        }

        const apiKey = get(openaiAPIKeyAtom);
        const updatedNodes = defaultNodesAtom.map((node) =>
          node.type === "welcome"
            ? { ...node, data: { ...node.data, api_key: apiKey ?? "" } }
            : node
        );

        set(flowAtom, {
          ...defaultDokgabiFlowProps,
          flow_nodes: updatedNodes,
          flow_edges: defaultEdgesAtom,
          maker: user?.membership === "admin" ? "admin" : user?.id ?? "",
        });

        set(currentMaxIdAtom, 1);

        set(
          currentMakerAtom,
          user?.membership === "admin" ? "admin" : user?.id ?? ""
        );
      } else {
        const refreshCookie = getCookie(
          process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
        );

        const accessCookie = getCookie(
          process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
        );

        const errorFunction = () => {
          set(errorModalAtom, {
            state: true,
            event: () => window.close(),
            text: "다시 챗봇 만들기를 눌러주세요.",
            title: "챗봇 가져오기 실패",
            eventText: "창 닫기",
            redirectUrl: "",
          });
        };

        const successFunction = (result: any) => {
          // 이전 버전과 상이해서 넣어둠
          (result as DokgabiFlowProps).flow_nodes.map((value) => {
            if (
              value.type === "topic" &&
              (value.data.temporary_intend === null ||
                value.data.temporary_intend === undefined)
            ) {
              value.data.temporary_intend = {
                intend_name: "",
                intend_exam: "",
                intend_desc: "",
                intend_exam_list: [],
              };
            }
          });

          set(
            currentMaxIdAtom,
            findMaxId((result as DokgabiFlowProps).flow_nodes)
          );

          // 기존에 있던 거 수정일 때
          if (data.type === "edit") {
            set(flowAtom, result as DokgabiFlowProps);
            set(currentMakerAtom, (result as DokgabiFlowProps).maker);
          }
          // 템플릿 클릭 했을 때
          else {
            const skip = localStorage.getItem("skip");

            if (!skip) {
              // 가이드 모달 띄우기 : 이 때는 그거 하기
              set(hanldeGuideModalStateAtom, true);
              set(hanldeGuideModalTypeAtom, GuideType.TEMPLATE);
              set(stepAtom, ["챗봇 초기 설정", "챗봇 캐릭터 설정"]);
            }

            const apiKey = get(openaiAPIKeyAtom);

            // 이거 일 때는 API-KEY 초기화 시키기
            (result as DokgabiFlowProps).flow_nodes.map((value) => {
              if (value.type === "welcome") {
                (value.data as unknown as WelcomeDataProps).api_key =
                  apiKey ?? "";
              } else if (value.type === "basicLLM") {
                (value.data as unknown as BasicLLMNodeDataProps).api_key =
                  apiKey ?? "";
              } else if (value.type === "ragLLM") {
                (value.data as unknown as RagBaseLLMDataProps).api_key =
                  apiKey ?? "";
              }
            });

            set(flowAtom, {
              ...(result as DokgabiFlowProps),
              id: -1,
              chatbot_socket_parameter: "",
              maker: user?.membership === "admin" ? "admin" : user?.id ?? "",
            });

            set(
              currentMakerAtom,
              user?.membership === "admin" ? "admin" : user?.id ?? ""
            );
          }
        };

        const newAccessToken = await handleApiResponse(
          refreshCookie,
          set,
          () => getSelectFlow(accessCookie ?? "", data.id),
          () => errorFunction(),
          (results) => successFunction(results)
        );

        if (newAccessToken) {
          await handleApiResponse(
            null,
            set,
            () => getSelectFlow(newAccessToken ?? "", data.id),
            () => errorFunction(),
            (results) => successFunction(results)
          );
        }
      }

      set(waitingModalAtom, {
        state: false,
        text: "",
      });
    } else if (data.message === "chatbotDesign") {
      window.close();
    }
  });
});

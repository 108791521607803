import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { Box } from "@mui/material";

interface DataPoint {
  x: number;
  y: number;
}

interface LineChartProps {
  chartTitle: string;
  xLabel: string;
  yLabel: string;
  data: DataPoint[];
}

const LineChart: React.FC<LineChartProps> = ({
  chartTitle,
  xLabel,
  yLabel,
  data,
}) => {
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    if (data.length === 0) return; // 데이터가 없으면 차트를 그리지 않음

    // Chart dimensions
    const margin = { top: 60, right: 40, bottom: 80, left: 60 }; // Bottom margin 증가
    const width = 600 - margin.left - margin.right;
    const height = 400 - margin.top - margin.bottom;

    // Clear previous chart
    d3.select(svgRef.current).selectAll("*").remove();

    // Create SVG container
    const svg = d3
      .select(svgRef.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom);

    // Create chart group
    const chart = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Compute min and max values for scales
    const xExtent = d3.extent(data, (d) => d.x) as [number, number];
    const yExtent = d3.extent(data, (d) => d.y) as [number, number];

    const xMin = xExtent[0] - (xExtent[1] - xExtent[0]) * 0.1;
    const xMax = xExtent[1] + (xExtent[1] - xExtent[0]) * 0.1;
    const yMin = yExtent[0] - (yExtent[1] - yExtent[0]) * 0.1;
    const yMax = yExtent[1] + (yExtent[1] - yExtent[0]) * 0.1;

    // Create scales
    const xScale = d3.scaleLinear().domain([xMin, xMax]).range([0, width]);
    const yScale = d3.scaleLinear().domain([yMin, yMax]).range([height, 0]);

    // Add X-axis
    chart
      .append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(xScale))
      .selectAll("text")
      .attr("font-size", "12px")
      .attr("text-anchor", "end") // 텍스트 앵커를 끝으로 설정
      .attr("transform", "rotate(-45)") // 45도 회전
      .attr("dx", "-0.5em") // X 위치 조정
      .attr("dy", "0.5em"); // Y 위치 조정

    // Add X-axis label
    chart
      .append("text")
      .attr("x", width / 2)
      .attr("y", height + margin.bottom - 20) // Bottom 여백에 맞게 조정
      .attr("text-anchor", "middle")
      .style("font-size", "14px")
      .style("font-weight", "bold")
      .text(xLabel);

    // Add Y-axis
    chart
      .append("g")
      .call(d3.axisLeft(yScale))
      .selectAll("text")
      .attr("font-size", "12px");

    // Add Y-axis label
    chart
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -height / 2)
      .attr("y", -margin.left + 15)
      .attr("text-anchor", "middle")
      .style("font-size", "14px")
      .style("font-weight", "bold")
      .text(yLabel);

    // Add chart title
    svg
      .append("text")
      .attr("x", (width + margin.left + margin.right) / 2)
      .attr("y", margin.top / 2)
      .attr("text-anchor", "middle")
      .style("font-size", "18px")
      .style("font-weight", "bold")
      .text(chartTitle);

    // Create line generator
    const line = d3
      .line<DataPoint>()
      .x((d) => xScale(d.x))
      .y((d) => yScale(d.y))
      .curve(d3.curveMonotoneX); // Smooth curve

    // Add line path
    chart
      .append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "#007bff")
      .attr("stroke-width", 2)
      .attr("d", line);

    // Add points
    chart
      .selectAll(".dot")
      .data(data)
      .enter()
      .append("circle")
      .attr("class", "dot")
      .attr("cx", (d) => xScale(d.x))
      .attr("cy", (d) => yScale(d.y))
      .attr("r", 4)
      .style("fill", "#007bff");
  }, [chartTitle, xLabel, yLabel, data]);

  return (
    <Box sx={{ padding: "15px", borderRadius: "5px", background: "white" }}>
      <svg ref={svgRef}></svg>
    </Box>
  );
};

export default LineChart;

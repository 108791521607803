import React from "react";
import Root from "./Root";
import { Route, Routes, createBrowserRouter } from "react-router-dom";
import { Template } from "./Pages/Template";
import { MyChatbotList } from "./Pages/MyChatbotList";
import { ChatAsset } from "./Pages/ChatAsset";
import { ChatSkin } from "./Pages/ChatSkin";
import { ChatTest } from "./Pages/ChatTest";
import { ChatBuild } from "./Pages/ChatBuild";
import { ChatFlow } from "./Pages/ChatFlow";
import { ChatMakeAsset } from "./Pages/ChatMakeAsset";
import { Profile } from "./Pages/Profile";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Root />}>
        <Route path="" element={<Template />} />
        <Route path="profile" element={<Profile />} />
        <Route path="myChatbotList" element={<MyChatbotList />} />
        <Route path="chatbotDesign/">
          <Route path="asset" element={<ChatAsset />} />
          <Route path="assetMake" element={<ChatMakeAsset />} />
          <Route path="flow" element={<ChatFlow />} />
          <Route path="skin" element={<ChatSkin />} />
          <Route path="test" element={<ChatTest />} />
          <Route path="build" element={<ChatBuild />} />
        </Route>
      </Route>
    </Routes>
  );
};

const router = createBrowserRouter([
  {
    path: "*",
    element: <AppRouter />,
  },
]);

export default router;

import {
  Box,
  FormControlLabel,
  styled,
  Switch,
  Typography,
} from "@mui/material";

// Styled Components
export const StyledBox = styled(Box)({
  position: "fixed",
  top: "72px",
  left: "20px",
  zIndex: 1000,
  background: "white",
  padding: "20px",
  borderRadius: "5px",
  border: "1px solid #ececec",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
});

export const StyledTypography = styled(Typography)({
  color: "#8f8f8f",
  fontSize: "12px",
});

export const StyledFormControlLabel = styled(FormControlLabel)({
  "& .MuiFormControlLabel-label": {
    color: "#6b6b6b",
    fontSize: "14px",
    fontWeight: "bold",
  },
});

export const StyledSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: process.env.REACT_APP_MAIN_COLOR,
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: process.env.REACT_APP_MAIN_COLOR,
  },
}));

import { atom } from "jotai";
import { isMakingAtom, promptAtom } from "../Atoms/ChatSkinAtom";
import { makeImageByAI } from "../Queries/DokgabiCharacterQueries";
import { userAtom } from "../Atoms/PublicAtom";
import { currentMakerAtom, flowAtom } from "../Atoms/ChatDesignPublicAtom";
import { insertFlow } from "../Queries/DokgabiFlowQueries";
import {
  errorModalAtom,
  successModalAtom,
  waitingModalAtom,
} from "../Atoms/RootAtom";
import { DokgabiCharacterProps } from "../Models/DokgabiFlow";
import { checkUserCharNum } from "../Queries/PlanValidQueries";
import { getCookie } from "../Utils/CookieUtil";
import { handleApiResponse } from "../Utils/APIUtil";
import {
  completeStepAtom,
  currentStepAtom,
  hanldeGuideModalStateAtom,
} from "../Atoms/GuideModalAtom";

export const handlePromptChangeAtom = atom(
  null,
  (
    get,
    set,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    set(promptAtom, event.target.value);
  }
);

export const handleSendPromptAtom = atom(null, async (get, set) => {
  set(isMakingAtom, true);
  const user = get(currentMakerAtom);
  const flow = get(flowAtom);

  if (user && flow) {
    const inputValue = get(promptAtom).trim();
    if (inputValue === "") return;

    const refreshCookie = getCookie(
      process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
    );

    const accessCookie = getCookie(
      process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
    );

    const errorFunction = () => {
      set(errorModalAtom, {
        state: true,
        event: null,
        eventText: "",
        redirectUrl: "",
        text: "캐릭터 생성 회수를 초과했습니다.",
        title: "생성 횟수 초과",
      });
    };

    const successFunction = async (results: any) => {
      const refreshCookie = getCookie(
        process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
      );

      const accessCookie = getCookie(
        process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
      );

      const errorFunction = () => {};

      const successFunction = (results: any) => {
        const { character } = results;

        set(flowAtom, (current) => ({
          ...current,
          character_image: character,
        }));

        set(promptAtom, "");

        setTimeout(() => {
          const guideModalState = get(hanldeGuideModalStateAtom);

          if (guideModalState) {
            set(currentStepAtom, 2);
            set(completeStepAtom, (current) => ({
              ...current,
              1: true,
            }));
          }
        }, 1000);
      };

      const newAccessToken = await handleApiResponse(
        refreshCookie,
        set,
        () =>
          makeImageByAI(
            accessCookie ?? "",
            inputValue,
            user === "admin" ? "admin" : user,
            flow.id
          ),
        () => errorFunction(),
        (results) => successFunction(results)
      );

      if (newAccessToken) {
        await handleApiResponse(
          null,
          set,
          () =>
            makeImageByAI(
              newAccessToken,
              inputValue,
              user === "admin" ? "admin" : user,
              flow.id
            ),
          () => errorFunction(),
          (results) => successFunction(results)
        );
      }
    };

    const newAccessToken = await handleApiResponse(
      refreshCookie,
      set,
      () =>
        user === "admin" ? true : checkUserCharNum(accessCookie ?? "", user),
      () => errorFunction(),
      (results) => successFunction(results)
    );

    if (newAccessToken) {
      await handleApiResponse(
        null,
        set,
        () =>
          user === "admin"
            ? true
            : checkUserCharNum(newAccessToken ?? "", user),
        () => errorFunction(),
        (results) => successFunction(results)
      );
    }
  }

  set(isMakingAtom, false);
});

export const handlePromptKeyDownAtom = atom(
  null,
  async (
    get,
    set,
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.key === "Enter" && !event.shiftKey) {
      set(isMakingAtom, true);
      const user = get(currentMakerAtom);
      const flow = get(flowAtom);

      if (user && flow) {
        const inputValue = get(promptAtom).trim();
        if (inputValue === "") return;

        const refreshCookie = getCookie(
          process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
        );

        const accessCookie = getCookie(
          process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
        );

        const errorFunction = () => {
          set(errorModalAtom, {
            state: true,
            event: null,
            eventText: "",
            redirectUrl: "",
            text: "캐릭터 생성 회수를 초과했습니다.",
            title: "생성 횟수 초과",
          });
        };

        const successFunction = async (results: any) => {
          const refreshCookie = getCookie(
            process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
          );

          const accessCookie = getCookie(
            process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
          );

          const errorFunction = () => {};

          const successFunction = (results: any) => {
            const { character } = results;

            set(flowAtom, (current) => ({
              ...current,
              character_image: character,
            }));

            set(promptAtom, "");

            setTimeout(() => {
              const guideModalState = get(hanldeGuideModalStateAtom);

              if (guideModalState) {
                set(currentStepAtom, 2);
                set(completeStepAtom, (current) => ({
                  ...current,
                  1: true,
                }));
              }
            }, 1000);
          };

          const newAccessToken = await handleApiResponse(
            refreshCookie,
            set,
            () =>
              makeImageByAI(
                accessCookie ?? "",
                inputValue,
                user === "admin" ? "admin" : user,
                flow.id
              ),
            () => errorFunction(),
            (results) => successFunction(results)
          );

          if (newAccessToken) {
            await handleApiResponse(
              null,
              set,
              () =>
                makeImageByAI(
                  newAccessToken,
                  inputValue,
                  user === "admin" ? "admin" : user,
                  flow.id
                ),
              () => errorFunction(),
              (results) => successFunction(results)
            );
          }
        };

        const newAccessToken = await handleApiResponse(
          refreshCookie,
          set,
          () =>
            user === "admin"
              ? true
              : checkUserCharNum(accessCookie ?? "", user),
          () => errorFunction(),
          (results) => successFunction(results)
        );

        if (newAccessToken) {
          await handleApiResponse(
            null,
            set,
            () =>
              user === "admin"
                ? true
                : checkUserCharNum(newAccessToken ?? "", user),
            () => errorFunction(),
            (results) => successFunction(results)
          );
        }
      }

      set(isMakingAtom, false);
    }
  }
);

export const onSaveImageAtom = atom(
  null,
  async (get, set, item: DokgabiCharacterProps) => {
    const flow = get(flowAtom);

    const guideModalState = get(hanldeGuideModalStateAtom);

    if (guideModalState) {
      set(flowAtom, (current) => ({
        ...current,
        character_image: item,
      }));

      set(currentStepAtom, 2);
      set(completeStepAtom, (current) => ({
        ...current,
        1: true,
      }));
    } else {
      flow.character_image = item;

      set(waitingModalAtom, {
        state: true,
        text: "저장하는 중",
      });

      const refreshCookie = getCookie(
        process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
      );

      const accessCookie = getCookie(
        process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
      );

      const errorFunction = () => {
        set(waitingModalAtom, {
          state: false,
          text: "저장하는 중",
        });
        set(errorModalAtom, {
          state: true,
          event: null,
          eventText: "",
          redirectUrl: "",
          text: "저장 실패하였습니다.",
          title: "저장 실패",
        });
      };

      const successFunction = (result: any) => {
        const { flow } = result;
        set(flowAtom, flow);

        set(waitingModalAtom, {
          state: false,
          text: "저장하는 중",
        });

        set(successModalAtom, {
          state: true,
          event: null,
          eventText: "",
          redirectUrl: "",
          text: "저장 성공하였습니다.",
          title: "저장 성공",
        });
      };

      const newAccessToken = await handleApiResponse(
        refreshCookie,
        set,
        () => insertFlow(accessCookie ?? "", flow),
        () => errorFunction(),
        (results) => successFunction(results)
      );

      if (newAccessToken) {
        await handleApiResponse(
          null,
          set,
          () => insertFlow(accessCookie ?? "", flow),
          () => errorFunction(),
          (results) => successFunction(results)
        );
      }
    }
  }
);

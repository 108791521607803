import { Box, Stack } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { ChatInput } from "../Components/ChatTest/ChatInput";
import { useAtom } from "jotai";
import { ChattingAtom } from "../Atoms/ChatTestAtom";
import { Chatting } from "../Components/ChatTest/Chatting";
import { handleInitializeAtom } from "../ViewModels/ChatTestViewModel";

export function ChatTest() {
  const [chatting] = useAtom(ChattingAtom);
  const [, handleInitialize] = useAtom(handleInitializeAtom);

  // 스크롤을 위한 ref 생성
  const boxRef = useRef<HTMLDivElement | null>(null);
  const bottomRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (chatting.length === 0) {
      handleInitialize();
    }
  }, []);

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [chatting]);

  return (
    <Box
      sx={{
        height: "calc(100vh - 56px)",
        maxHeight: "calc(100vh - 56px)",
        display: "flex",
      }}
    >
      <Box sx={{ flex: 1, background: "#ececec" }}></Box>
      <Stack
        sx={{
          flex: 3,
        }}
      >
        <Box
          ref={boxRef}
          sx={{
            display: "flex",
            justifyContent: "center",
            flex: 6,
            overflowY: "auto",
            margin: "20px",
            boxSizing: "border-box",
          }}
        >
          <Stack sx={{ width: "100%", gap: "40px" }}>
            {chatting.map((chat, index) => (
              <Chatting
                key={`chatting-${index}`}
                name={chat.name}
                image={chat.image}
                text={chat.text}
                date={chat.date}
                chattingUser={chat.chattingUser}
                type={chat.type}
                data={chat.data}
              />
            ))}
            <div ref={bottomRef} />
          </Stack>
        </Box>
        <Stack
          sx={{
            width: "100%",
            background: "rgba(255, 255, 255,0)",
            bottom: "0",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            height: "56px",
          }}
        >
          <Stack
            sx={{
              width: "90%",
              height: "100%",
              marginBottom: "30px",
              alignItems: "end",
              justifyContent: "center",
              gap: "5px",
            }}
            direction="row"
          >
            <ChatInput />
          </Stack>
        </Stack>
      </Stack>
      <Box sx={{ flex: 1, background: "#ececec" }}></Box>
    </Box>
  );
}

import React, { useEffect } from "react";
import { useAtom } from "jotai";
import { handleNodeSettingWhenInFlowUpdateAtom } from "../../../../../ViewModels/ChatFlowViewModel";
import * as S from "../styled";
import { sortedModelOptions } from "../../../../../Initialize/CustomNodeOptions";
import { BasicLLMNodeProps } from "../../../../../Props/CustomNodeProps";
import { Box, Typography } from "@mui/material";

export function BasicLLMNodeInFlowSetting({ data }: BasicLLMNodeProps) {
  const [, handleNodeUpdate] = useAtom(handleNodeSettingWhenInFlowUpdateAtom);

  if (data === null) return null;

  return (
    <>
      <S.CustomAutoComplete
        value={data.model_name.view_value}
        options={sortedModelOptions.map((value) => value.view_value)}
        onChange={(event, value) => {
          if (value)
            handleNodeUpdate(
              sortedModelOptions.find((val) => val.view_value === value),
              "model_name",
              data.id
            );
        }}
        renderInput={(params) => (
          <S.CustomTextField
            {...params}
            label="사용할 AI 모델을 선택해주세요."
          />
        )}
      />
      <S.CustomTextField
        value={data.api_key}
        placeholder="Open AI API-KEY 입력"
        onChange={(event) =>
          handleNodeUpdate(event.target.value, "api_key", data.id)
        }
      />

      <S.CustomTextField
        value={data.prompt}
        placeholder={`프롬프트 입력
예시) 사용자 입력에 답변해주고, 감사합니다 를 붙여서 출력해줘.`}
        onChange={(event) =>
          handleNodeUpdate(event.target.value, "prompt", data.id)
        }
        minRows={8}
        maxRows={8}
        multiline
      />
    </>
  );
}

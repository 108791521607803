import { atom } from "jotai";
import { tempNicknameFieldAtom } from "../Atoms/ProfileAtom";
import { userAtom } from "../Atoms/PublicAtom";
import { updateNickname, uploadUserImage } from "../Queries/UserInfoQueries";
import { errorModalAtom } from "../Atoms/RootAtom";
import { handleApiResponse } from "../Utils/APIUtil";
import { getCookie } from "../Utils/CookieUtil";

export const whenPageComeAtom = atom(
  (get) => get(tempNicknameFieldAtom),
  (get, set) => {
    const user = get(userAtom);
    set(tempNicknameFieldAtom, user ? user?.nickname : "");
  }
);

export const whenNicknameChangeAtom = atom(null, (get, set, value: string) =>
  set(tempNicknameFieldAtom, value)
);

export const onSubmitNicknameAtom = atom(null, async (get, set) => {
  const tempNickname = get(tempNicknameFieldAtom);
  const user = get(userAtom);

  if (user) {
    const refreshCookie = getCookie(
      process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
    );

    const accessCookie = getCookie(
      process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
    );

    const errorFunction = () => {
      set(errorModalAtom, {
        state: true,
        event: null,
        eventText: "",
        redirectUrl: "",
        title: "네트워크 오류",
        text: "저장 버튼을 다시 한번 더 눌러주세요.",
      });
    };

    const successFunction = (results: any) => {
      set(
        userAtom,
        (current) =>
          current && {
            ...current,
            nickname: tempNickname,
          }
      );
    };

    const newAccessToken = await handleApiResponse(
      refreshCookie,
      set,
      () => updateNickname(accessCookie ?? "", user.idx, tempNickname),
      () => errorFunction(),
      (results) => successFunction(results)
    );

    if (newAccessToken) {
      await handleApiResponse(
        null,
        set,
        () => updateNickname(newAccessToken ?? "", user.idx, tempNickname),
        () => errorFunction(),
        (results) => successFunction(results)
      );
    }
  } else {
    set(errorModalAtom, {
      state: true,
      event: null,
      eventText: "",
      redirectUrl: "",
      title: "유저 정보 오류",
      text: "새로고침하여 유저 정보를 갱신해주세요.",
    });
  }
});

export const handleImageChangeAtom = atom(
  null,
  async (get, set, event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const user = get(userAtom);
    if (file && user) {
      const refreshCookie = getCookie(
        process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
      );

      const accessCookie = getCookie(
        process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
      );

      const errorFunction = () => {
        set(errorModalAtom, {
          state: true,
          event: null,
          eventText: "",
          redirectUrl: "",
          title: "네트워크 오류",
          text: "프로필 이미지를 다시 설정해 주세요.",
        });
      };

      const successFunction = (results: any) => {
        set(
          userAtom,
          (current) =>
            current && {
              ...current,
              image: `${results.file_path}`,
            }
        );
      };

      const newAccessToken = await handleApiResponse(
        refreshCookie,
        set,
        () => uploadUserImage(accessCookie ?? "", user.idx, file),
        () => errorFunction(),
        (results) => successFunction(results)
      );

      if (newAccessToken) {
        await handleApiResponse(
          null,
          set,
          () => uploadUserImage(newAccessToken ?? "", user.idx, file),
          () => errorFunction(),
          (results) => successFunction(results)
        );
      }
    } else {
      set(errorModalAtom, {
        state: true,
        event: null,
        eventText: "",
        redirectUrl: "",
        title: "유저 정보 오류",
        text: "새로고침하여 유저 정보를 갱신해주세요.",
      });
    }
  }
);

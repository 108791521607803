import React, { useState } from "react";

import { Avatar, Divider, IconButton, Stack, Typography } from "@mui/material";
import { Handle, Position, useReactFlow } from "@xyflow/react";
import ForumIcon from "@mui/icons-material/Forum";
import Lottie from "react-lottie";
import LottieData from "../Animation/maincolor.json";
import * as S from "../styled";
import { useAtom } from "jotai";
import {
  handleHideStateAtom,
  handleNodeClickAtom,
  onNodeCopyAtom,
} from "../../../../ViewModels/ChatFlowViewModel";
import { RAGbaseLLMNodeProps } from "../../../../Props/CustomNodeProps";
import { RagLLMNodeInFlowSetting } from "../../SettingBar/CustomNodeSetting/RagLLMNodeInFlowSetting";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ContentCopy } from "@mui/icons-material";
import { errorIdListAtom } from "../../../../Atoms/ChatDesignPublicAtom";

export function RagLLMNode({ data }: RAGbaseLLMNodeProps) {
  const [currentEditingNode, setCurrentEditingNode] =
    useAtom(handleNodeClickAtom);

  const [, onNodeCopy] = useAtom(onNodeCopyAtom);
  const [, handleHideState] = useAtom(handleHideStateAtom);

  const [errorIdList] = useAtom(errorIdListAtom);

  const { screenToFlowPosition } = useReactFlow();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <S.NodeBox
      isclick={currentEditingNode?.data.id === data.id ? "true" : "false"}
      iserror={
        errorIdList.some((value) => value === data.id) ? "true" : "false"
      }
    >
      <Stack
        direction={"row"}
        sx={{
          alignItems: "center",
          gap: "10px",
          justifyContent: "space-between",
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Avatar sx={{ background: process.env.REACT_APP_MAIN_COLOR }}>
            <ForumIcon />
          </Avatar>
          <Typography>Asset Base AI</Typography>
          <IconButton
            onClick={() => onNodeCopy(data, screenToFlowPosition, "ragLLM")}
          >
            <ContentCopy />
          </IconButton>
        </Stack>
        <IconButton onClick={() => handleHideState(data.id)}>
          {data.hide ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
        </IconButton>
      </Stack>

      <Handle
        type="target"
        position={Position.Left}
        id={`${data.id}-left`}
        className="handle-left"
      />
      <Handle
        type="source"
        position={Position.Right}
        id={`${data.id}-right`}
        className="handle-right"
      />
      {data.hide ? null : (
        <>
          <Divider></Divider>
          <RagLLMNodeInFlowSetting data={data} />

          {currentEditingNode?.data.id === data.id ? (
            <Lottie
              style={{ width: "100%", marginTop: "20px" }}
              options={defaultOptions}
              height={50}
              isClickToPauseDisabled={true}
            />
          ) : (
            <S.NodeButton
              variant="contained"
              onClick={() => setCurrentEditingNode(data.id)}
            >
              큰 화면에서 설정하기
            </S.NodeButton>
          )}
        </>
      )}
    </S.NodeBox>
  );
}

import React, { useEffect, useRef } from "react";
import * as pdfjsLib from "pdfjs-dist";
import "pdfjs-dist/build/pdf.worker.entry";
import { Box, Divider, Typography } from "@mui/material";
import { useAtom } from "jotai";
import { errorModalAtom } from "../../Atoms/RootAtom";

interface PdfViewerProps {
  filePath: string;
  pageNum: number;
}

const PdfViewer = ({ filePath, pageNum }: PdfViewerProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [, setErrorModal] = useAtom(errorModalAtom);

  useEffect(() => {
    const renderPage = async () => {
      try {
        const loadingTask = pdfjsLib.getDocument(filePath);
        const pdf = await loadingTask.promise;

        // Ensure the page number is within valid bounds
        const validPageNum = Math.min(Math.max(pageNum, 1), pdf.numPages);

        const page = await pdf.getPage(validPageNum);

        const viewport = page.getViewport({ scale: 1 });
        const canvas = canvasRef.current;
        const context = canvas?.getContext("2d");

        if (canvas && context) {
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };

          await page.render(renderContext).promise;
        }
      } catch (error) {
        setErrorModal({
          state: true,
          event: null,
          eventText: "",
          redirectUrl: "",
          text: "페이지 랜더링에 실패 했습니다.\n다시 선택해주세요.",
          title: "페이지 랜더링 실패",
        });
      }
    };

    renderPage();
  }, [filePath, pageNum]); // Re-render when filePath or pageNum changes

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #8f8f8f",
        borderRadius: "5px",
        width: "100%",
        height: "1000px",
        flexDirection: "column",
        background: "white",
      }}
    >
      <Typography
        sx={{
          color: "#8f8f8f",
          textAlign: "start",
          width: "90%",
        }}
      >
        원본 문서
      </Typography>
      {/* Always render the canvas, even if PDF fails to load */}
      <canvas
        ref={canvasRef}
        style={{
          width: "100%",
          height: "calc(100% - 50px)",
          objectFit: "contain",
        }}
      />
    </Box>
  );
};

export default PdfViewer;

import {
  Autocomplete,
  Button,
  FormControlLabel,
  styled,
  Switch,
  TextField,
} from "@mui/material";

export const CustomAutoComplete = styled(Autocomplete)({
  width: "100%",
  marginTop: "20px",
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: process.env.REACT_APP_MAIN_COLOR,
    },
  },
});

export const CustomTextField = styled(TextField)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: process.env.REACT_APP_MAIN_COLOR,
    },
  },

  "& .MuiInputLabel-root": {
    "&.Mui-focused": {
      color: process.env.REACT_APP_MAIN_COLOR,
    },
  },
});

export const CustomButton = styled(Button)({
  width: "100%",
  color: "white",
  background: process.env.REACT_APP_MAIN_COLOR,
  "&:hover": {
    background: process.env.REACT_APP_MAIN_COLOR,
  },
});

export const StyledFormControlLabel = styled(FormControlLabel)({
  "& .MuiFormControlLabel-label": {
    color: "#6b6b6b",
    fontSize: "14px",
    fontWeight: "bold",
  },
});

export const StyledSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: process.env.REACT_APP_MAIN_COLOR,
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: process.env.REACT_APP_MAIN_COLOR,
  },
}));

export async function checkOpenAIAPIKey(apiKey: string): Promise<boolean> {
  const url = "https://api.openai.com/v1/models";
  const headers = {
    Authorization: `Bearer ${apiKey}`,
  };

  try {
    const response = await fetch(url, { headers });

    if (response.ok) {
      return true;
    } else if (response.status === 401) {
      return false;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}

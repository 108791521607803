import { Box, styled } from "@mui/material";

export const FileUploadInnerBox = styled(Box)({
  width: "100%",
  height: "100%",
  padding: "50px",
  boxSizing: "border-box",
  border: "3px dashed #8f8f8f",
  borderRadius: "15px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

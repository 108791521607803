import { atom } from "jotai";
import { GuideType } from "../Enum/GuideModalEnum";
import { WelcomeNodeProps } from "../Props/CustomNodeProps";
import {
  initialDokgabiAsset,
  initializeWelcomeNodeAtom,
} from "../Initialize/ChatFlowInitialize";
import { DokgabiAssetProps } from "../Models/DokgabiFlow";

export const hanldeGuideModalStateAtom = atom(false);
export const hanldeGuideModalTypeAtom = atom(GuideType.NONE);

export const stepAtom = atom<string[]>([]);
export const currentStepAtom = atom(0);
export const completeStepAtom = atom<{
  [k: number]: boolean;
}>({});

export const tempChatbotNameAtom = atom("");
export const tempOpenaiAPIKeyAtom = atom("");

export const guideModalIsDraggingAtom = atom(false);

export const tempWelcomeNodeAtom = atom<WelcomeNodeProps>(
  initializeWelcomeNodeAtom
);

export const tempAssetAtom = atom<DokgabiAssetProps>(initialDokgabiAsset);

import React from "react";
import {
  Background,
  BackgroundVariant,
  Controls,
  MiniMap,
  ReactFlow,
  useReactFlow,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { useAtom } from "jotai";
import { ElementBar } from "../Components/ChatFlow/ElementBar";
import { SettingBar } from "../Components/ChatFlow/SettingBar";
import {
  handleChatbotType2Atom,
  handleChatbotTypeAtom,
  handleIsPublicAtom,
  onConnectAtom,
  onDropAtom,
  onEdgesChangeAtom,
  onFlowComponentDragOverAtom,
  onNodesChangeAtom,
  onNodesDeleteAtom,
} from "../ViewModels/ChatFlowViewModel";
import { WelcomeNode } from "../Components/ChatFlow/CustomNode/WelcomeNode";
import { TargetNode } from "../Components/ChatFlow/CustomNode/TargetNode";
import { TopicNode } from "../Components/ChatFlow/CustomNode/TopicNode";
import { BasicLLMNode } from "../Components/ChatFlow/CustomNode/BasicLLMNode";
import { RagLLMNode } from "../Components/ChatFlow/CustomNode/RagLLMNode";
import { TriggerNode } from "../Components/ChatFlow/CustomNode/TriggerNode";
import { StartNode } from "../Components/ChatFlow/CustomNode/StartNode";
import { Button, Divider, FormGroup } from "@mui/material";
import * as S from "../Components/ChatFlow/styled";
import { userAtom } from "../Atoms/PublicAtom";
import { Add } from "@mui/icons-material";
import { settingModalAtom } from "../Atoms/ChatDesignPublicAtom";
import { MakeJsonNode } from "../Components/ChatFlow/CustomNode/MakeJsonNode";

const nodeTypes = {
  start: StartNode,
  welcome: WelcomeNode,
  target: TargetNode,
  topic: TopicNode,
  basicLLM: BasicLLMNode,
  ragLLM: RagLLMNode,
  makeJson: MakeJsonNode,
  trigger: TriggerNode,
};

export function ChatFlow() {
  const [user] = useAtom(userAtom);
  const [nodes, onNodesChange] = useAtom(onNodesChangeAtom);
  const [edges, onEdgesChange] = useAtom(onEdgesChangeAtom);
  const [, onConnect] = useAtom(onConnectAtom);
  const [, onDragOver] = useAtom(onFlowComponentDragOverAtom);
  const [, onDrop] = useAtom(onDropAtom);
  const [, onNodesDelete] = useAtom(onNodesDeleteAtom);

  const [isPublic, handleIsPublic] = useAtom(handleIsPublicAtom);
  const [chatbotType, handleChatbotType] = useAtom(handleChatbotTypeAtom);
  const [, handleChatbotType2] = useAtom(handleChatbotType2Atom);

  const [settingModal, setSettingModal] = useAtom(settingModalAtom);

  const { screenToFlowPosition } = useReactFlow();

  return (
    <>
      <S.StyledBox>
        <S.StyledTypography>챗봇 설정</S.StyledTypography>
        <Divider />
        <FormGroup>
          <S.StyledFormControlLabel
            control={
              <S.StyledSwitch
                checked={isPublic}
                onChange={(event) => handleIsPublic(event.target.checked)}
              />
            }
            label="챗봇 공개 여부"
          />
          {user?.membership === "admin" && (
            <S.StyledFormControlLabel
              control={
                <S.StyledSwitch
                  checked={chatbotType === "mentor"}
                  onChange={(event) => handleChatbotType(event.target.checked)}
                />
              }
              label="챗봇을 멘토로 설정"
            />
          )}
          {user?.membership === "admin" && (
            <S.StyledFormControlLabel
              control={
                <S.StyledSwitch
                  checked={chatbotType === "template"}
                  onChange={(event) => handleChatbotType2(event.target.checked)}
                />
              }
              label="챗봇을 작성 도우미 설정"
            />
          )}
          {user?.membership === "admin" && (
            <Button
              sx={{
                color: process.env.REACT_APP_MAIN_COLOR,
                "&:hover": {
                  color: process.env.REACT_APP_MAIN_COLOR,
                },
              }}
              onClick={() => setSettingModal(true)}
            >
              <Add />
              추가 설정
            </Button>
          )}
        </FormGroup>
      </S.StyledBox>

      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        onNodesDelete={onNodesDelete}
        onDrop={(event) => onDrop(event, screenToFlowPosition)}
        onDragOver={onDragOver}
        fitView
      >
        <Background
          color="#d8d8d8"
          variant={BackgroundVariant.Lines}
          gap={200}
          style={{ background: "#F2F2F2" }}
        />
        <MiniMap />
        <Controls />
      </ReactFlow>
      <ElementBar />
      <SettingBar />
    </>
  );
}

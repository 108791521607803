import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import * as S from "./Components/Root/styled";
import { useAtom } from "jotai";
import {
  checkCookieAndAutoLoginAtom,
  initializeLandingAppAtom,
  judgeRightRouteForChatbotDesignAtom,
} from "./ViewModels/RootViewModel";
import { Header } from "./Components/Root/Header";
import { userAtom } from "./Atoms/PublicAtom";
import { UserLoginError } from "./Pages/UserLoginError";
import SuccessModal from "./Components/Root/Modal/SuccessModal";
import ErrorModal from "./Components/Root/Modal/ErrorModal";
import WaitingModal from "./Components/Root/Modal/WaitingModal";
import NoticeModal from "./Components/Root/Modal/NoticeModal";
import { ChatbotDesignHeader } from "./Components/ChatbotDesignHeader";
import { NotRightPathError } from "./Pages/NotRightPathError";
import { CustomDrawer } from "./Components/Root/Header/Drawer";
import SettingModal from "./Components/ChatFlow/SettingModal";
import GuideModal from "./Components/Root/Modal/GuideModal";

function Root() {
  const [user] = useAtom(userAtom);
  const [judgeRightRoute, judgeRightRouteForChatbotDesign] = useAtom(
    judgeRightRouteForChatbotDesignAtom
  );
  const [, checkCookieAndAutoLogin] = useAtom(checkCookieAndAutoLoginAtom);
  const [, initializeLandingApp] = useAtom(initializeLandingAppAtom);

  const location = useLocation();

  useEffect(() => {
    checkCookieAndAutoLogin();
    judgeRightRouteForChatbotDesign(location);
    initializeLandingApp();
  }, []);

  return (
    <>
      <SuccessModal />
      <ErrorModal />
      <WaitingModal />
      <NoticeModal />

      {user && judgeRightRoute ? (
        <S.FullContainer>
          <SettingModal />
          <CustomDrawer />
          <GuideModal />

          {location.pathname.split("/")[1] === "chatbotDesign" ? (
            <ChatbotDesignHeader />
          ) : (
            <Header />
          )}

          <S.ContentContainer>
            <Outlet />
          </S.ContentContainer>
        </S.FullContainer>
      ) : user &&
        location.pathname.split("/")[1] === "chatbotDesign" &&
        !judgeRightRoute ? (
        <NotRightPathError />
      ) : (
        <UserLoginError />
      )}
    </>
  );
}

export default Root;

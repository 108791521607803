import { ResponseStatus } from "../Enum/NetworkEnum";

export const updateNickname = async (
  access: string,
  userId: number,
  nickname: string
) => {
  const response = await fetch(
    `${process.env.REACT_APP_USER_API_ADDRESS}/mysql/updateNickname`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${access}`,
      },
      body: JSON.stringify({ id: userId, nickname: nickname }),
    }
  );
  if (response.status === 200) {
    return response;
  } else if (response.status === 401) {
    return ResponseStatus.UNAUTHORIZED;
  } else if (response.status === 403) {
    return ResponseStatus.FORBIDDEN;
  } else {
    return ResponseStatus.ERROR;
  }

  // return response;
};

// upload user image
export const uploadUserImage = async (
  access: string,
  userId: number,
  file: File
) => {
  const formData = new FormData();
  formData.append("id", userId.toString());
  formData.append("profile_image", file);
  const response = await fetch(
    `${process.env.REACT_APP_USER_API_ADDRESS}/mysql/updateImage`,
    {
      method: "POST",
      body: formData,
      headers: {
        // "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${access}`,
      },
    }
  );

  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else if (response.status === 401) {
    return ResponseStatus.UNAUTHORIZED;
  } else if (response.status === 403) {
    return ResponseStatus.FORBIDDEN;
  } else {
    return ResponseStatus.ERROR;
  }
  // const data = await response.json();
  // return data;
};

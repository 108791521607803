import { atom } from "jotai";
import { flowAtom } from "../Atoms/ChatDesignPublicAtom";
import {
  oneExampleEditStateAtom,
  oneTopicEditStateAtom,
} from "../Atoms/ChatFlowAtom";
import { errorModalAtom } from "../Atoms/RootAtom";
import { TopicClassifyNodeDataProps } from "../Props/CustomNodeProps";

// 예제 추가 할 때 쓰는 거
export const handleAddExampleAtom = atom(null, (get, set, id: string) => {
  const flow = get(flowAtom);

  const currentIndex = flow.flow_nodes.findIndex((fl) => fl.data.id === id);

  if (currentIndex !== -1) {
    const exam =
      (
        flow.flow_nodes[currentIndex]
          .data as unknown as TopicClassifyNodeDataProps
      ).temporary_intend?.intend_exam ?? "";

    if (exam === "")
      set(errorModalAtom, {
        state: true,
        event: null,
        eventText: "",
        redirectUrl: "",
        text: "예제에 입력 된게 아무것도 없습니다.",
        title: "입력 없음",
      });
    else {
      set(flowAtom, (current) => {
        const updatedNodes = [...current.flow_nodes];
        const existingNodeData: Record<string, any> =
          updatedNodes[currentIndex].data["temporary_intend"] || {};
        const updatedNode = {
          ...updatedNodes[currentIndex],
          data: {
            ...updatedNodes[currentIndex].data,
            ["temporary_intend"]: {
              ...existingNodeData,
              ["intend_exam_list"]: [
                ...((existingNodeData["intend_exam_list"] as any[]) || []),
                exam,
              ],
              ["intend_exam"]: "",
            },
          },
        };
        updatedNodes[currentIndex] = updatedNode;
        return {
          ...current,
          flow_nodes: updatedNodes,
        };
      });
    }
  }
});

// 예제별 수정 아이콘 버튼 눌렀을 때 쓰는 거
export const handleMakeEditIndexExampleAtom = atom(
  (get) => get(oneExampleEditStateAtom),
  (get, set, index: number, nodeId: string) => {
    // 이미 수정 중인 것이 있다면 그거 없애고 지금 누른 거를 최신으로 만들기
    set(oneExampleEditStateAtom, (current) => {
      if (current !== null) {
        set(flowAtom, (currentFlow) => {
          const currentIndex = currentFlow.flow_nodes.findIndex(
            (fl) => fl.data.id === current.id
          );

          if (currentIndex !== -1) {
            const updatedNodes = [...currentFlow.flow_nodes];
            const existingNodeData = (
              updatedNodes[currentIndex]
                .data as unknown as TopicClassifyNodeDataProps
            ).temporary_intend;

            if (existingNodeData) {
              const updatedNode = {
                ...updatedNodes[currentIndex],
                data: {
                  ...updatedNodes[currentIndex].data,
                  temporary_intend: {
                    ...existingNodeData,
                    intend_exam: "",
                  },
                },
              };
              updatedNodes[currentIndex] = updatedNode;
              return {
                ...currentFlow,
                flow_nodes: updatedNodes,
              };
            } else {
              return currentFlow;
            }
          } else {
            return currentFlow;
          }
        });

        return {
          editIndex: index,
          id: nodeId,
        };
      } else {
        return {
          editIndex: index,
          id: nodeId,
        };
      }
    });

    const flow = get(flowAtom);

    const currentIndex = flow.flow_nodes.findIndex(
      (fl) => fl.data.id === nodeId
    );

    if (currentIndex !== -1) {
      set(flowAtom, (current) => {
        const updatedNodes = [...current.flow_nodes];
        const existingNodeData = (
          updatedNodes[currentIndex]
            .data as unknown as TopicClassifyNodeDataProps
        ).temporary_intend;

        if (existingNodeData) {
          const updatedNode = {
            ...updatedNodes[currentIndex],
            data: {
              ...updatedNodes[currentIndex].data,
              temporary_intend: {
                ...existingNodeData,
                intend_exam: existingNodeData.intend_exam_list[index],
              },
            },
          };
          updatedNodes[currentIndex] = updatedNode;
          return {
            ...current,
            flow_nodes: updatedNodes,
          };
        } else {
          return current;
        }
      });
    }
  }
);

// 예제 수정 버튼 눌렀을 때 쓰는 거
export const handleEditExamAtom = atom(null, (get, set, nodeId: string) => {
  const editIndex = get(oneExampleEditStateAtom)?.editIndex;
  const flow = get(flowAtom);

  const currentIndex = flow.flow_nodes.findIndex((fl) => fl.data.id === nodeId);

  if (currentIndex !== -1) {
    const exam =
      (
        flow.flow_nodes[currentIndex]
          .data as unknown as TopicClassifyNodeDataProps
      ).temporary_intend?.intend_exam ?? "";

    if (exam === "")
      set(errorModalAtom, {
        state: true,
        event: null,
        eventText: "",
        redirectUrl: "",
        text: "예제에 입력 된게 아무것도 없습니다.",
        title: "입력 없음",
      });
    else {
      set(flowAtom, (current) => {
        const updatedNodes = [...current.flow_nodes];
        const existingNodeData = (
          updatedNodes[currentIndex]
            .data as unknown as TopicClassifyNodeDataProps
        ).temporary_intend;

        if (existingNodeData) {
          const updatedNode = {
            ...updatedNodes[currentIndex],
            data: {
              ...updatedNodes[currentIndex].data,
              temporary_intend: {
                ...existingNodeData,
                intend_exam_list: existingNodeData.intend_exam_list.map(
                  (value, index) => {
                    if (index === editIndex)
                      return existingNodeData.intend_exam;
                    else return value;
                  }
                ),
                intend_exam: "",
              },
            },
          };
          updatedNodes[currentIndex] = updatedNode;
          return {
            ...current,
            flow_nodes: updatedNodes,
          };
        } else {
          return current;
        }
      });

      set(oneExampleEditStateAtom, null);
    }
  }
});

// 예제별 삭제 아이콘 버튼 눌렀을 때 쓰는 거
export const handleMakeDeleteIndexExampleAtom = atom(
  null,
  (get, set, deleteIndex: number, nodeId: string) => {
    const currentEditingIndex = get(oneExampleEditStateAtom)?.editIndex;
    const currentEditingId = get(oneExampleEditStateAtom)?.id;
    const flow = get(flowAtom);

    const currentIndex = flow.flow_nodes.findIndex(
      (fl) => fl.data.id === nodeId
    );

    if (currentIndex !== -1) {
      set(flowAtom, (current) => {
        const updatedNodes = [...current.flow_nodes];
        const existingNodeData = (
          updatedNodes[currentIndex]
            .data as unknown as TopicClassifyNodeDataProps
        ).temporary_intend;

        if (existingNodeData) {
          const updatedNode = {
            ...updatedNodes[currentIndex],
            data: {
              ...updatedNodes[currentIndex].data,
              temporary_intend: {
                ...existingNodeData,
                intend_exam_list: existingNodeData.intend_exam_list.filter(
                  (value, index) => index !== deleteIndex
                ),
                intend_exam:
                  currentEditingIndex === deleteIndex
                    ? ""
                    : existingNodeData.intend_exam,
              },
            },
          };
          updatedNodes[currentIndex] = updatedNode;
          return {
            ...current,
            flow_nodes: updatedNodes,
          };
        } else {
          return current;
        }
      });

      if (currentEditingIndex === deleteIndex && currentEditingId === nodeId)
        set(oneExampleEditStateAtom, null);
    }
  }
);

// 토픽 추가 버튼 눌렀을 때 쓰는 거
export const handleAddTopicAtom = atom(null, (get, set, nodeId: string) => {
  const flow = get(flowAtom);

  const currentIndex = flow.flow_nodes.findIndex((fl) => fl.data.id === nodeId);

  if (currentIndex !== -1) {
    const currentNodes = flow.flow_nodes[currentIndex];
    const currentTopic = (
      currentNodes.data as unknown as TopicClassifyNodeDataProps
    ).temporary_intend;

    if (currentTopic) {
      const sameName = (
        currentNodes.data as unknown as TopicClassifyNodeDataProps
      ).intend_list.find(
        (value, index) => value.intend_name === currentTopic.intend_name
      );

      if (currentTopic.intend_name === "")
        set(errorModalAtom, {
          state: true,
          event: null,
          eventText: "",
          redirectUrl: "",
          text: "이름을 입력해주세요.",
          title: "이름 없음",
        });
      else if (currentTopic.intend_desc === "")
        set(errorModalAtom, {
          state: true,
          event: null,
          eventText: "",
          redirectUrl: "",
          text: "설명에 입력 된게 아무것도 없습니다.",
          title: "설명 없음",
        });
      else if (currentTopic.intend_exam_list.length < 1)
        set(errorModalAtom, {
          state: true,
          event: null,
          eventText: "",
          redirectUrl: "",
          text: "하나 이상의 예제가 필요합니다.",
          title: "예제 없음",
        });
      else if (sameName !== undefined)
        set(errorModalAtom, {
          state: true,
          event: null,
          eventText: "",
          redirectUrl: "",
          text: "중복된 이름으로 된 주제가 있습니다.",
          title: "주제 이름 중복",
        });
      else {
        set(flowAtom, (current) => {
          const updatedNodes = [...current.flow_nodes];
          const updatedNode = {
            ...updatedNodes[currentIndex],
            data: {
              ...updatedNodes[currentIndex].data,
              intend_list: [
                {
                  intend_name: currentTopic.intend_name,
                  intend_desc: currentTopic.intend_desc,
                  intend_exam: currentTopic.intend_exam_list,
                },
                ...(
                  updatedNodes[currentIndex]
                    .data as unknown as TopicClassifyNodeDataProps
                ).intend_list,
              ],
              temporary_intend: {
                intend_name: "",
                intend_desc: "",
                intend_exam: "",
                intend_exam_list: [],
              },
            },
          };
          updatedNodes[currentIndex] = updatedNode;

          return {
            ...current,
            flow_nodes: updatedNodes,
          };
        });
      }
    } else {
      set(errorModalAtom, {
        state: true,
        event: null,
        eventText: "",
        redirectUrl: "",
        text: "네트워크 오류가 났습니다.\n잠시만 뒤에 시도해 주세요.",
        title: "네트워크 오류",
      });
    }
  } else {
    set(errorModalAtom, {
      state: true,
      event: null,
      eventText: "",
      redirectUrl: "",
      text: "네트워크 오류가 났습니다.\n잠시만 뒤에 시도해 주세요.",
      title: "네트워크 오류",
    });
  }
});

// 토픽별 수정 아이콘 버튼 눌렀을 때 쓰는 거
export const handleMakeEditTopicAtom = atom(
  (get) => get(oneTopicEditStateAtom),
  (get, set, index: number, nodeId: string) => {
    set(oneExampleEditStateAtom, null);

    const flow = get(flowAtom);

    // 노드의 인덱스 찾기
    const currentIndex = flow.flow_nodes.findIndex(
      (fl) => fl.data.id === nodeId
    );

    // 수정할 노드가 존재할 경우
    if (currentIndex !== -1) {
      const existingNodeData = (
        flow.flow_nodes[currentIndex]
          .data as unknown as TopicClassifyNodeDataProps
      ).intend_list[index];

      // "기타" 조건 먼저 확인
      if (existingNodeData && existingNodeData.intend_name === "기타") {
        set(errorModalAtom, {
          state: true,
          event: null,
          eventText: "",
          redirectUrl: "",
          title: "수정 불가",
          text: "기타로 정해놓은 주제는 수정이 안됩니다.",
        });
        return; // "기타"일 경우 함수 종료
      }

      // "기타"가 아닐 경우에만 수정 진행
      set(flowAtom, (current) => {
        const updatedNodes = [...current.flow_nodes];
        const updatedNode = {
          ...updatedNodes[currentIndex],
          data: {
            ...updatedNodes[currentIndex].data,
            temporary_intend: {
              intend_name: existingNodeData.intend_name,
              intend_desc: existingNodeData.intend_desc,
              intend_exam_list: existingNodeData.intend_exam,
              intend_exam: "",
            },
          },
        };
        updatedNodes[currentIndex] = updatedNode;
        return {
          ...current,
          flow_nodes: updatedNodes,
        };
      });
    }

    // 현재 편집 상태 업데이트
    set(oneTopicEditStateAtom, (current) => {
      return {
        editIndex: index,
        id: nodeId,
      };
    });
  }
);

// 토픽 수정 버튼 눌렀을 때 쓰는 거
export const handleEditTopicAtom = atom(null, (get, set, nodeId: string) => {
  set(oneExampleEditStateAtom, null);
  const flow = get(flowAtom);
  const editTopicIndex = get(oneTopicEditStateAtom)?.editIndex;

  const currentIndex = flow.flow_nodes.findIndex((fl) => fl.data.id === nodeId);

  if (currentIndex !== -1 && editTopicIndex !== null) {
    const currentNodes = flow.flow_nodes[currentIndex];
    const editTopic = (
      currentNodes.data as unknown as TopicClassifyNodeDataProps
    ).temporary_intend;

    if (editTopic) {
      const sameName = (
        currentNodes.data as unknown as TopicClassifyNodeDataProps
      ).intend_list.find(
        (value, index) =>
          editTopicIndex !== index &&
          value.intend_name === editTopic.intend_name
      );

      if (editTopic.intend_name === "")
        set(errorModalAtom, {
          state: true,
          event: null,
          eventText: "",
          redirectUrl: "",
          text: "이름을 입력해주세요.",
          title: "이름 없음",
        });
      else if (editTopic.intend_desc === "")
        set(errorModalAtom, {
          state: true,
          event: null,
          eventText: "",
          redirectUrl: "",
          text: "설명에 입력 된게 아무것도 없습니다.",
          title: "설명 없음",
        });
      else if (editTopic.intend_exam_list.length < 1)
        set(errorModalAtom, {
          state: true,
          event: null,
          eventText: "",
          redirectUrl: "",
          text: "하나 이상의 예제가 필요합니다.",
          title: "예제 없음",
        });
      else if (sameName !== undefined)
        set(errorModalAtom, {
          state: true,
          event: null,
          eventText: "",
          redirectUrl: "",
          text: "중복된 이름으로 된 주제가 있습니다.",
          title: "주제 이름 중복",
        });
      else {
        set(flowAtom, (current) => {
          const updatedNodes = [...current.flow_nodes];
          const updatedNode = {
            ...updatedNodes[currentIndex],
            data: {
              ...updatedNodes[currentIndex].data,
              intend_list: (
                updatedNodes[currentIndex]
                  .data as unknown as TopicClassifyNodeDataProps
              ).intend_list.map((value, index) => {
                if (index === editTopicIndex) {
                  return {
                    intend_name: editTopic.intend_name,
                    intend_desc: editTopic.intend_desc,
                    intend_exam: editTopic.intend_exam_list,
                  };
                } else {
                  return value;
                }
              }),
              temporary_intend: {
                intend_name: "",
                intend_desc: "",
                intend_exam: "",
                intend_exam_list: [],
              },
            },
          };
          updatedNodes[currentIndex] = updatedNode;

          return {
            ...current,
            flow_nodes: updatedNodes,
          };
        });

        set(oneTopicEditStateAtom, null);
      }
    } else {
      set(errorModalAtom, {
        state: true,
        event: null,
        eventText: "",
        redirectUrl: "",
        text: "네트워크 오류가 났습니다.",
        title: "네트워크 오류",
      });
    }
  } else {
    set(errorModalAtom, {
      state: true,
      event: null,
      eventText: "",
      redirectUrl: "",
      text: "네트워크 오류가 났습니다.\n잠시만 뒤에 시도해 주세요.",
      title: "네트워크 오류",
    });
  }
});

// 토픽별 삭제 아이콘 버튼 눌렀을 때 쓰는 거
export const handleDeleteTopicAtom = atom(
  null,
  (get, set, deleteIndex: number, nodeId: string) => {
    const currentEditingIndex = get(oneTopicEditStateAtom)?.editIndex;
    const currentEditingId = get(oneTopicEditStateAtom)?.id;
    const flow = get(flowAtom);

    const currentIndex = flow.flow_nodes.findIndex(
      (fl) => fl.data.id === nodeId
    );

    if (currentIndex !== -1) {
      set(flowAtom, (current) => {
        const updatedNodes = [...current.flow_nodes];
        const existingNodeData = updatedNodes[currentIndex]
          .data as unknown as TopicClassifyNodeDataProps;

        if (existingNodeData) {
          if (
            existingNodeData.intend_list[deleteIndex].intend_name === "기타"
          ) {
            set(errorModalAtom, {
              state: true,
              event: null,
              eventText: "",
              redirectUrl: "",
              title: "삭제 불가",
              text: "기타로 정해놓은 주제는 삭제가 안됩니다.",
            });

            return current;
          } else {
            const updatedNode = {
              ...updatedNodes[currentIndex],
              data: {
                ...updatedNodes[currentIndex].data,
                intend_list: existingNodeData.intend_list.filter(
                  (value, index) => index !== deleteIndex
                ),
                temporary_intend:
                  currentEditingIndex === deleteIndex
                    ? {
                        intend_name: "",
                        intend_exam: "",
                        intend_desc: "",
                        intend_exam_list: [],
                      }
                    : existingNodeData.temporary_intend,
              },
            };
            updatedNodes[currentIndex] = updatedNode;
            return {
              ...current,
              flow_nodes: updatedNodes,
            };
          }
        } else {
          return current;
        }
      });

      if (currentEditingIndex === deleteIndex && currentEditingId === nodeId) {
        set(oneExampleEditStateAtom, null);
        set(oneTopicEditStateAtom, null);
      }
    }
  }
);

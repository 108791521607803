import React, { useState } from "react";

import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Handle, Position, useReactFlow } from "@xyflow/react";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import Lottie from "react-lottie";
import LottieData from "../Animation/maincolor.json";
import * as S from "../styled";
import { useAtom } from "jotai";
import {
  handleHideStateAtom,
  handleNodeClickAtom,
  onNodeCopyAtom,
} from "../../../../ViewModels/ChatFlowViewModel";
import { TopicClassifyNodeProps } from "../../../../Props/CustomNodeProps";
import { TopicNodeInFlowSetting } from "../../SettingBar/CustomNodeSetting/TopicNodeInFlowSetting";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  handleDeleteTopicAtom,
  handleMakeEditTopicAtom,
} from "../../../../ViewModels/ChatFlowTopicSettingViewModel";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ContentCopy } from "@mui/icons-material";
import { errorIdListAtom } from "../../../../Atoms/ChatDesignPublicAtom";

export function TopicNode({ data }: TopicClassifyNodeProps) {
  const [currentEditingNode, setCurrentEditingNode] =
    useAtom(handleNodeClickAtom);

  const [editTopicIndex, handleMakeEditTopic] = useAtom(
    handleMakeEditTopicAtom
  );
  const [, handleDeleteTopic] = useAtom(handleDeleteTopicAtom);

  const [, onNodeCopy] = useAtom(onNodeCopyAtom);

  const [, handleHideState] = useAtom(handleHideStateAtom);

  const [errorIdList] = useAtom(errorIdListAtom);

  const { screenToFlowPosition } = useReactFlow();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Stack
      sx={{
        gap: "20px",
      }}
    >
      <S.NodeBox
        id={`${data.id}`}
        isclick={currentEditingNode?.data.id === data.id ? "true" : "false"}
        iserror={
          errorIdList.some((value) => value === data.id) ? "true" : "false"
        }
      >
        <Handle
          type="target"
          position={Position.Left}
          id={`${data.id}-left`}
          className="handle-left"
        />
        <Stack
          direction={"row"}
          sx={{
            alignItems: "center",
            gap: "10px",
            justifyContent: "space-between",
          }}
        >
          <Stack
            direction={"row"}
            sx={{
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Avatar sx={{ background: process.env.REACT_APP_MAIN_COLOR }}>
              <AccountTreeIcon />
            </Avatar>
            <Typography>Topic</Typography>
            <IconButton
              onClick={() => onNodeCopy(data, screenToFlowPosition, "topic")}
            >
              <ContentCopy />
            </IconButton>
          </Stack>

          <IconButton onClick={() => handleHideState(data.id)}>
            {data.hide ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </IconButton>
        </Stack>

        {data.hide ? null : (
          <>
            <Divider></Divider>
            <TopicNodeInFlowSetting data={data} />

            {currentEditingNode?.data.id === data.id ? (
              <Lottie
                style={{ width: "100%", marginTop: "20px" }}
                options={defaultOptions}
                height={50}
                isClickToPauseDisabled={true}
              />
            ) : (
              <S.NodeButton
                variant="contained"
                onClick={() => setCurrentEditingNode(data.id)}
              >
                큰 화면에서 설정하기
              </S.NodeButton>
            )}
          </>
        )}
      </S.NodeBox>

      {data.intend_list.map((topic, index) => (
        <S.ChildrenBox
          key={`${data.id}-${index}`}
          id={`${data.id}-${index}`}
          isclick={currentEditingNode?.data.id === data.id ? "true" : "false"}
          iserror={
            errorIdList.some((value) => value === data.id) ? "true" : "false"
          }
        >
          <Typography sx={{ flex: 3 }}>{topic.intend_name}</Typography>
          <Box sx={{ flex: 1 }}>
            <IconButton onClick={() => handleMakeEditTopic(index, data.id)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => handleDeleteTopic(index, data.id)}>
              <DeleteIcon />
            </IconButton>
          </Box>
          <Handle
            type="source"
            position={Position.Right}
            id={`${data.id}-${index}-right`}
            className="handle-right"
          />
        </S.ChildrenBox>
      ))}
    </Stack>
  );
}

import { Box, Button, styled, Typography } from "@mui/material";

export const ErrorFullContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100vw",
  height: "100vh",
});

export const TitleText = styled(Typography)({
  textShadow: "-10px 5px 10px #8f8f8f",
  fontSize: "4em",
  fontWeight: "bold",
  color: process.env.REACT_APP_MAIN_COLOR,
});

export const SubText = styled(Typography)({
  fontSize: "1em",
  color: process.env.REACT_APP_MAIN_COLOR,
});

export const CustomButton = styled(Button)({
  marginTop: "20px",
  color: process.env.REACT_APP_MAIN_COLOR,
  border: `1px solid ${process.env.REACT_APP_MAIN_COLOR}`,
  transition: "transform 0.3s ease-in-out", // 크기 변화를 부드럽게 하기 위한 transition 추가
  "&:hover": {
    border: `1px solid ${process.env.REACT_APP_MAIN_COLOR}`,
    transform: "scale(1.05)", // hover 시 크기를 1.05배로 확대
  },
});

import { useAtom } from "jotai";
import React from "react";
import {
  handleGuideModalDragLeaveAtom,
  handleGuideModalDragOverAtom,
  handleGuideModalDropAtom,
} from "../../../ViewModels/GuideModalViewModel";
import {
  completeStepAtom,
  guideModalIsDraggingAtom,
  tempAssetAtom,
} from "../../../Atoms/GuideModalAtom";
import * as S from "./styled";
import { NoFileUpload } from "../../ChatMakeAsset/NoFileUpload";
import { Button, Typography } from "@mui/material";
import { CompleteFourthStep } from "./Complete";

export function FourthStep() {
  const [, handleDragOver] = useAtom(handleGuideModalDragOverAtom);
  const [, handleDragLeave] = useAtom(handleGuideModalDragLeaveAtom);
  const [, handleDrop] = useAtom(handleGuideModalDropAtom);
  const [isDragging] = useAtom(guideModalIsDraggingAtom);
  const [tempAsset, setTempAsset] = useAtom(tempAssetAtom);
  const [completeStep, setCompleteStep] = useAtom(completeStepAtom);

  if (completeStep[3]) return <CompleteFourthStep />;

  return (
    <>
      <S.ResponsiveRow>
        <Typography className="label">어셋 이름</Typography>
        <S.InputContainer>
          <S.CustomInputBase
            placeholder={"어셋이름을 입력해주세요"}
            value={tempAsset.name}
            onChange={(e) =>
              setTempAsset((current) => {
                return { ...current, name: e.target.value };
              })
            }
          />
        </S.InputContainer>
        <Button
          sx={{
            height: "100%",
            color: process.env.REACT_APP_MAIN_COLOR,
            "&:hover": { color: process.env.REACT_APP_MAIN_COLOR },
          }}
          onClick={() =>
            setCompleteStep((current) => ({ ...current, 3: true }))
          }
        >
          넘어가기
        </Button>
      </S.ResponsiveRow>
      <S.CenterSideBar
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <NoFileUpload />
        {isDragging && (
          <S.FileUploadBox>
            <S.FileUploadTypo>파일을 올려주세요.</S.FileUploadTypo>
            <S.FileUploadTypo>
              파일 형식은 txt, pdf, csv, xlsx를 지원하고 있습니다.
            </S.FileUploadTypo>
          </S.FileUploadBox>
        )}
      </S.CenterSideBar>
    </>
  );
}

import React from "react";
import { useAtom } from "jotai";
import { handleNodeSettingUpdateAtom } from "../../../../../ViewModels/ChatFlowViewModel";
import * as S from "../styled";
import { triggerTypeOptions } from "../../../../../Initialize/CustomNodeOptions";
import { TriggerDataProps } from "../../../../../Props/CustomNodeProps";

export function TriggerNodeSetting() {
  const [currentEditingNode, handleNodeUpdate] = useAtom(
    handleNodeSettingUpdateAtom
  );

  if (currentEditingNode === null) return null;

  return (
    <>
      <S.CustomAutoComplete
        value={
          (currentEditingNode.data as unknown as TriggerDataProps).type
            .view_value
        }
        options={triggerTypeOptions.map((value) => value.view_value)}
        onChange={(event, value) => {
          if (value)
            handleNodeUpdate(
              triggerTypeOptions.find((val) => val.view_value === value),
              "type"
            );
        }}
        renderInput={(params) => (
          <S.CustomTextField
            {...params}
            label="다른 Path로 유도를 할 Trigger의 타입을 선택해 주세요."
          />
        )}
      />

      {(currentEditingNode.data as unknown as TriggerDataProps).type.value ===
      "recommend" ? (
        <S.CustomTextField
          placeholder="추천질문 리스트를 넣어주세요."
          value={
            (currentEditingNode.data as unknown as TriggerDataProps)
              .recommend_messages
          }
          onChange={(event) =>
            handleNodeUpdate(event.target.value, "recommend_messages")
          }
        />
      ) : (currentEditingNode.data as unknown as TriggerDataProps).type
          .value === "text" ? (
        <S.CustomTextField
          value={
            (currentEditingNode.data as unknown as TriggerDataProps)
              .trigger_message
          }
          placeholder={`역질문할 내용을 넣어주세요.
예시) 저는 000 이런 것도 알려줄 수 있는데, 00, 00 같은 항목을 넣어서 질문해보는게 어떻겠어요?`}
          onChange={(event) =>
            handleNodeUpdate(event.target.value, "trigger_message")
          }
          minRows={5}
          maxRows={25}
          multiline
        />
      ) : null}
    </>
  );
}

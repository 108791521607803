import { atom } from "jotai";
import { encryptedAtomWithLocalStorage } from "./EncryptedAtomWithStorage";

export interface UserProps {
  idx: number;
  id: string;
  membership: string;
  image: string;
  nickname: string;
  membership_end_date: Date;
}

const user = {
  idx: 1,
  id: "mike@ieum.io",
  membership: "pro",
  image: "",
  nickname: "순형",
  membership_end_date: new Date("2024-12-31 00:00:00"),
};

export const userAtom = atom<UserProps | null>(null);

export const inviteUserAtom = atom<string[]>([]);

export const openaiAPIKeyAtom = encryptedAtomWithLocalStorage<string | null>(
  "99NlMl=V!j1i#maz",
  null
);

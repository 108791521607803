import React, { useEffect } from "react";
import { useAtom } from "jotai";
import { handleNodeTwoDepthSettingUpdateAtom } from "../../../../../ViewModels/ChatFlowViewModel";
import * as S from "../styled";
import { Box, IconButton, MenuItem, Stack, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { TopicClassifyNodeDataProps } from "../../../../../Props/CustomNodeProps";
import {
  handleAddExampleAtom,
  handleAddTopicAtom,
  handleDeleteTopicAtom,
  handleEditExamAtom,
  handleEditTopicAtom,
  handleMakeDeleteIndexExampleAtom,
  handleMakeEditIndexExampleAtom,
  handleMakeEditTopicAtom,
} from "../../../../../ViewModels/ChatFlowTopicSettingViewModel";

export function TopicNodeSetting() {
  const [currentEditingNode, handleNodeUpdate] = useAtom(
    handleNodeTwoDepthSettingUpdateAtom
  );

  const [, addExam] = useAtom(handleAddExampleAtom);
  const [exampleEditIndex, makeEditIndex] = useAtom(
    handleMakeEditIndexExampleAtom
  );
  const [, editExam] = useAtom(handleEditExamAtom);
  const [, deleteExam] = useAtom(handleMakeDeleteIndexExampleAtom);

  const [, addTopic] = useAtom(handleAddTopicAtom);
  const [editTopicIndex, handleMakeEditTopic] = useAtom(
    handleMakeEditTopicAtom
  );
  const [, editTopic] = useAtom(handleEditTopicAtom);
  const [, handleDeleteTopic] = useAtom(handleDeleteTopicAtom);

  if (currentEditingNode === null) return null;

  return (
    <Stack sx={{ gap: "20px" }}>
      <S.CustomTextField
        placeholder="분류할 주제의 이름을 설정해주세요."
        value={
          (currentEditingNode.data as unknown as TopicClassifyNodeDataProps)
            .temporary_intend?.intend_name ?? ""
        }
        onChange={(event) =>
          handleNodeUpdate(
            event.target.value,
            "temporary_intend",
            "intend_name"
          )
        }
      />
      <S.CustomTextField
        placeholder="분류할 주제에 대한 설명을 적어주세요."
        value={
          (currentEditingNode.data as unknown as TopicClassifyNodeDataProps)
            .temporary_intend?.intend_desc ?? ""
        }
        onChange={(event) =>
          handleNodeUpdate(
            event.target.value,
            "temporary_intend",
            "intend_desc"
          )
        }
        minRows={3}
        maxRows={10}
        multiline
      />
      <Stack direction={"row"} sx={{ gap: "20px", alignItems: "end" }}>
        <S.CustomTextField
          sx={{ flex: 5 }}
          placeholder={`분류할 주제에 대한 질문 예시들을 넣어주세요.
예시) 수학 공부 하고 싶은데 어떻게 공부를 시작해야 될지 모르겠어요.`}
          value={
            (currentEditingNode.data as unknown as TopicClassifyNodeDataProps)
              .temporary_intend?.intend_exam ?? ""
          }
          onChange={(event) =>
            handleNodeUpdate(
              event.target.value,
              "temporary_intend",
              "intend_exam"
            )
          }
          onKeyDown={(event) => {
            if (event.key === "Enter" && !event.shiftKey) {
              event.preventDefault();
              addExam(currentEditingNode.id);
            }
          }}
          minRows={1}
          maxRows={5}
          multiline
        />
        <S.CustomButton
          sx={{ flex: 1, height: "50px" }}
          onClick={
            exampleEditIndex !== null &&
            exampleEditIndex.id === currentEditingNode.id
              ? () => editExam(currentEditingNode.id)
              : () => addExam(currentEditingNode.id)
          }
        >
          {exampleEditIndex !== null &&
          exampleEditIndex.id === currentEditingNode.id
            ? "예제 수정"
            : "예제 추가"}
        </S.CustomButton>
      </Stack>

      {(currentEditingNode.data as unknown as TopicClassifyNodeDataProps)
        .temporary_intend?.intend_exam_list &&
        (
          currentEditingNode.data as unknown as TopicClassifyNodeDataProps
        ).temporary_intend?.intend_exam_list.map((exam, index) => (
          <Stack
            key={`topic-exam-list-${index}`}
            direction={"row"}
            sx={{
              padding: "10px",
              display: "flex",
              alignItems: "start",
              justifyContent: "space-between",
              borderBottom: "1px solid #ececec",
            }}
          >
            <Typography sx={{ flex: 4 }}>{exam}</Typography>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "end",
                height: "40px",
              }}
            >
              <IconButton
                onClick={() => makeEditIndex(index, currentEditingNode.id)}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => deleteExam(index, currentEditingNode.id)}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Stack>
        ))}

      <S.CustomButton
        onClick={
          editTopicIndex !== null && editTopicIndex.id === currentEditingNode.id
            ? () => editTopic(currentEditingNode.id)
            : () => addTopic(currentEditingNode.id)
        }
      >
        {editTopicIndex !== null && editTopicIndex.id === currentEditingNode.id
          ? "토픽 수정"
          : "토픽 추가"}
      </S.CustomButton>

      {(
        currentEditingNode.data as unknown as TopicClassifyNodeDataProps
      ).intend_list.map((topic, index) => (
        <MenuItem
          key={`topic-exam-list-${index}`}
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography>{topic.intend_name}</Typography>
          <Box>
            <IconButton
              onClick={() => handleMakeEditTopic(index, currentEditingNode.id)}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => handleDeleteTopic(index, currentEditingNode.id)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </MenuItem>
      ))}
    </Stack>
  );
}

import React from "react";
import { useAtom } from "jotai";
import { MenuItem } from "@mui/material";
import * as S from "./styled";
import { useNavigate } from "react-router-dom";
import {
  handleCurrentMyStateMenuCloseAtom,
  handleCurrentMyStateMenuOpenAtom,
  handleInviteUserAtom,
} from "../../../../../ViewModels/HeaderViewModel";
import { userAtom } from "../../../../../Atoms/PublicAtom";

export function CurrentMyStateMenu() {
  const [user] = useAtom(userAtom);
  const [currentMyStateOpen] = useAtom(handleCurrentMyStateMenuOpenAtom);
  const [currentMyStateEl, currentMyStateClose] = useAtom(
    handleCurrentMyStateMenuCloseAtom
  );

  const [inviteUsers, handleUserCheck] = useAtom(handleInviteUserAtom);

  const navigate = useNavigate();

  if (inviteUsers === null || user === null) return null;

  return (
    <S.CustomMenu
      anchorEl={currentMyStateEl}
      id="current-my-state-menu"
      open={currentMyStateOpen}
      onClose={currentMyStateClose}
      onClick={currentMyStateClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem
        onClick={() => handleUserCheck(user.id, { navigate: navigate })}
      >
        내가 만든 챗봇 보기
      </MenuItem>

      {inviteUsers.map((inviteUser, index) => (
        <MenuItem
          onClick={() => handleUserCheck(inviteUser, { navigate: navigate })}
          key={`invite-user-${index}`}
        >
          {inviteUser}의 챗봇 보기
        </MenuItem>
      ))}
    </S.CustomMenu>
  );
}

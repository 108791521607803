import { atom } from "jotai";
import { getCookie } from "../Utils/CookieUtil";
import {
  flowAtom,
  flowFrontElementAtom,
  settingModalAtom,
} from "../Atoms/ChatDesignPublicAtom";
import { handleApiResponse } from "../Utils/APIUtil";
import { insertFrontElement } from "../Queries/DokgabiFlowQueries";

export const handleCloseAtom = atom(null, async (get, set) => {
  const flow = get(flowAtom);
  const flowFrontElement = get(flowFrontElementAtom);

  if (flow && flowFrontElement) {
    const refreshCookie = getCookie(
      process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
    );

    const accessCookie = getCookie(
      process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
    );

    const errorFunction = () => {
      set(settingModalAtom, false);
    };

    const successFunction = (results: any) => {
      set(flowFrontElementAtom, results);
      set(settingModalAtom, false);
    };

    const newAccessToken = await handleApiResponse(
      refreshCookie,
      set,
      () =>
        insertFrontElement(accessCookie ?? "", flowFrontElement ?? [], flow.id),
      () => errorFunction(),
      (results) => successFunction(results)
    );

    if (newAccessToken) {
      await handleApiResponse(
        null,
        set,
        () =>
          insertFrontElement(
            newAccessToken ?? "",
            flowFrontElement ?? [],
            flow.id
          ),
        () => errorFunction(),
        (results) => successFunction(results)
      );
    }
  }
});

import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import {
  assetViewFileCurrentPageAtom,
  assetViewFileCurrentRowsAtom,
  currentAssetViewFileAtom,
} from "../../../Atoms/ChatMakeAssetAtom";
import { useGetFileData } from "../../../Queries/DokgabiAssetQueries";
import {
  Box,
  CardContent,
  CardActionArea,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
} from "@mui/material";
import { Delete, FileOpen, Pending } from "@mui/icons-material";
import { selectAssetAtom } from "../../../Atoms/ChatAssetAtom";
import * as S from "./styled";
import {
  handleRowClickAtom,
  handleUsingFilesGridScrollAtom,
} from "../../../ViewModels/ChatMakeAssetViewModel";
import { waitingModalAtom } from "../../../Atoms/RootAtom";
import PDFViewer from "../../PDFViewer";

export function AssetFile() {
  const [selectAsset] = useAtom(selectAssetAtom);
  const [currentAssetViewFile] = useAtom(currentAssetViewFileAtom);
  const [assetViewFileCurrentPage] = useAtom(assetViewFileCurrentPageAtom);

  const [selectedRow, handleRowClick] = useAtom(handleRowClickAtom);
  const [assetViewFileCurrentRows, setassetViewFileCurrentRows] = useAtom(
    assetViewFileCurrentRowsAtom
  );

  const [, handleScroll] = useAtom(handleUsingFilesGridScrollAtom);

  const [, setWaitingModal] = useAtom(waitingModalAtom);

  // 훅을 항상 호출하도록 수정
  const { data: currentFileData } = useGetFileData(
    currentAssetViewFile || {
      file_name: "",
      file_type: "",
      file_view_name: "",
      file_vector_progress: 0,
      task_id: "",
    },
    selectAsset.id,
    assetViewFileCurrentPage
  );

  useEffect(() => {
    if (currentFileData && Array.isArray(currentFileData.rows)) {
      setassetViewFileCurrentRows((current) => [
        ...current,
        ...currentFileData.rows,
      ]);
      setWaitingModal({
        state: false,
        text: "데이터 가져오는 중",
      });
    }
  }, [currentFileData]);

  // currentAssetViewFile이 null일 때 처리
  if (currentAssetViewFile === null) {
    return (
      <S.OuterBox>
        <S.NoRowBox>
          <FileOpen sx={{ width: "30%", height: "30%", color: "#8f8f8f" }} />
          <Typography sx={{ color: "#8f8f8f" }}>
            보고 싶은 파일을 선택해주세요.
          </Typography>
        </S.NoRowBox>
      </S.OuterBox>
    );
  }

  // 로딩 중일 때 처리
  if (assetViewFileCurrentRows?.length === 0) {
    return (
      <S.OuterBox>
        <S.NoRowBox>
          <Pending sx={{ width: "30%", height: "30%", color: "#8f8f8f" }} />
          <Typography sx={{ color: "#8f8f8f" }}>
            파일을 불러오는 중입니다.
          </Typography>
        </S.NoRowBox>
      </S.OuterBox>
    );
  }

  return (
    <S.AssetFileBox>
      {/* 프로그레스 표시 */}
      <S.TobEmptyBox></S.TobEmptyBox>
      <S.CustomLinearProgress
        variant="determinate"
        value={currentFileData?.progress}
      />
      <S.ProgressTypo>
        {currentFileData?.progress ?? 0}% Asset화 완료
      </S.ProgressTypo>

      <S.FileViewOuterGrid container>
        {/* 왼쪽 그리드: 파일 목록 */}
        <S.FileDocListGrid item xs={4} onScroll={handleScroll}>
          {assetViewFileCurrentRows && assetViewFileCurrentRows.length > 0 ? (
            assetViewFileCurrentRows.map((row, index) => (
              <S.FileDocsCard
                key={`${index}-file-docs-list-card`}
                onClick={() => handleRowClick(row)}
              >
                <CardActionArea>
                  <CardContent>
                    {/* 왼쪽 상단에 Chip으로 index+1번 문서 표시 */}
                    <S.FileDocsCardBox>
                      <S.FileDocsChip
                        label={`${index + 1}번 문서`}
                        size="small"
                      />

                      {/* 오른쪽에 수정 및 삭제 버튼 */}
                      <Box>
                        {/* <IconButton
                          sx={{ marginRight: 1 }}
                          onClick={(e) => {
                            e.stopPropagation(); // 클릭 이벤트가 상위로 전파되지 않도록 설정
                            // 수정 로직을 여기에 추가
                          }}
                        >
                          <Edit />
                        </IconButton> */}
                      </Box>
                    </S.FileDocsCardBox>

                    {/* 문서 요약 타이틀 및 요약된 내용 */}
                    <S.FileDocsSummaryTitleTypo variant="subtitle2">
                      문서 요약
                    </S.FileDocsSummaryTitleTypo>
                    <Typography variant="body2">
                      {row.content
                        ? row.content.replaceAll("nan", "-").substring(0, 70)
                        : Object.values(row)
                            .join(", ")
                            .replaceAll("nan", "-")
                            .substring(0, 70)}
                      {row.content && row.content.length > 70 ? "..." : ""}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </S.FileDocsCard>
            ))
          ) : (
            <Typography>데이터 없음</Typography>
          )}
        </S.FileDocListGrid>

        {/* 오른쪽 그리드: 상세 정보 */}
        <S.FileDocsDetailGrid item xs={8}>
          {selectedRow ? (
            <>
              {/* 상세 데이터 출력 */}
              {currentFileData?.columns &&
              currentFileData.columns.length > 0 ? (
                <Table>
                  <TableBody>
                    {(
                      currentFileData as {
                        progress: number;
                        task_state: string;
                        rows: any[];
                        columns: string[];
                      }
                    ).columns.map((col, index) => (
                      <TableRow
                        key={`${index}-current-file-data-click-detail-view`}
                      >
                        {/* 왼쪽에 col */}
                        <S.FileDocsDetailOneLineCell>
                          {col}
                        </S.FileDocsDetailOneLineCell>
                        {/* 오른쪽에 selectedRow[col] */}
                        <TableCell>
                          {selectedRow[col] === "nan" ? "-" : selectedRow[col]}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <>
                  {Object.keys(selectedRow).map((key, index) => (
                    <Box
                      key={`${index}-${selectedRow}-${key}-list-mosigi`}
                      mb={2}
                    >
                      {key === "content" ? (
                        // content일 경우는 Typography로 출력
                        <Box
                          sx={{
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid #8f8f8f",
                            borderRadius: "5px",
                            width: "100%",
                            flexDirection: "column",
                            background: "white",
                            padding: "20px",
                            boxSizing: "border-box",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#8f8f8f",
                              textAlign: "start",
                              width: "calc(90% + 40px)",
                              marginBottom: "20px",
                            }}
                          >
                            추출한 텍스트
                          </Typography>
                          <Typography variant="body2">
                            {selectedRow[key] ?? ""}
                          </Typography>
                        </Box>
                      ) : key === "page_image" ? (
                        <PDFViewer
                          filePath={`${
                            process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS
                          }${(selectedRow[key]["file_path"] as string) ?? ""}`}
                          pageNum={
                            (selectedRow[key]["page_num"] as number) ?? 1
                          }
                        />
                      ) : (
                        <Box
                          sx={{
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid #ececec",
                            borderRadius: "5px",
                            width: "100%",
                            boxSizing: "border-box",
                            padding: "20px",
                          }}
                        >
                          <Typography variant="subtitle1">
                            {key}:{" "}
                            {selectedRow[key] === "nan"
                              ? "-"
                              : selectedRow[key]}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  ))}
                </>
              )}
            </>
          ) : (
            <S.OuterBox>
              <S.NoRowBox>
                <Pending
                  sx={{ width: "30%", height: "30%", color: "#8f8f8f" }}
                />
                <Typography sx={{ color: "#8f8f8f" }}>
                  왼쪽에서 보고싶은 문서를 선택하세요
                </Typography>
              </S.NoRowBox>
            </S.OuterBox>
          )}
        </S.FileDocsDetailGrid>
      </S.FileViewOuterGrid>
    </S.AssetFileBox>
  );
}

import React, { useEffect, useState } from "react";
import { ArrowForward } from "@mui/icons-material";
import * as S from "./styled";
import { useAtom } from "jotai";
import {
  characterNameAtom,
  chattingValueAtom,
  typeAvailableStateAtom,
} from "../../../Atoms/ChatTestAtom";
import {
  handleInputChange,
  handleKeyDown,
  handleSendMessage,
} from "../../../ViewModels/ChatTestViewModel";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { Box, Button, Grid, Menu, MenuItem } from "@mui/material";

const characterList = [
  "고래변호사",
  "츤데레",
  "경영이",
  "김상병",
  "니체",
  "해적왕",
  "명탐정 설박사",
  "목탁스님",
  "사랑사냥꾼",
  "수다쟁이박",
  "예성경",
  "요리왕",
  "용감한소룡",
  "유머러스 박",
  "임금",
  "조선 애기씨",
  "테스선생",
  "현타닥터",
];

export function ChatInput() {
  const [inputValue] = useAtom(chattingValueAtom);

  const [, onInputChange] = useAtom(handleInputChange);
  const [, onSendMessage] = useAtom(handleSendMessage);
  const [, onKeyDown] = useAtom(handleKeyDown);
  const [typeAvailableState] = useAtom(typeAvailableStateAtom);

  const [characterName, setcharacterName] = useAtom(characterNameAtom);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <S.InputContainer>
      <Button
        variant="contained"
        sx={{
          background: process.env.REACT_APP_MAIN_COLOR,
          borderRadius: "30px",
          "&:hover": {
            background: process.env.REACT_APP_MAIN_COLOR,
          },
        }}
        onClick={handleMenuOpen}
      >
        {characterName === "" ? "캐릭터 말투 선택" : characterName}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <Box
          sx={{
            padding: "10px",
            width: "600px",
            borderRadius: "5px",
            border: "1px solid #ececec",
          }}
        >
          <Grid container spacing={2}>
            {characterList.map((character, index) => (
              <Grid item xs={3} key={`${index}-character-maltoo`}>
                {character === characterName ? (
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      background: process.env.REACT_APP_MAIN_COLOR,
                      borderRadius: "30px",
                      "&:hover": {
                        background: process.env.REACT_APP_MAIN_COLOR,
                      },
                    }}
                    onClick={() => {
                      setcharacterName(character);
                    }}
                  >
                    {character}
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    fullWidth
                    sx={{
                      border: `1px solid ${process.env.REACT_APP_MAIN_COLOR}`,
                      color: process.env.REACT_APP_MAIN_COLOR,
                      borderRadius: "30px",
                      "&:hover": {
                        border: `1px solid ${process.env.REACT_APP_MAIN_COLOR}`,
                        color: process.env.REACT_APP_MAIN_COLOR,
                      },
                    }}
                    onClick={() => {
                      setcharacterName(character);
                    }}
                  >
                    {character}
                  </Button>
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
      </Menu>

      <S.CustomInputBase
        placeholder={
          typeAvailableState
            ? "질문을 입력해보세요."
            : "챗봇이 답변을 생성중입니다."
        }
        multiline
        maxRows={10}
        value={inputValue}
        onChange={onInputChange}
        onKeyDown={
          typeAvailableState ? (event) => onKeyDown(event) : () => null
        }
      />
      <S.CustomIconButton
        onClick={typeAvailableState ? () => onSendMessage() : () => null}
      >
        {typeAvailableState ? <ArrowForward /> : <StopCircleIcon />}
      </S.CustomIconButton>
    </S.InputContainer>
  );
}

import React from "react";
import { Typography } from "@mui/material";
import { CheckCircle, FileDownload } from "@mui/icons-material";
import * as S from "./styled";
import { useAtom } from "jotai";
import { tempAssetAtom } from "../../../../Atoms/GuideModalAtom";

export function CompleteFourthStep() {
  const [tempAsset] = useAtom(tempAssetAtom);

  return (
    <S.FileUploadInnerBox>
      <CheckCircle sx={{ width: "30%", height: "30%", color: "#336eff" }} />

      {tempAsset.id === -1 ? (
        <>
          <Typography sx={{ color: "#8f8f8f" }}>
            어셋 만들기를 넘어갔습니다.
          </Typography>
          <Typography sx={{ color: "#8f8f8f" }}>
            사이트 맨위의 ASSET을 눌러서 Asset을 만들고 Flow에서 적용하실수
            있어요!!
          </Typography>
        </>
      ) : (
        <>
          <Typography sx={{ color: "#8f8f8f" }}>
            어셋 만들기 완료 했습니다.
          </Typography>
          <Typography sx={{ color: "#8f8f8f" }}>
            어셋화 완료는 이메일로 확인해주세요.
          </Typography>
        </>
      )}
    </S.FileUploadInnerBox>
  );
}

import {
  applyEdgeChanges,
  applyNodeChanges,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  Connection,
  addEdge,
  MarkerType,
} from "@xyflow/react";
import { atom } from "jotai";
import {
  currentEditingNodeIndexAtom,
  currentMaxIdAtom,
  typeAtom,
} from "../Atoms/ChatFlowAtom";
import {
  initializeBasicLLMNodeAtom,
  initializeMakeJsonNodeAtom,
  initializeRAGBaseLLMNodeAtom,
  initializeTargetNodeAtom,
  initializeTopicClassifyNodeAtom,
  initializeTriggerNodeAtom,
  initializeWelcomeNodeAtom,
} from "../Initialize/ChatFlowInitialize";
import { errorIdListAtom, flowAtom } from "../Atoms/ChatDesignPublicAtom";
import { DokgabiAssetProps } from "../Models/DokgabiFlow";
import {
  BasicLLMNodeDataProps,
  RagBaseLLMDataProps,
} from "../Props/CustomNodeProps";
import { findMaxId } from "../Utils/FindMaxNumUtil";
import { openaiAPIKeyAtom } from "../Atoms/PublicAtom";
import { checkNode } from "./ChatbotDesignHeaderViewModel";

export const onNodesChangeAtom = atom(
  (get) => get(flowAtom).flow_nodes,
  (get, set, changes: NodeChange<Node>[]) => {
    if (changes[0].type === "remove") set(currentEditingNodeIndexAtom, null);

    if (!(changes[0].type === "remove" && ["0", "1"].includes(changes[0].id))) {
      set(flowAtom, (flow) => {
        const nds = flow.flow_nodes;
        return { ...flow, flow_nodes: applyNodeChanges(changes, nds) };
      });
    }
  }
);

export const onEdgesChangeAtom = atom(
  (get) => get(flowAtom).flow_edges,
  (get, set, changes: EdgeChange<Edge>[]) => {
    if (
      !(
        changes[0].type === "remove" &&
        changes[0].id === "xy-edge__0start-right-1-left"
      )
    ) {
      set(flowAtom, (flow) => {
        const eds = flow.flow_edges;

        return { ...flow, flow_edges: applyEdgeChanges(changes, eds) };
      });
    }
  }
);

export const onConnectAtom = atom(null, (get, set, params: Connection) => {
  set(flowAtom, (flow) => {
    const eds = flow.flow_edges;
    const nodes = flow.flow_nodes;

    const connectIndex = nodes.findIndex((node) => {
      return node.data.id === params.target;
    });

    let newEdge = null;

    if (connectIndex !== -1) {
      const connectNode = nodes[connectIndex];

      if (connectNode.type === "trigger") {
        if (params.targetHandle?.includes("right")) {
          newEdge = {
            ...params,
            markerStart: {
              type: MarkerType.ArrowClosed,
              width: 20,
              height: 20,
              color: process.env.REACT_APP_MAIN_COLOR,
            },
            style: {
              strokeWidth: 3,
              stroke: process.env.REACT_APP_MAIN_COLOR,
            },
            animated: true,
          } as Edge;
        } else {
          newEdge = {
            ...params,
            markerEnd: {
              type: MarkerType.ArrowClosed,
              width: 20,
              height: 20,
              color: process.env.REACT_APP_MAIN_COLOR,
            },
            style: {
              strokeWidth: 3,
              stroke: process.env.REACT_APP_MAIN_COLOR,
            },
            animated: true,
          } as Edge;
        }
      } else {
        newEdge = {
          ...params,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 20,
            height: 20,
            color: process.env.REACT_APP_MAIN_COLOR,
          },
          style: {
            strokeWidth: 3,
            stroke: process.env.REACT_APP_MAIN_COLOR,
          },
        } as Edge;
      }

      let newEds = addEdge(newEdge, eds);

      return { ...flow, flow_edges: newEds };
    } else {
      return { ...flow, flow_edges: eds };
    }
  });
});

export const onDragStartAtom = atom(
  null,
  (get, set, event: React.DragEvent<HTMLButtonElement>, nodeType: string) => {
    set(typeAtom, nodeType);
    event.dataTransfer.effectAllowed = "move";
  }
);

export const onFlowComponentDragOverAtom = atom(
  null,
  (get, set, event: any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }
);

export const onDropAtom = atom(
  null,
  (get, set, event: any, screenToFlowPosition: any) => {
    const type = get(typeAtom) as keyof typeof typeMap;
    event.preventDefault();

    if (!type) return;

    const position: { x: number; y: number } = screenToFlowPosition({
      x: event.clientX,
      y: event.clientY,
    });

    const typeMap = {
      welcome: initializeWelcomeNodeAtom,
      target: initializeTargetNodeAtom,
      topic: initializeTopicClassifyNodeAtom,
      basicLLM: initializeBasicLLMNodeAtom,
      ragLLM: initializeRAGBaseLLMNodeAtom,
      makeJson: initializeMakeJsonNodeAtom,
      trigger: initializeTriggerNodeAtom,
    };

    const selectedNode = typeMap[type];

    if (selectedNode) {
      // api_key 집어 넣기
      if (
        selectedNode.type === "basicLLM" ||
        selectedNode.type === "ragLLM" ||
        selectedNode.type === "welcome" ||
        selectedNode.type === "makeJson"
      ) {
        const api_key = get(openaiAPIKeyAtom);
        (selectedNode.data as BasicLLMNodeDataProps).api_key = api_key ?? "";
      }

      const flow = get(flowAtom);

      const currentMaxIndex = findMaxId(flow.flow_nodes);
      const newId = currentMaxIndex ? currentMaxIndex + 1 : 1;

      // Update currentMaxIdAtom to the new ID
      set(currentMaxIdAtom, newId);

      const newNode = {
        ...selectedNode,
        id: `${newId}`,
        position: position,
        data: {
          ...selectedNode.data,

          id: `${newId}`,
        },
      };

      set(flowAtom, (flow) => {
        const current = flow.flow_nodes;
        return { ...flow, flow_nodes: [...current, newNode] };
      });
    }
  }
);

export const onNodeCopyAtom = atom(
  null,
  (get, set, data: any, screenToFlowPosition: any, nodeType: string) => {
    const type = nodeType as keyof typeof typeMap;

    if (!type) return;

    const screenCenter = {
      x: window.innerWidth / 2,
      y: window.innerHeight / 2,
    };
    const flowPosition = screenToFlowPosition(screenCenter);

    const typeMap = {
      welcome: initializeWelcomeNodeAtom,
      target: initializeTargetNodeAtom,
      topic: initializeTopicClassifyNodeAtom,
      basicLLM: initializeBasicLLMNodeAtom,
      ragLLM: initializeRAGBaseLLMNodeAtom,
      trigger: initializeTriggerNodeAtom,
      makeJson: initializeMakeJsonNodeAtom,
    };

    const selectedNode = typeMap[type];

    if (selectedNode) {
      const flow = get(flowAtom);

      const currentMaxIndex = findMaxId(flow.flow_nodes);
      const newId = currentMaxIndex ? currentMaxIndex + 1 : 1;

      // Update currentMaxIdAtom to the new ID
      set(currentMaxIdAtom, newId);

      const newNode = {
        ...selectedNode,
        id: `${newId}`,
        position: flowPosition,
        data: {
          ...data,
          id: `${newId}`,
        },
      };

      set(flowAtom, (flow) => {
        const current = flow.flow_nodes;
        return { ...flow, flow_nodes: [...current, newNode] };
      });
    }
  }
);

export const onNodesDeleteAtom = atom(null, (get, set, nodes: Node[]) => {
  const flow = get(flowAtom);

  for (var node of nodes) {
    if (node.type === "ragLLM") {
      const haveToRemoveAsset = (node.data as unknown as RagBaseLLMDataProps)
        .grounding_data;

      if (typeof haveToRemoveAsset !== "string") {
        set(flowAtom, (current) => {
          const indexToRemove = current.flow_used_assets.findIndex(
            (value) => value.id === haveToRemoveAsset.id
          );

          if (indexToRemove !== -1) {
            const updatedAssets = [...current.flow_used_assets];
            updatedAssets.splice(indexToRemove, 1);
            return {
              ...current,
              flow_used_assets: updatedAssets,
            };
          }

          return current;
        });
      }
    }
  }
});

export const handleNodeClickAtom = atom(
  (get) => {
    const flow = get(flowAtom);
    const currentEditingNodeIndex = get(currentEditingNodeIndexAtom);

    if (currentEditingNodeIndex)
      return flow.flow_nodes[currentEditingNodeIndex];
    else return null;
  },
  (get, set, id: string) => {
    const flow = get(flowAtom);

    flow.flow_nodes.map((value, index) => {
      if (value.data.id === id) set(currentEditingNodeIndexAtom, index);
    });
  }
);

export const handleNodeSettingDoneAtom = atom(
  (get) => {
    const flow = get(flowAtom);
    const currentEditingNodeIndex = get(currentEditingNodeIndexAtom);

    if (currentEditingNodeIndex)
      return flow.flow_nodes[currentEditingNodeIndex];
    else return null;
  },
  (get, set) => set(currentEditingNodeIndexAtom, null)
);

// 설명 : 오른쪽에 나오는 세팅바에서 one-depth 문자열을 수정할 때
export const handleNodeSettingUpdateAtom = atom(
  (get) => {
    const flow = get(flowAtom);
    const currentEditingNodeIndex = get(currentEditingNodeIndexAtom);

    return currentEditingNodeIndex !== null
      ? flow.flow_nodes[currentEditingNodeIndex]
      : null;
  },
  (get, set, value: any, key: string) => {
    const currentIndex = get(currentEditingNodeIndexAtom);

    if (currentIndex !== null) {
      set(flowAtom, (current) => {
        const updatedNodes = [...current.flow_nodes];
        const updatedNode = {
          ...updatedNodes[currentIndex],
          data: {
            ...updatedNodes[currentIndex].data,
            [key]: value,
          },
        };
        updatedNodes[currentIndex] = updatedNode;

        const errorIdList = get(errorIdListAtom);

        if (errorIdList.some((value) => value === updatedNode.id)) {
          const result = checkNode(updatedNode);

          if (result) {
            set(errorIdListAtom, (current) =>
              current.filter((value) => value !== updatedNode.id)
            );
          }
        }

        return {
          ...current,
          flow_nodes: updatedNodes,
        };
      });
    }
  }
);

// 설명 : 오른쪽에 나오는 세팅바에서 two-depth 문자열을 수정할 때
export const handleNodeTwoDepthSettingUpdateAtom = atom(
  (get) => {
    const flow = get(flowAtom);
    const currentEditingNodeIndex = get(currentEditingNodeIndexAtom);

    return currentEditingNodeIndex !== null
      ? flow.flow_nodes[currentEditingNodeIndex]
      : null;
  },
  (get, set, value: any, key: string, child_key: string) => {
    const currentIndex = get(currentEditingNodeIndexAtom);

    if (currentIndex !== null) {
      set(flowAtom, (current) => {
        const updatedNodes = [...current.flow_nodes];
        const existingNodeData = updatedNodes[currentIndex].data[key] || {};
        const updatedNode = {
          ...updatedNodes[currentIndex],
          data: {
            ...updatedNodes[currentIndex].data,
            [key]: {
              ...existingNodeData,
              [child_key]: value,
            },
          },
        };
        updatedNodes[currentIndex] = updatedNode;

        const errorIdList = get(errorIdListAtom);

        if (errorIdList.some((value) => value === updatedNode.id)) {
          const result = checkNode(updatedNode);

          if (result) {
            set(errorIdListAtom, (current) =>
              current.filter((value) => value !== updatedNode.id)
            );
          }
        }

        return {
          ...current,
          flow_nodes: updatedNodes,
        };
      });
    }
  }
);

// 설명 : flow 내부의 설정에서 one-depth 문자열 추가/수정 할 때
export const handleNodeSettingWhenInFlowUpdateAtom = atom(
  null,
  (get, set, value: any, key: string, id: string) => {
    const flow = get(flowAtom);

    const currentIndex = flow.flow_nodes.findIndex(
      (fl, index) => fl.data.id === id
    );

    if (currentIndex !== null) {
      set(flowAtom, (current) => {
        const updatedNodes = [...current.flow_nodes];
        const updatedNode = {
          ...updatedNodes[currentIndex],
          data: {
            ...updatedNodes[currentIndex].data,
            [key]: value,
          },
        };
        updatedNodes[currentIndex] = updatedNode;

        const errorIdList = get(errorIdListAtom);

        if (errorIdList.some((value) => value === updatedNode.id)) {
          const result = checkNode(updatedNode);

          if (result) {
            set(errorIdListAtom, (current) =>
              current.filter((value) => value !== updatedNode.id)
            );
          }
        }

        return {
          ...current,
          flow_nodes: updatedNodes,
        };
      });
    }
  }
);

// 설명 : flow 내부의 설정에서 two-depth 문자열 추가/수정 할 때
export const handleNodeTwoDepthSettingWhenInFlowUpdateAtom = atom(
  null,
  (get, set, value: any, key: string, child_key: string, id: string) => {
    const flow = get(flowAtom);

    const currentIndex = flow.flow_nodes.findIndex(
      (fl, index) => fl.data.id === id
    );

    if (currentIndex !== null) {
      set(flowAtom, (current) => {
        const updatedNodes = [...current.flow_nodes];
        const existingNodeData = updatedNodes[currentIndex].data[key] || {};
        const updatedNode = {
          ...updatedNodes[currentIndex],
          data: {
            ...updatedNodes[currentIndex].data,
            [key]: {
              ...existingNodeData,
              [child_key]: value,
            },
          },
        };
        updatedNodes[currentIndex] = updatedNode;

        const errorIdList = get(errorIdListAtom);

        if (errorIdList.some((value) => value === updatedNode.id)) {
          const result = checkNode(updatedNode);

          if (result) {
            set(errorIdListAtom, (current) =>
              current.filter((value) => value !== updatedNode.id)
            );
          }
        }

        return {
          ...current,
          flow_nodes: updatedNodes,
        };
      });
    }
  }
);

export const handleAddFlowAssetAtom = atom(
  null,
  (get, set, asset: DokgabiAssetProps) => {
    set(flowAtom, (current) => ({
      ...current,
      flow_used_assets: [...current.flow_used_assets, asset],
    }));
  }
);

// 설명 : flow 의 isPublic 변경
export const handleIsPublicAtom = atom(
  (get) => get(flowAtom).is_public,
  (get, set, state: boolean) =>
    set(flowAtom, (current) => ({ ...current, is_public: state }))
);

// 설명 : flow 의 chatbot_type 변경
export const handleChatbotTypeAtom = atom(
  (get) => get(flowAtom).chatbot_type,
  (get, set, state: boolean) =>
    set(flowAtom, (current) => ({
      ...current,
      chatbot_type: state ? "mentor" : null,
    }))
);

// 설명 : flow 의 chatbot_type 변경
export const handleChatbotType2Atom = atom(
  (get) => get(flowAtom).chatbot_type,
  (get, set, state: boolean) =>
    set(flowAtom, (current) => ({
      ...current,
      chatbot_type: state ? "template" : null,
    }))
);

export const handleHideStateAtom = atom(null, (get, set, id: string) => {
  set(flowAtom, (current) => ({
    ...current,
    flow_nodes: current.flow_nodes.map((node) =>
      node.id === id
        ? {
            ...node,
            data: {
              ...node.data,
              hide: !node.data.hide, // 현재 hide 상태를 반전
            },
          }
        : node
    ),
  }));
});

import { atom } from "jotai";
import { ChangeEvent } from "react";
import {
  chatbotDesignHeaderMakingAnchorElAtom,
  currentMakerAtom,
  errorIdListAtom,
  flowAtom,
} from "../Atoms/ChatDesignPublicAtom";
import {
  errorModalAtom,
  successModalAtom,
  waitingModalAtom,
} from "../Atoms/RootAtom";
import { insertFlow } from "../Queries/DokgabiFlowQueries";
import { Edge, Node } from "@xyflow/react";
import {
  BasicLLMNodeProps,
  MakeJsonNodeProps,
  RAGbaseLLMNodeProps,
  TopicClassifyNodeProps,
  TriggerNodeProps,
  WelcomeNodeProps,
} from "../Props/CustomNodeProps";
import { handleApiResponse } from "../Utils/APIUtil";
import { getCookie } from "../Utils/CookieUtil";
import { openaiAPIKeyAtom, userAtom } from "../Atoms/PublicAtom";
import { checkOpenAIAPIKey } from "../Utils/CheckOpenAIUtil";

export const navigateAtom = atom(
  null,
  (get, set, { navigate, location }, page: string, navigatePage: string) => {
    const currentPath = location.pathname;
    const newPath = currentPath.replace(page, navigatePage);
    navigate(newPath);
  }
);

export const onTextFieldChangeAtom = atom(
  null,
  (get, set, event: ChangeEvent<HTMLInputElement>) => {
    set(flowAtom, (current) => ({ ...current, name: event?.target.value }));
  }
);

export const handleChatbotDesignHeaderMakeMenuOpenAtom = atom(
  (get) => Boolean(get(chatbotDesignHeaderMakingAnchorElAtom)),
  (get, set, event: React.MouseEvent<HTMLButtonElement>) => {
    set(chatbotDesignHeaderMakingAnchorElAtom, event.currentTarget);
  }
);

export const handleChatbotDesignHeaderMakeMenuCloseAtom = atom(
  (get) => get(chatbotDesignHeaderMakingAnchorElAtom),
  (get, set) => set(chatbotDesignHeaderMakingAnchorElAtom, null)
);

export const onSaveAtom = atom(null, async (get, set) => {
  let flow = get(flowAtom);

  if (flow.maker === "") {
    const user = get(userAtom);

    flow = {
      ...flow,
      maker: user?.membership === "admin" ? "admin" : user?.id ?? "",
    };
  }

  const flowCheck = checkFlow(flow.flow_nodes);

  set(errorIdListAtom, flowCheck);

  const cycleCheck = checkCycle(flow.flow_edges);

  if (flow.name === "") {
    set(errorModalAtom, {
      state: true,
      event: null,
      eventText: "",
      redirectUrl: "",
      text: "챗봇 이름을 입력해주세요.",
      title: "챗봇 입력 없음",
    });

    return false;
  } else if (flowCheck.length >= 1) {
    set(errorModalAtom, {
      state: true,
      event: null,
      eventText: "",
      redirectUrl: "",
      text: "설정하지 않은 항목이 있습니다.\n빨간색 테두리로 된 부분을 체크해주세요.",
      title: "사용하지 않는 노드 삭제 또는 노드 항목 설정하기",
    });

    return false;
  } else if (cycleCheck !== null) {
    set(flowAtom, (current) => ({
      ...current,
      flow_edges: current.flow_edges.map((edge) =>
        edge.id === cycleCheck.id
          ? {
              ...edge,
              style: {
                ...edge.style,
                stroke: "red",
              },
              markerEnd:
                edge.markerEnd && typeof edge.markerEnd === "object"
                  ? {
                      ...edge.markerEnd,
                      color: "red", // markerEnd에 stroke 속성이 없다면 color로 수정
                    }
                  : edge.markerEnd, // markerEnd가 객체가 아닌 경우 처리
            }
          : edge
      ),
    }));

    set(errorModalAtom, {
      state: true,
      event: null,
      eventText: "",
      redirectUrl: "",
      text: "순환 되는 구조로 연결을 하면 챗봇 로직에 문제가 생깁니다.\n빨간색으로 표시되어 있는 부분을 보고 해당 부분 변경해주세요.",
      title: "챗봇 구조 오류",
    });

    return false;
  } else {
    set(waitingModalAtom, {
      state: true,
      text: "저장하는 중",
    });

    const openaiAPIKey = get(openaiAPIKeyAtom);

    if (!openaiAPIKey) {
      const uniqueApiKeys = Array.from(
        new Set(
          flow.flow_nodes
            .filter(
              (node) =>
                node.type === "basicLLM" ||
                node.type === "ragLLM" ||
                node.type === "welcome"
            )
            .map((node) => node.data.api_key as string)
        )
      );

      if (uniqueApiKeys.length >= 1) {
        const result = await checkOpenAIAPIKey(uniqueApiKeys[0]);

        if (result) {
          set(openaiAPIKeyAtom, uniqueApiKeys[0]);
        }
      }
    }

    const refreshCookie = getCookie(
      process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
    );

    const accessCookie = getCookie(
      process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
    );

    const errorFunction = () => {
      set(errorModalAtom, {
        state: true,
        event: null,
        eventText: "",
        redirectUrl: "",
        text: "저장 실패하였습니다.",
        title: "저장 실패",
      });

      set(waitingModalAtom, {
        state: false,
        text: "저장하는 중",
      });

      return false;
    };

    const successFunction = (result: any) => {
      const { flow } = result;
      set(flowAtom, flow);

      set(successModalAtom, {
        state: true,
        event: null,
        eventText: "",
        redirectUrl: "",
        text: "저장 성공하였습니다.",
        title: "저장 성공",
      });

      set(waitingModalAtom, {
        state: false,
        text: "저장하는 중",
      });

      return true;
    };

    const newAccessToken = await handleApiResponse(
      refreshCookie,
      set,
      () => insertFlow(accessCookie ?? "", flow),
      () => errorFunction(),
      (results) => successFunction(results)
    );

    if (newAccessToken !== null && typeof newAccessToken !== "boolean") {
      const result = await handleApiResponse(
        null,
        set,
        () => insertFlow(newAccessToken ?? "", flow),
        () => errorFunction(),
        (results) => successFunction(results)
      );

      set(waitingModalAtom, {
        state: false,
        text: "저장하는 중",
      });

      return result;
    } else {
      set(waitingModalAtom, {
        state: false,
        text: "저장하는 중",
      });
      return newAccessToken;
    }
  }
});

export const checkFlow = (node: Node[]): string[] => {
  // 불충족한 조건 목록을 저장할 배열
  const errors: string[] = [];

  node.forEach((item) => {
    // 첫 번째 조건: type이 welcome이고, data.type.value가 user_setting_text일 때
    if (
      item.type === "welcome" &&
      (item as unknown as WelcomeNodeProps).data?.type?.value === ""
    ) {
      errors.push(item.id);
    } else if (
      item.type === "welcome" &&
      (item as unknown as WelcomeNodeProps).data?.type?.value ===
        "user_setting_text"
    ) {
      if (item.data.welcome_message === "") {
        errors.push(item.id);
      }
    } else if (
      item.type === "welcome" &&
      (item as unknown as WelcomeNodeProps).data?.type?.value === "llm"
    ) {
      if (
        item.data.prompt === "" ||
        item.data.api_key === "" ||
        (item as unknown as WelcomeNodeProps).data.model_name.value === ""
      ) {
        errors.push(item.id);
      }
    }
    // 두 번째 조건: type이 topic일 때, data.intend_list 내부 검사
    if (item.type === "topic") {
      const intend_list =
        (item as unknown as TopicClassifyNodeProps).data?.intend_list || [];
      intend_list.forEach((intend: any) => {
        if (!intend.intend_name || !intend.intend_desc) {
          errors.push(item.id);
        }
        if (
          intend.intend_exam.length < 1 ||
          intend.intend_exam.some((ex: string) => ex === "")
        ) {
          errors.push(item.id);
        }
      });
    }

    // 세 번째 조건: type이 basicLLM일 때
    if (item.type === "basicLLM") {
      if (
        !item.data?.prompt ||
        !item.data?.api_key ||
        !(item as unknown as BasicLLMNodeProps).data?.model_name?.value
      ) {
        errors.push(item.id);
      }
    }

    // 네 번째 조건: type이 ragLLM일 때
    if (item.type === "ragLLM") {
      if (
        !item.data?.grounding_data ||
        !(item as unknown as RAGbaseLLMNodeProps).data?.model_name?.value ||
        !item.data?.api_key ||
        !item.data?.prompt
      ) {
        errors.push(item.id);
      }
    }

    // 다섯 번째 조건: type이 trigger일 때
    if (item.type === "trigger") {
      const triggerTypeValue = (item as unknown as TriggerNodeProps).data?.type
        ?.value;
      if (!triggerTypeValue) {
        errors.push(item.id);
      }
      if (triggerTypeValue === "text" && !item.data?.trigger_message) {
        errors.push(item.id);
      }
      if (triggerTypeValue === "recommend") {
        const recommendMessages =
          (item as unknown as TriggerNodeProps).data?.recommend_messages || [];
        if (
          recommendMessages.length < 1 ||
          recommendMessages.some((msg: string) => msg === "")
        ) {
          errors.push(item.id);
        }
      }
    }

    // 네 번째 조건: type이 makeJson일 때
    if (item.type === "makeJson") {
      if (
        !item.data?.grounding_data ||
        !(item as unknown as MakeJsonNodeProps).data?.model_name?.value ||
        !item.data?.api_key ||
        !(item as unknown as MakeJsonNodeProps).data.json_type.value
      ) {
        errors.push(item.id);
      } else if (
        (item as unknown as MakeJsonNodeProps).data.json_type.value === "chart"
      ) {
        if (
          !(item as unknown as MakeJsonNodeProps).data.chart_type.value ||
          !(item as unknown as MakeJsonNodeProps).data.chart_description
        ) {
          errors.push(item.id);
        }
      } else if (
        (item as unknown as MakeJsonNodeProps).data.json_type.value === "etc"
      ) {
        if (!(item as unknown as MakeJsonNodeProps).data.etc_json_data) {
          errors.push(item.id);
        }
      }
    }
  });

  return errors;
};

export const checkNode = (item: Node): boolean => {
  if (
    item.type === "welcome" &&
    (item as unknown as WelcomeNodeProps).data?.type?.value === ""
  ) {
    return false;
  } else if (
    item.type === "welcome" &&
    (item as unknown as WelcomeNodeProps).data?.type?.value ===
      "user_setting_text"
  ) {
    if (item.data.welcome_message === "") {
      return false;
    }
  } else if (
    item.type === "welcome" &&
    (item as unknown as WelcomeNodeProps).data?.type?.value === "llm"
  ) {
    if (
      item.data.prompt === "" ||
      item.data.api_key === "" ||
      (item as unknown as WelcomeNodeProps).data.model_name.value === ""
    ) {
      return false;
    }
  }
  // 두 번째 조건: type이 topic일 때, data.intend_list 내부 검사
  if (item.type === "topic") {
    const intend_list =
      (item as unknown as TopicClassifyNodeProps).data?.intend_list || [];
    intend_list.forEach((intend: any) => {
      if (!intend.intend_name || !intend.intend_desc) {
        return false;
      }
      if (
        intend.intend_exam.length < 1 ||
        intend.intend_exam.some((ex: string) => ex === "")
      ) {
        return false;
      }
    });
  }

  // 세 번째 조건: type이 basicLLM일 때
  if (item.type === "basicLLM") {
    if (
      !item.data?.prompt ||
      !item.data?.api_key ||
      !(item as unknown as BasicLLMNodeProps).data?.model_name?.value
    ) {
      return false;
    }
  }

  // 네 번째 조건: type이 ragLLM일 때
  if (item.type === "ragLLM") {
    if (
      !item.data?.grounding_data ||
      !(item as unknown as RAGbaseLLMNodeProps).data?.model_name?.value ||
      !item.data?.api_key ||
      !item.data?.prompt
    ) {
      return false;
    }
  }

  // 다섯 번째 조건: type이 trigger일 때
  if (item.type === "trigger") {
    const triggerTypeValue = (item as unknown as TriggerNodeProps).data?.type
      ?.value;
    if (!triggerTypeValue) {
      return false;
    }
    if (triggerTypeValue === "text" && !item.data?.trigger_message) {
      return false;
    }
    if (triggerTypeValue === "recommend") {
      const recommendMessages =
        (item as unknown as TriggerNodeProps).data?.recommend_messages || [];
      if (
        recommendMessages.length < 1 ||
        recommendMessages.some((msg: string) => msg === "")
      ) {
        return false;
      }
    }
  }

  // 네 번째 조건: type이 makeJson일 때
  if (item.type === "makeJson") {
    if (
      !item.data?.grounding_data ||
      !(item as unknown as MakeJsonNodeProps).data?.model_name?.value ||
      !item.data?.api_key ||
      !(item as unknown as MakeJsonNodeProps).data.json_type.value
    ) {
      return false;
    } else if (
      (item as unknown as MakeJsonNodeProps).data.json_type.value === "chart"
    ) {
      if (
        !(item as unknown as MakeJsonNodeProps).data.chart_type.value ||
        !(item as unknown as MakeJsonNodeProps).data.chart_description
      ) {
        return false;
      }
    } else if (
      (item as unknown as MakeJsonNodeProps).data.json_type.value === "etc"
    ) {
      if (!(item as unknown as MakeJsonNodeProps).data.etc_json_data) {
        return false;
      }
    }
  }

  return true;
};

export const checkCycle = (edges: Edge[]): Edge | null => {
  // 그래프를 저장할 객체
  const graph: { [key: string]: string[] } = {};

  // 그래프 구성
  edges.forEach((edge) => {
    if (!graph[edge.source]) {
      graph[edge.source] = [];
    }
    graph[edge.source].push(edge.target);
  });

  // DFS로 순환 여부 확인
  const visited: { [key: string]: boolean } = {}; // 방문 여부 확인
  const stack: { [key: string]: string | null } = {}; // 현재 탐색 중인 노드와 부모 추적

  const hasCycle = (node: string, parent: string | null): Edge | null => {
    if (stack[node]) {
      // 현재 노드가 이미 스택에 있는 경우 순환이 발생한 것
      const cycleEdgeIndex = edges.findIndex(
        (edge) => edge.source === stack[node] && edge.target === node
      );
      return cycleEdgeIndex > 0 ? edges[cycleEdgeIndex - 1] : null;
    }

    if (visited[node]) {
      return null; // 이미 방문한 노드는 순환 검사하지 않음
    }

    visited[node] = true;
    stack[node] = parent; // 현재 노드의 부모 노드 저장

    const neighbors = graph[node] || [];
    for (const neighbor of neighbors) {
      const result = hasCycle(neighbor, node);
      if (result) {
        return result;
      }
    }

    stack[node] = null; // 탐색 종료 후 스택에서 제거
    return null;
  };

  // 모든 노드에서 순환 여부 검사
  for (const node in graph) {
    const cycleEdge = hasCycle(node, null);
    if (cycleEdge) {
      return cycleEdge; // 순환의 마지막 엣지 전의 엣지 반환
    }
  }

  return null; // 순환이 없는 경우
};

import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { selectAssetAtom } from "../../../Atoms/ChatAssetAtom";
import { useGetAssetLibrary } from "../../../Queries/DokgabiAssetQueries";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  assetLibraryCurrentPageAtom,
  assetLibraryListAtom,
  currentEditAssetLibraryIndexAtom,
} from "../../../Atoms/ChatMakeAssetAtom";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Error, ExpandMore, Pending, TableChart } from "@mui/icons-material";
import {
  handleAsetLibraryTableScrollAtom,
  handleAssetLibraryDeleteAtom,
  handleAssetLibraryRowUpdateAtom,
  handleExpandClickAtom,
  handleTabChangeAtom,
  updateAssetLibraryRowAtom,
} from "../../../ViewModels/ChatMakeAssetViewModel";
import * as S from "./styled";
import { Edit, Delete } from "@mui/icons-material";
import { useQueryClient } from "@tanstack/react-query";
import { DokgabiAssetLibraryProps } from "../../../Models/DokgabiFlow";

export function AssetLibrary() {
  const queryClient = useQueryClient();
  const [selectAsset] = useAtom(selectAssetAtom);
  const [assetLibraryCurrentPage] = useAtom(assetLibraryCurrentPageAtom);
  const [currentEditAssetLibraryIndex, setCurrentEditAssetLibraryIndex] =
    useAtom(currentEditAssetLibraryIndexAtom);

  const [tabValue, handleTabChange] = useAtom(handleTabChangeAtom);
  const [expandedRow, handleExpandClick] = useAtom(handleExpandClickAtom);
  const [assetLibraryList, setAssetLibraryList] = useAtom(assetLibraryListAtom);

  const [, handleScroll] = useAtom(handleAsetLibraryTableScrollAtom);
  const [, handleAssetLibraryDelete] = useAtom(handleAssetLibraryDeleteAtom);
  const [, handleAssetLibraryRowUpdate] = useAtom(
    handleAssetLibraryRowUpdateAtom
  );
  const [, updateAssetLibraryRow] = useAtom(updateAssetLibraryRowAtom);

  const { data: currentAssetLibrary } = useGetAssetLibrary(
    selectAsset.id,
    assetLibraryCurrentPage
  );

  useEffect(() => {
    if (currentAssetLibrary && Array.isArray(currentAssetLibrary)) {
      setAssetLibraryList((current) => {
        // 기존 리스트와 새로운 리스트를 합침
        const combined = [...current, ...currentAssetLibrary];

        // 배열을 역순으로 처리하여 뒤에 있는 값을 우선으로 남김
        const unique = Array.from(new Set(combined.map((item) => item.id)))
          .map((id) => combined.reverse().find((item) => item.id === id))
          .filter(
            (item): item is DokgabiAssetLibraryProps => item !== undefined
          ); // undefined 제거

        // 다시 순서를 원래대로 돌림
        return unique;
      });
    }
  }, [currentAssetLibrary]);

  if (currentAssetLibrary === null)
    return (
      <S.OuterBox>
        <S.NoRowBox>
          <Error sx={{ width: "20%", height: "20%", color: "#8f8f8f" }} />
          <Typography sx={{ color: "#8f8f8f" }}>
            새로 고침 후 다시 시도해주세요.
          </Typography>
        </S.NoRowBox>
      </S.OuterBox>
    );

  if (assetLibraryList?.length === 0)
    return (
      <S.OuterBox>
        <S.NoRowBox>
          <TableChart sx={{ width: "30%", height: "30%", color: "#8f8f8f" }} />
          <Typography sx={{ color: "#8f8f8f" }}>
            아직 만들어진 Asset Library가 없습니다
          </Typography>
        </S.NoRowBox>
      </S.OuterBox>
    );

  return (
    <S.AssetLibraryTableContainer onScroll={handleScroll}>
      <Table stickyHeader aria-label="asset library table">
        <TableHead>
          <TableRow>
            <S.HeaderCell>분류된 주제</S.HeaderCell>
            <S.HeaderCell>유저가 입력한 문장</S.HeaderCell>
            <S.HeaderCell align="right">챗봇 답변 및 사용된 Asset</S.HeaderCell>
            <S.HeaderCell align="center">수정 • 삭제</S.HeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {assetLibraryList?.map((row, index) => (
            <React.Fragment
              key={`${index}-currentAssetLibrary-table-mosigi-total-row`}
            >
              <TableRow key={`${index}-currentAssetLibrary-table-mosigi-cell`}>
                <S.OneLineCell>
                  {row.topic === null ? "-" : row.topic}
                </S.OneLineCell>
                {currentEditAssetLibraryIndex === index ? (
                  <TextField
                    value={row.user_text}
                    sx={{ width: "100%" }}
                    multiline
                    onChange={(e) =>
                      handleAssetLibraryRowUpdate(
                        e.target.value,
                        "user_text",
                        row
                      )
                    }
                  />
                ) : (
                  <TableCell>{row.user_text}</TableCell>
                )}

                <TableCell align="right">
                  <IconButton
                    onClick={() => handleExpandClick(index)}
                    aria-expanded={expandedRow === index}
                    aria-label="expand row"
                  >
                    <ExpandMore />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  {currentEditAssetLibraryIndex === index ? (
                    <Button
                      onClick={() => updateAssetLibraryRow(row, queryClient)}
                    >
                      수정완료
                    </Button>
                  ) : (
                    <>
                      <IconButton
                        onClick={() => setCurrentEditAssetLibraryIndex(index)}
                        aria-label="edit"
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        onClick={() => handleAssetLibraryDelete(row)}
                        aria-label="delete"
                      >
                        <Delete />
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
              <TableRow
                key={`${index}-currentAssetLibrary-table-mosigi-cell-expand`}
              >
                <S.ExpandCell colSpan={4}>
                  {" "}
                  {/* colspan을 4로 변경 */}
                  <Collapse
                    in={expandedRow === index}
                    timeout="auto"
                    unmountOnExit
                  >
                    <S.ExpandBox>
                      <S.CustomTabs
                        value={tabValue}
                        onChange={(event, value) => handleTabChange(value)}
                        aria-label="chatbot and docs tabs"
                      >
                        <S.CustomTab
                          label="챗봇 응답"
                          tabvalue={tabValue === 0 ? "true" : "false"}
                        />
                        <S.CustomTab
                          label="챗봇 응답 시 사용된 Asset"
                          tabvalue={tabValue === 1 ? "true" : "false"}
                        />
                      </S.CustomTabs>
                      {tabValue === 0 && (
                        <S.InnerTabBox>
                          {currentEditAssetLibraryIndex === index ? (
                            <TextField
                              value={row.chatbot_text}
                              sx={{ width: "100%", background: "white" }}
                              onChange={(e) =>
                                handleAssetLibraryRowUpdate(
                                  e.target.value,
                                  "chatbot_text",
                                  row
                                )
                              }
                              multiline
                            />
                          ) : (
                            <ReactMarkdown
                              remarkPlugins={[remarkGfm]}
                              components={
                                {
                                  // ReactMarkdown 컴포넌트 설정 유지
                                }
                              }
                            >
                              {row.chatbot_text}
                            </ReactMarkdown>
                          )}
                        </S.InnerTabBox>
                      )}
                      {tabValue === 1 && (
                        <S.InnerTabBox>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Using Asset</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {row.using_docs ? (
                                row.using_docs.map(
                                  (doc: string, docIndex: number) => (
                                    <TableRow
                                      key={`${docIndex}-using-asset-cell`}
                                    >
                                      <TableCell>{doc}</TableCell>
                                    </TableRow>
                                  )
                                )
                              ) : (
                                <TableRow>
                                  <TableCell>없음</TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </S.InnerTabBox>
                      )}
                    </S.ExpandBox>
                  </Collapse>
                </S.ExpandCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </S.AssetLibraryTableContainer>
  );
}

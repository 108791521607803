import { useQuery } from "@tanstack/react-query";
import {
  DokgabiAssetLibraryProps,
  DokgabiAssetProps,
} from "../Models/DokgabiFlow";
import axios from "axios";
import { useEffect } from "react";
import { ResponseStatus } from "../Enum/NetworkEnum";
import { getCookie } from "../Utils/CookieUtil";
import { handleReactQueryApiResponse } from "../Utils/APIUtil";

export async function deleteFileFromVector(
  access: string,
  asset: DokgabiAssetProps,
  file: {
    file_name: string;
    file_type: string;
    file_view_name: string;
    file_vector_progress: number;
    task_id: string;
  },
  index: number
) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({
      asset_id: asset.id,
      vector_file_path: asset.verctor_file_path,
      file: file,
      index: index,
    }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/deleteFileFromVector`,
    requestOptions
  );

  if (response.status === 200) {
    const result = await response.json();
    return result.results;
  } else if (response.status === 401) {
    return ResponseStatus.UNAUTHORIZED;
  } else if (response.status === 403) {
    return ResponseStatus.FORBIDDEN;
  } else {
    return ResponseStatus.ERROR;
  }
}

export async function updateAssetLibrary(
  access: string,
  row: DokgabiAssetLibraryProps
) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify(row),
  };
  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/updateAssetLibrary`,
    requestOptions
  );
  if (response.status === 200) {
    const result = await response.json();
    return result.results;
  } else if (response.status === 401) {
    return ResponseStatus.UNAUTHORIZED;
  } else if (response.status === 403) {
    return ResponseStatus.FORBIDDEN;
  } else {
    return ResponseStatus.ERROR;
  }
}

export async function deleteAssetLibrary(
  access: string,
  row: DokgabiAssetLibraryProps
) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify(row),
  };

  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/deleteAssetLibrary`,
    requestOptions
  );

  if (response.status === 200) {
    const result = await response.json();
    return result.results;
  } else if (response.status === 401) {
    return ResponseStatus.UNAUTHORIZED;
  } else if (response.status === 403) {
    return ResponseStatus.FORBIDDEN;
  } else {
    return ResponseStatus.ERROR;
  }
}

async function getMyAsset(
  access: string,
  id: string
): Promise<DokgabiAssetProps[] | null | number | string> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({
      id: id,
    }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/getMyAsset`,
    requestOptions
  );

  if (response.status === 200) {
    const result = await response.json();
    return result.results;
  } else if (response.status === 401) {
    return ResponseStatus.UNAUTHORIZED;
  } else if (response.status === 403) {
    return ResponseStatus.FORBIDDEN;
  } else {
    return ResponseStatus.ERROR;
  }
}

export function useGetMyAssetQuery(id: string) {
  const accessCookie = getCookie(
    process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
  );

  return useQuery({
    queryKey: [`${id}-knowbase`],
    queryFn: () =>
      handleReactQueryApiResponse(
        (accessCookie) => getMyAsset(accessCookie ?? "", id),
        accessCookie ?? ""
      ),
    staleTime: 0, // 데이터를 항상 최신으로 유지
  });
}

async function getFileData(
  access: string,
  file: {
    file_name: string;
    file_type: string;
    file_view_name: string;
    file_vector_progress: number;
    task_id: string;
  },
  asset_id: number,
  numpage: number
): Promise<
  | {
      progress: number;
      task_state: string;
      rows: any[];
      columns: string[];
    }
  | null
  | number
  | string
> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({
      ...file,
      asset_id: asset_id,
      numpage: numpage,
    }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/getFileData`,
    requestOptions
  );
  if (response.status === 200) {
    const result = await response.json();
    return result.results;
  } else if (response.status === 401) {
    return ResponseStatus.UNAUTHORIZED;
  } else if (response.status === 403) {
    return ResponseStatus.FORBIDDEN;
  } else {
    return ResponseStatus.ERROR;
  }
}

export function useGetFileData(
  file: {
    file_name: string;
    file_type: string;
    file_view_name: string;
    file_vector_progress: number;
    task_id: string;
  },
  asset_id: number,
  numpage: number
) {
  const accessCookie = getCookie(
    process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
  );
  return useQuery({
    queryKey: [`${file.file_name}-${asset_id}-${numpage}-current-file-data`],
    queryFn: () =>
      handleReactQueryApiResponse(
        (accessCookie) =>
          getFileData(accessCookie ?? "", file, asset_id, numpage),
        accessCookie ?? ""
      ),
  });
}

async function getAssetLibrary(
  access: string,
  id: number,
  pageNum: number
): Promise<DokgabiAssetLibraryProps[] | null | string | number> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({
      asset_id: id,
      numpage: pageNum,
    }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/getAssetLibrary`,
    requestOptions
  );
  if (response.status === 200) {
    const result = await response.json();
    return result.results;
  } else if (response.status === 401) {
    return ResponseStatus.UNAUTHORIZED;
  } else if (response.status === 403) {
    return ResponseStatus.FORBIDDEN;
  } else {
    return ResponseStatus.ERROR;
  }
}

export function useGetAssetLibrary(id: number, pageNum: number) {
  const accessCookie = getCookie(
    process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
  );
  const query = useQuery({
    queryKey: [`${id}-${pageNum}-knowbase-asset-library`],
    queryFn: () =>
      handleReactQueryApiResponse(
        (accessCookie) => getAssetLibrary(accessCookie ?? "", id, pageNum),
        accessCookie ?? ""
      ),
  });

  useEffect(() => {
    if (pageNum === 1) {
      // 상태를 초기화하여 중복 방지
      query.refetch();
    }
  }, [id, pageNum, query]);

  return query;
}

export async function makeOrUpdateAsset(
  access: string,
  file: File | null,
  selectAsset: DokgabiAssetProps
): Promise<any | null> {
  // FormData 객체 생성
  const formData = new FormData();

  if (file) formData.append("file", file);

  // selectAsset 데이터를 추가 (JSON 문자열로 변환)
  formData.append("asset", JSON.stringify(selectAsset));

  const requestOptions = {
    method: "POST",
    headers: {
      // "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${access}`,
    },
    body: formData,
  };

  // API 요청 보내기
  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/makeOrUpdateAsset`,
    requestOptions
  );

  if (response.status === 200) {
    const result = await response.json();
    return result.results;
    // return response.data.results;
  } else if (response.status === 401) {
    return ResponseStatus.UNAUTHORIZED;
  } else if (response.status === 403) {
    return ResponseStatus.FORBIDDEN;
  } else {
    return ResponseStatus.ERROR;
  }

  // try {
  //   // 성공적으로 전송된 경우
  //   if (response.status === 200 && response.data?.results) {
  //     return response.data.results; // 결과 반환
  //   } else {
  //     return null; // 실패시 null 반환
  //   }
  // } catch (error) {
  //   return null; // 오류시 null 반환
  // }
}

import { atom } from "jotai";
import { deleteCookie, getCookie } from "../Utils/CookieUtil";
import { inviteUserAtom, userAtom } from "../Atoms/PublicAtom";
import {
  currentMyStateElAtom,
  drawerOpenAtom,
  functionAnchorElAtom,
  makingAnchorElAtom,
  profileAnchorElAtom,
} from "../Atoms/HeaderAtom";
import { openNewWindowWithPostData } from "../Utils/WindowOpenNavigateUtil";
import { myChatbotListUserAtom } from "../Atoms/MyChatbotListAtom";
import {
  checkUserChatbotNum,
  checkUserEndDate,
} from "../Queries/PlanValidQueries";
import { errorModalAtom } from "../Atoms/RootAtom";
import { handleApiResponse } from "../Utils/APIUtil";

export const handleFunctionMenuOpenAtom = atom(
  (get) => Boolean(get(functionAnchorElAtom)),
  (get, set, event: React.MouseEvent<HTMLButtonElement>) => {
    set(functionAnchorElAtom, event.currentTarget);
  }
);

export const handleFunctionMenuCloseAtom = atom(
  (get) => get(functionAnchorElAtom),
  (get, set) => set(functionAnchorElAtom, null)
);

export const handleMakeMenuOpenAtom = atom(
  (get) => Boolean(get(makingAnchorElAtom)),
  (get, set, event: React.MouseEvent<HTMLButtonElement>) => {
    set(makingAnchorElAtom, event.currentTarget);
  }
);

export const handleMakeMenuCloseAtom = atom(
  (get) => get(makingAnchorElAtom),
  (get, set) => set(makingAnchorElAtom, null)
);

export const handleProfileMenuOpenAtom = atom(
  (get) => Boolean(get(profileAnchorElAtom)),
  (get, set, event: React.MouseEvent<HTMLButtonElement>) => {
    set(profileAnchorElAtom, event.currentTarget);
  }
);

export const handleProfileMenuCloseAtom = atom(
  (get) => get(profileAnchorElAtom),
  (get, set) => set(profileAnchorElAtom, null)
);

export const handleCurrentMyStateMenuOpenAtom = atom(
  (get) => Boolean(get(currentMyStateElAtom)),
  (get, set, event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    set(currentMyStateElAtom, event.currentTarget);
  }
);

export const handleCurrentMyStateMenuCloseAtom = atom(
  (get) => get(currentMyStateElAtom),
  (get, set) => set(currentMyStateElAtom, null)
);

export const handleLogoutItemClickAtom = atom(null, (get, set) => {
  deleteCookie(
    process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID,
    "/",
    process.env.REACT_APP_DOMAIN_NAME
  );

  deleteCookie(
    process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID,
    "/",
    process.env.REACT_APP_DOMAIN_NAME
  );
  set(userAtom, null);
  window.location.href = process.env.REACT_APP_DOKGABI_HOME_ADDRESS;
});

export const handleNoticeWhenServiceIsInDevelopAtom = atom(
  null,
  (get, set) => null
);

export const setStateDrawerAtom = atom(
  (get) => get(drawerOpenAtom),
  (get, set, open: boolean, event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    set(drawerOpenAtom, open);
  }
);

export const makeChatbotAtom = atom(null, async (get, set) => {
  const user = get(userAtom);

  if (user) {
    const refreshCookie = getCookie(
      process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
    );

    const accessCookie = getCookie(
      process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
    );

    const errorFunction = () => {
      set(errorModalAtom, {
        state: true,
        event: () =>
          (window.location.href = process.env.REACT_APP_DOKGABI_PAY_ADDRESS),
        eventText: "플랜 갱신",
        redirectUrl: "",
        title: "플랜 기간 만료",
        text: "현재 플랜의 기간이 만료되었습니다.\n플랜 갱신 후 다시 시도해 주세요.",
      });
    };

    const successFunction = async (results: any) => {
      const refreshCookie = getCookie(
        process.env.REACT_APP_DOKGABI_REFRESH_COOKIE_ID
      );

      const accessCookie = getCookie(
        process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
      );

      const errorFunction = () => {
        set(errorModalAtom, {
          state: true,
          event: () =>
            (window.location.href = process.env.REACT_APP_DOKGABI_PAY_ADDRESS),
          eventText: "플랜 갱신",
          redirectUrl: "",
          title: "챗봇 생성 제한",
          text: "현재 플랜에서 최대 챗봇 생성에 도달 했습니다.\n플랜 업그레이드 후에 다시 시도해 주세요.",
        });
      };

      const successFunction = (results: any) => {
        if (results === true) {
          const data = {
            message: "chatbotDesign",
            type: "create",
            id: -1,
          };

          openNewWindowWithPostData(
            `${process.env.REACT_APP_DOKGABI_DASHBOARD_ADDRESS}/chatbotDesign/flow`,
            data
          );
        } else {
          set(errorModalAtom, {
            state: true,
            event: () =>
              (window.location.href =
                process.env.REACT_APP_DOKGABI_PAY_ADDRESS),
            eventText: "플랜 갱신",
            redirectUrl: "",
            title: "챗봇 생성 제한",
            text: "현재 플랜에서 최대 챗봇 생성에 도달 했습니다.\n플랜 업그레이드 후에 다시 시도해 주세요.",
          });
        }
      };

      const newAccessToken = await handleApiResponse(
        refreshCookie,
        set,
        () => checkUserChatbotNum(accessCookie ?? "", user?.id),
        () => errorFunction(),
        (results) => successFunction(results)
      );

      if (newAccessToken) {
        await handleApiResponse(
          null,
          set,
          () => checkUserChatbotNum(newAccessToken ?? "", user?.id),
          () => errorFunction(),
          (results) => successFunction(results)
        );
      }
    };

    const newAccessToken = await handleApiResponse(
      refreshCookie,
      set,
      () => checkUserEndDate(accessCookie ?? "", user?.id),
      () => errorFunction(),
      (results) => successFunction(results)
    );

    if (newAccessToken) {
      await handleApiResponse(
        null,
        set,
        () => checkUserEndDate(newAccessToken ?? "", user?.id),
        () => errorFunction(),
        (results) => successFunction(results)
      );
    }
  } else {
    set(errorModalAtom, {
      state: true,
      event: null,
      eventText: "",
      redirectUrl: "",
      title: "네트워크 오류",
      text: "네트워크 오류가 났습니다.\n새로고침 후에 시도해주세요.",
    });
  }
});

export const handleInviteUserAtom = atom(
  (get) => get(inviteUserAtom),
  (get, set, userId: string, { navigate }) => {
    set(myChatbotListUserAtom, userId);
    navigate("/myChatbotList");
  }
);

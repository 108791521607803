import React from "react";
import { Typography, Box } from "@mui/material";
import { useAtom } from "jotai";
import { whenFinishButtonClickAtom } from "../../../ViewModels/GuideModalViewModel";
import { CheckCircle } from "@mui/icons-material";
import { styled, keyframes } from "@mui/system";

// 통통 튀는 애니메이션 정의
const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
`;

// 애니메이션을 적용한 CheckCircle 스타일링
const BouncingCheckCircle = styled(CheckCircle)(({ theme }) => ({
  width: "30%",
  height: "30%",
  color: "#336eff",
  cursor: "pointer", // 클릭 가능하도록 커서 변경
  animation: `${bounce} 1s infinite`, // 애니메이션 추가
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "scale(1.1)", // hover 시 크기 증가
  },
}));

export const FileUploadInnerBox = styled(Box)({
  width: "100%",
  height: "100%",
  padding: "50px",
  boxSizing: "border-box",
  border: "3px dashed #8f8f8f",
  borderRadius: "15px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
});

export function Finish() {
  const [, finish] = useAtom(whenFinishButtonClickAtom);

  return (
    <FileUploadInnerBox onClick={finish}>
      {/* 통통 튀는 CheckCircle */}
      <BouncingCheckCircle />

      {/* 안내 문구 */}
      <Typography sx={{ color: "#8f8f8f", marginTop: "16px" }}>
        모든 과정을 완수했습니다.
      </Typography>
      <Typography sx={{ color: "#8f8f8f" }}>
        체크 버튼을 눌러 챗봇을 완성해보세요.
      </Typography>
    </FileUploadInnerBox>
  );
}

import { Box, styled as muiStyled, Typography } from "@mui/material";
import { ExpandIconButton } from "../../ChatbotDesignHeader/styled";
import styled from "styled-components";

export const ElementBarContainer = muiStyled(Box)({
  position: "fixed",
  left: "20px",
  height: "calc(100vh - 56px)",
  background: "transparent",
  zIndex: 100,
  bottom: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "start",
  flexDirection: "column",
  gap: "20px",
});

export const CustomExpandIconButton = muiStyled(ExpandIconButton)({
  padding: "10px",
  gap: "10px",
  //   background: process.env.REACT_APP_SUB_COLOR,
  "& .MuiTypography-root": {
    display: "none",
  },
  "&:hover": {
    padding: "10px 20px",
    "& .MuiTypography-root": {
      display: "block",
    },
  },
});

export const CustomTypograpy = muiStyled(Typography)({
  fontSize: "10px",
});

import {
  Accordion,
  ListItemIcon,
  MenuItem,
  styled as muiStyled,
  Typography,
} from "@mui/material";
import styled from "styled-components";

export const ChatbotDesignMobileHeaderDiv = styled.div`
  width: 100%;
  height: 56px;
  background-color: white;
  z-index: 1000;
  position: fixed;
  top: 0;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const MobileInputField = styled.input`
  flex: 1;
  height: 40px;
  border: none;
  outline: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background 0.3s ease;
  box-sizing: border-box;
  padding: 0 10px;
  margin-right: 40px;
  border: 1px solid ${process.env.REACT_APP_MAIN_COLOR};

  &:focus {
    border: 1.2px solid ${process.env.REACT_APP_MAIN_COLOR};
  }
`;

export const CustomAccordion = muiStyled(Accordion)({
  padding: 0,
  border: "none",
  width: "100%",
  borderRadius: 0,
  boxShadow: "0 5px 5px #d8d8d8",
});

export const SubTitleTypo = muiStyled(Typography)({
  fontSize: "12px",
});

export const ButtonTypo = muiStyled(Typography)({
  fontSize: "14px",
});

export const ButtonIcon = muiStyled(ListItemIcon)({
  fontSize: "14px",
});

export const SelectButtonTypo = muiStyled(Typography)({
  fontSize: "14px",
  color: "white",
});

export const SelectButtonIcon = muiStyled(ListItemIcon)({
  fontSize: "14px",
  color: "white",
});

export const SelectMenuItem = muiStyled(MenuItem)({
  background: process.env.REACT_APP_MAIN_COLOR,
});

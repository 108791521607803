import { Box, Stack, styled } from "@mui/material";

export const FullStack = styled(Stack)({
  width: "100%",
  height: "100%",
  boxSizing: "border-box",
  display: "flex",
});

export const LeftSideBar = styled(Box)({
  flex: 1,
  height: "100%",
  maxHeight: "100%",
  padding: "10px 0",
  boxSizing: "border-box",
  borderRight: "1px solid #ececec",
  overflowY: "auto",
});

export const RightSidebar = styled(Box)(({ theme }) => ({
  flex: 1, // 유연한 공간 차지
  height: "100%",
  maxHeight: "100%",
  padding: "10px 0",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column", // 여기에 있는 작은 오타 수정
  alignItems: "center",
  justifyContent: "center",
}));

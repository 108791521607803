import {
  AccordionDetails,
  AccordionSummary,
  Divider,
  IconButton,
  ListItemIcon,
  MenuItem,
  Stack,
} from "@mui/material";
import React from "react";
import * as S from "./styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useAtom } from "jotai";
import { flowAtom, pageListAtom } from "../../../Atoms/ChatDesignPublicAtom";
import {
  navigateAtom,
  onTextFieldChangeAtom,
} from "../../../ViewModels/ChatbotDesignHeaderViewModel";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { setStateDrawerAtom } from "../../../ViewModels/HeaderViewModel";

export function MobileComponent() {
  const [flow] = useAtom(flowAtom);
  const [pageList] = useAtom(pageListAtom);
  const [, onTextFieldChange] = useAtom(onTextFieldChangeAtom);
  const [, chatboDesignNavigate] = useAtom(navigateAtom);
  const [, toggleDrawer] = useAtom(setStateDrawerAtom);

  const navigate = useNavigate();

  return (
    <S.ChatbotDesignMobileHeaderDiv>
      <S.CustomAccordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              toggleDrawer(true, e);
            }}
          >
            <MenuIcon />
          </IconButton>
          <S.MobileInputField
            placeholder={"챗봇 이름을 입력해주세요."}
            value={flow.name}
            onChange={onTextFieldChange}
          ></S.MobileInputField>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "0 10px" }}>
          <Stack direction={"row"} sx={{ gap: "10px" }}>
            <Stack sx={{ flex: 1 }}>
              <S.SubTitleTypo>페이지 이동</S.SubTitleTypo>
              <Divider />
              {pageList.map((page, index) =>
                location.pathname.includes(page) ? (
                  <S.SelectMenuItem
                    key={`mobile-page-key-${index}`}
                    onClick={() =>
                      chatboDesignNavigate(
                        { navigate, location },
                        location.pathname.split("/")[2],
                        page
                      )
                    }
                  >
                    <S.SelectButtonTypo>To {page}</S.SelectButtonTypo>
                    <S.SelectButtonTypo>
                      <KeyboardArrowRightIcon />
                    </S.SelectButtonTypo>
                  </S.SelectMenuItem>
                ) : (
                  <MenuItem
                    key={`mobile-page-key-${index}`}
                    onClick={() =>
                      chatboDesignNavigate(
                        { navigate, location },
                        location.pathname.split("/")[2],
                        page
                      )
                    }
                  >
                    <S.ButtonTypo>To {page}</S.ButtonTypo>
                    <ListItemIcon sx={{ fontSize: "12px" }}>
                      <KeyboardArrowRightIcon />
                    </ListItemIcon>
                  </MenuItem>
                )
              )}
            </Stack>
            <Stack sx={{ flex: 1 }}>
              <S.SubTitleTypo>기능</S.SubTitleTypo>
              <Divider />
              <MenuItem>
                <S.ButtonTypo>저장</S.ButtonTypo>
                <S.ButtonIcon>
                  <KeyboardArrowRightIcon />
                </S.ButtonIcon>
              </MenuItem>
              <MenuItem>
                <S.ButtonTypo>BOOSTER</S.ButtonTypo>
                <S.ButtonIcon>
                  <KeyboardArrowRightIcon />
                </S.ButtonIcon>
              </MenuItem>
              <MenuItem>
                <S.ButtonTypo>ASSET 생성</S.ButtonTypo>
                <S.ButtonIcon>
                  <KeyboardArrowRightIcon />
                </S.ButtonIcon>
              </MenuItem>
            </Stack>
          </Stack>
        </AccordionDetails>
      </S.CustomAccordion>
    </S.ChatbotDesignMobileHeaderDiv>
  );
}

import { useQuery } from "@tanstack/react-query";
import {
  DokgabiFlowProps,
  DokgabiFrontElementProps,
} from "../Models/DokgabiFlow";
import { getCookie } from "../Utils/CookieUtil";
import { handleReactQueryApiResponse } from "../Utils/APIUtil";
import { ResponseStatus } from "../Enum/NetworkEnum";

async function getTemplateFlow(
  access: string
): Promise<DokgabiFlowProps[] | null | string | number> {
  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/getTemplateFlow`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
    }
  );

  if (response.status === 200) {
    const result = await response.json();
    return result.results;
  } else if (response.status === 401) {
    return ResponseStatus.UNAUTHORIZED;
  } else if (response.status === 403) {
    return ResponseStatus.FORBIDDEN;
  } else {
    return ResponseStatus.ERROR;
  }
}

export function useGetTemplateFlowDataQuery() {
  const accessCookie = getCookie(
    process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
  );
  return useQuery({
    queryKey: ["template"],
    queryFn: () =>
      handleReactQueryApiResponse(
        (accessCookie) => getTemplateFlow(accessCookie ?? ""),
        accessCookie ?? ""
      ),
  });
}

async function getMyFlow(
  access: string,
  id: string
): Promise<DokgabiFlowProps[] | null | string | number> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json", // JSON 형식으로 지정
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({
      id: id,
    }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/getMyFlow`,
    requestOptions
  );

  if (response.status === 200) {
    const result = await response.json();
    return result.results;
  } else if (response.status === 401) {
    return ResponseStatus.UNAUTHORIZED;
  } else if (response.status === 403) {
    return ResponseStatus.FORBIDDEN;
  } else {
    return ResponseStatus.ERROR;
  }
}

export function useGetFlowDataQuery(myId: string) {
  const accessCookie = getCookie(
    process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
  );
  return useQuery({
    queryKey: [`${myId}-flow`],
    queryFn: () =>
      handleReactQueryApiResponse(
        (accessCookie) => getMyFlow(accessCookie ?? "", myId),
        accessCookie ?? ""
      ),
  });
}

export async function getSelectFlow(
  access: string,
  flowId: number
): Promise<DokgabiFlowProps | null | string | number> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json", // JSON 형식으로 지정
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({
      id: flowId,
    }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/getSelectFlow`,
    requestOptions
  );

  if (response.status === 200) {
    const result = await response.json();
    return result.results;
  } else if (response.status === 401) {
    return ResponseStatus.UNAUTHORIZED;
  } else if (response.status === 403) {
    return ResponseStatus.FORBIDDEN;
  } else {
    return ResponseStatus.ERROR;
  }
}

export async function insertFlow(
  access: string,
  flow: DokgabiFlowProps
): Promise<
  | {
      flow: DokgabiFlowProps;
    }
  | null
  | string
  | number
> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json", // JSON 형식으로 지정
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({
      flow: flow,
    }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/insertFlow`,
    requestOptions
  );

  if (response.status === 200) {
    const result = await response.json();
    return {
      flow: result.flow,
    };
  } else if (response.status === 401) {
    return ResponseStatus.UNAUTHORIZED;
  } else if (response.status === 403) {
    return ResponseStatus.FORBIDDEN;
  } else {
    return ResponseStatus.ERROR;
  }
}

async function getMyFlowFrontElement(
  access: string,
  id: number
): Promise<DokgabiFrontElementProps[] | null | string | number> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json", // JSON 형식으로 지정
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({
      id: id,
    }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/getMyChatbotFrontElement`,
    requestOptions
  );

  if (response.status === 200) {
    const result = await response.json();
    return result.results;
  } else if (response.status === 401) {
    return ResponseStatus.UNAUTHORIZED;
  } else if (response.status === 403) {
    return ResponseStatus.FORBIDDEN;
  } else {
    return ResponseStatus.ERROR;
  }
}

export function useGetMyFlowFrontElement(myId: number) {
  const accessCookie = getCookie(
    process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
  );
  return useQuery({
    queryKey: [`${myId}-flow-front-element`],
    queryFn: () =>
      handleReactQueryApiResponse(
        (accessCookie) => getMyFlowFrontElement(accessCookie ?? "", myId),
        accessCookie ?? ""
      ),
  });
}

export async function insertFrontElement(
  access: string,
  value: DokgabiFrontElementProps[],
  id: number
): Promise<DokgabiFrontElementProps[] | null | string | number> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json", // JSON 형식으로 지정
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({
      flow_id: id,
      value: value,
    }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/insertFrontElement`,
    requestOptions
  );

  if (response.status === 200) {
    const result = await response.json();
    return result.flow;
  } else if (response.status === 401) {
    return ResponseStatus.UNAUTHORIZED;
  } else if (response.status === 403) {
    return ResponseStatus.FORBIDDEN;
  } else {
    return ResponseStatus.ERROR;
  }
}

export async function deleteFlow(
  access: string,
  id: number
): Promise<Boolean | null | string | number> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json", // JSON 형식으로 지정
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({
      flow_id: id,
    }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/deleteFlow`,
    requestOptions
  );

  if (response.status === 200) {
    const result = await response.json();
    return result.results;
  } else if (response.status === 401) {
    return ResponseStatus.UNAUTHORIZED;
  } else if (response.status === 403) {
    return ResponseStatus.FORBIDDEN;
  } else {
    return ResponseStatus.ERROR;
  }
}

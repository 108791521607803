import { useQuery } from "@tanstack/react-query";
import { DokgabiCharacterProps } from "../Models/DokgabiFlow";
import { handleReactQueryApiResponse } from "../Utils/APIUtil";
import { getCookie } from "../Utils/CookieUtil";
import { ResponseStatus } from "../Enum/NetworkEnum";

async function getAllCharacter(
  access: string,
  id: string
): Promise<DokgabiCharacterProps[] | null | string | number> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({
      id: id,
    }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/getAllCharacter`,
    requestOptions
  );
  if (response.status === 200) {
    const result = await response.json();
    return result.results;
  } else if (response.status === 401) {
    return ResponseStatus.UNAUTHORIZED;
  } else if (response.status === 403) {
    return ResponseStatus.FORBIDDEN;
  } else {
    return ResponseStatus.ERROR;
  }
}

export function useGetCharacterDataQuery(id: string) {
  const accessCookie = getCookie(
    process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
  );
  return useQuery({
    queryKey: ["character"],
    queryFn: () =>
      handleReactQueryApiResponse(
        (accessCookie) => getAllCharacter(accessCookie ?? "", id),
        accessCookie ?? ""
      ),
  });
}

async function getLeftCharMakeNum(
  access: string,
  id: string
): Promise<DokgabiCharacterProps[] | null | string | number> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({
      id: id,
    }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_USER_API_ADDRESS}/mysql/leftCharMakeNum`,
    requestOptions
  );
  if (response.status === 200) {
    const result = await response.json();
    return result.results;
  } else if (response.status === 401) {
    return ResponseStatus.UNAUTHORIZED;
  } else if (response.status === 403) {
    return ResponseStatus.FORBIDDEN;
  } else {
    return ResponseStatus.ERROR;
  }
}

export function useGetLeftCharMakeNum(id: string) {
  const accessCookie = getCookie(
    process.env.REACT_APP_DOKGABI_ACCESS_COOKIE_ID
  );
  return useQuery({
    queryKey: [`character-make-num-${id}`],
    queryFn: () =>
      handleReactQueryApiResponse(
        (accessCookie) => getLeftCharMakeNum(accessCookie ?? "", id),
        accessCookie ?? ""
      ),
  });
}

export async function makeImageByAI(
  access: string,
  prompt: string,
  maker: string,
  flow: number
): Promise<
  | {
      character: DokgabiCharacterProps;
    }
  | null
  | string
  | number
> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({
      prompt: prompt,
      maker: maker,
      flow: flow,
    }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/makeImageByAI`,
    requestOptions
  );
  if (response.status === 200) {
    const result = await response.json();
    return {
      character: result.results,
    };
  } else if (response.status === 401) {
    return ResponseStatus.UNAUTHORIZED;
  } else if (response.status === 403) {
    return ResponseStatus.FORBIDDEN;
  } else {
    return ResponseStatus.ERROR;
  }
}
